/**
 * メモマーカーをクリックした際に表示されるメモのポップアップを作成
 * React.Componentではない
 * mapboxgl.PopupのsetDOMContentメソッドに渡すためのDOMContentを作成する
 * @param {Object} props
 * @returns ポップアップのDOMContentを返す
 */
export default function MemoPopup(props) {
  const {
    memoId,
    userId,
    lng,
    lat,
    memoText,
    memoTitle,
  } = props

  let { createDateTime } = props;

  // ポップアップ用のElement作成
  let popupElement = document.createElement('div');
  popupElement.classList.add('popArea');
  popupElement.id = 'popArea_' + memoId;
  // div
  const elementUpper = document.createElement('div');
  elementUpper.classList.add('upper');
  popupElement.appendChild(elementUpper);
  // userid
  const htmlUserId = document.createElement('p');
  htmlUserId.classList.add('userId');
  htmlUserId.innerText = userId;
  elementUpper.appendChild(htmlUserId);
  // createdatetime
  const pTagForCreateDateTime = document.createElement('p');
  pTagForCreateDateTime.classList.add('createDateTime');
  createDateTime = createDateTime.split('_');
  let yyyyMMdd = createDateTime[0];
  let hhmmss = createDateTime[1];
  yyyyMMdd = `${yyyyMMdd.slice(0, 4)}-${yyyyMMdd.slice(4, 6)}-${yyyyMMdd.slice(6, 8)}`
  hhmmss = `${hhmmss.slice(0, 2)}:${hhmmss.slice(2, 4)}:${hhmmss.slice(4, 6)}`
  pTagForCreateDateTime.innerText = `${yyyyMMdd} ${hhmmss}`;
  elementUpper.appendChild(pTagForCreateDateTime);
  // title
  const pTagForMemoTitle = document.createElement('p');
  pTagForMemoTitle.classList.add('title');
  pTagForMemoTitle.innerText = memoTitle;
  popupElement.appendChild(pTagForMemoTitle);
  // memo
  const pTagForMemoText = document.createElement('p');
  pTagForMemoText.classList.add('memo');
  pTagForMemoText.innerText = memoText;
  popupElement.appendChild(pTagForMemoText);
  // div
  const elementBottom = document.createElement('div');
  elementBottom.classList.add('bottom');
  popupElement.appendChild(elementBottom);
  // div
  const divTagForLatLng = document.createElement('div');
  divTagForLatLng.classList.add('lngLat');
  elementBottom.appendChild(divTagForLatLng);
  // lat
  const pTagForLat = document.createElement('p');
  pTagForLat.classList.add('Lat');
  pTagForLat.innerText = Math.floor(lat * Math.pow(10, 5)) / Math.pow(10, 5); // 小数点5桁までに制限
  divTagForLatLng.appendChild(pTagForLat);
  // lng
  const pTagForLng = document.createElement('p');
  pTagForLng.classList.add('lng');
  pTagForLng.innerText = Math.floor(lng * Math.pow(10, 5)) / Math.pow(10, 5); // 小数点5桁までに制限
  divTagForLatLng.appendChild(pTagForLng);
  // ul
  const ulTagForDeleteButton = document.createElement('ul');
  ulTagForDeleteButton.classList.add('button');
  elementBottom.appendChild(ulTagForDeleteButton);
  // 更新用ボタン作成
  const liTagForUpdateButton = document.createElement('li');
  liTagForUpdateButton.id = 'UpdateMarker_' + memoId;
  liTagForUpdateButton.classList.add('UpdateMarker');
  liTagForUpdateButton.dataset.id = memoId;
  ulTagForDeleteButton.appendChild(liTagForUpdateButton);
  // 削除用ボタン作成
  const liTagForDeleateButton = document.createElement('li');
  liTagForDeleateButton.id = 'deleateMarker_' + memoId;
  liTagForDeleateButton.classList.add('deleateMarker');
  liTagForDeleateButton.dataset.id = memoId;
  ulTagForDeleteButton.appendChild(liTagForDeleateButton);

  return popupElement
}
