import { useState } from 'react';

//シームレス地質図をマップ上に表示するロジック
export const useGeologyMap = () => {
  // 地質図ラスターのcheckboxの状態を管理
  const [geologyLayerState, setGeologyLayerState] = useState(false); //true時に地質図をマップ上に表示
  const handleChangegeologyLayerState = (event) => {
    setGeologyLayerState(event.target.checked)
    // シームレス地質図を表示中は凡例を調べるボタンを表示する
    if(event.target.checked){
      document.getElementById('geologyCheck') && document.getElementById('geologyCheck').classList.add('buttonActive');
    }else{
      document.getElementById('geologyCheck') && document.getElementById('geologyCheck').classList.remove('buttonActive');
    }
  }

  //地質図の不透明度を定義
  const [geologyMapOpacity, setGeologyMapOpacity] = useState(60);
  const handleChangeGeologyMapOpacity = (event, newValue) => {
    setGeologyMapOpacity(newValue)
  }

  /** 地質図の凡例情報を取得できる状態をON,OFFで管理 */
  const[GeologyDataGetMode, setgeologyDataGetMode] = useState(false); // 凡例情報取得モードON,OFFのフラグ
  // 凡例情報取得ボタン押下時の処理
  const handleChangeGeologyDataMode = (value) => {
    setgeologyDataGetMode(!value);
  }

  return{
    geologyLayerState,
    geologyMapOpacity,
    GeologyDataGetMode,
    setgeologyDataGetMode,
    handleChangegeologyLayerState,
    handleChangeGeologyMapOpacity,
    handleChangeGeologyDataMode,
  }
}