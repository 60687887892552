import { useEffect, useState } from "react"
import { postRequest } from 'utils/_function';
export const useGetPurchaseInfo = (projectId, AccountGroupId, setMapLoading, setIsShowPurchaseAlertPopup) => {
  
  const errorExeNum = 3; // API実行のチャレンジ回数
  const [aoiPurchaseData, setAoiPurchaseData] = useState([]); // 購入フラグを格納
  const [projectAoiType, setProjectAoiType] = useState(null); // AOIタイプを格納Grid or Free
  const [aoiPurchaseExeFlag, setAoiPurchaseExeFlag] = useState(false); // 非購入AOIのスタイルを変更する実行フラグ  
  const [fetchErrorNum, setFetchErrorNum] = useState(0); // APIアクセス時のエラー回数

  /** 画面初期描画時 */
  // 各AOIが購入済みか非購入かのデータを取得
  useEffect(() => {
    setMapLoading(true); // ローディングオン
    if(!AccountGroupId) return;
    if (fetchErrorNum > errorExeNum) return;
    const params = {
      projectId: String(projectId),
      AccountGroupId: String(AccountGroupId)
    }
    const fetchGetPurchase = async () => {
      try{
        const response = await postRequest('IM_Lambda', '/im-getpurchaseinfo', params);
        const purchaseIds = response.result.purchased.map(value => value[0]);
        setAoiPurchaseData(purchaseIds);
        setProjectAoiType(response.result.type);
        setAoiPurchaseExeFlag(true);
      }catch{
        setFetchErrorNum(fetchErrorNum => fetchErrorNum + 1); // エラー回数をカウントアップ
        // データの取得に3回取得失敗したら実行
        if(fetchErrorNum === errorExeNum){
          setIsShowPurchaseAlertPopup(true); // リダイレクトモーダルを表示
          setMapLoading(false); // ローディングオフ
        }
      }
    }
    fetchGetPurchase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AccountGroupId, fetchErrorNum]);

  //　非購入AOIのfillの透明度を変更する処理
  const handleChangePurchaseFill = (mapData, filter, match, other) => {
    mapData.setPaintProperty('aoisInner', 'fill-opacity', [ // AOIの内側描画色のスタイルを変更
      'case',
      ['!', filter],
      match, // filter内で定義したIDに対して適用
      other // それ以外
    ]);
  }

  //　非購入AOIのアウトラインの透明度を変更する処理
  const handleChangePurchaseOutLine = (mapData, filter, match, other) => {
    mapData.setPaintProperty('aoisOutline', 'line-opacity', [ // AOIのアウトラインのスタイルを変更
      'case',
      ['!', filter],
      match, // filter内で定義したIDに対して適用
      other // それ以外
    ]);
  }

  return{
    aoiPurchaseData,
    projectAoiType,
    aoiPurchaseExeFlag,
    handleChangePurchaseFill,
    handleChangePurchaseOutLine,
    setAoiPurchaseExeFlag,
  }
}