import { useState } from 'react';

// 解析モードを変更するロジック
export const useChangeAnalyticeMode = (setOutputType, t, analysisVer) => {

  const [AnalyticeModeType, setAnalyticeModeType] = useState('ground'); // ドロワーメニューのラジオボタン制御
  const [isSlider, setIsSlider] = useState(true); //ドロワーメニューの凡例スライダーの表示非表示を制御
  const [AnalyticeIsShowAoi, setAnalyticeIsShowAoi] = useState([]); // 解析ポイントが表示されているAOIのIDを格納
  const [usageGuideunit, setUsageGuideunit] = useState(t("map.year")) // ドロワーメニューの凡例単位を定義

  // 解析モードの種類を定義
  let ModeTypes;
  if(analysisVer >= 2.0){
    //analysis.geojsonのバージョンが2の場合
    ModeTypes = [
      {ground: t("map.measuredValue")}, // 計測値
      {slope: t("map.rank")}, // ランク
      {gDirection: t("map.slopeDirecton")}, // 勾配の向き
      {theta: t("map.theta")} // シータ
      // {gAngle: t("map.slopeAngle")}, // 勾配の角度
    ];
  }else if(analysisVer >= 1.0){
    //analysis.geojsonのバージョンが1の場合
    ModeTypes = [
      {ground: t("map.measuredValue")}, //　計測値
      {slope: t("map.rank")}, // ランク
    ];
  }
    
  // 解析モードのradioボタン切り替え時の処理
  const handleChangeAnalyticeMode = (event) => {
    setAnalyticeModeType(event.target.value)
    if(event.target.value === 'ground') {
      setIsSlider(true);
      setUsageGuideunit(t("map.year"));
    };
    if(event.target.value === 'slope') {
      setIsSlider(false);
      setUsageGuideunit(t("map.month"));
      setOutputType('velocity');
    };
  }

  /**
   * 解析点の色を濃くする処理
   * @param {*} baseColorCode 色コード16進数(#fffff)
   * @param {*} darkWeight 濃くする度合い(0.0 ~ 1.0)
   * @returns カラーコード 色コード16進数(#fffff)
   */
  const getDarkColor = (baseColorCode, darkWeight) => {
    // 10進数に変換
    let red = parseInt(baseColorCode.substring(1, 3), 16);
    let green = parseInt(baseColorCode.substring(3, 5), 16);
    let blue = parseInt(baseColorCode.substring(5, 7), 16);
    // 少し濃くする
    let redNew = Math.round(red * (1 - darkWeight));
    let greenNew = Math.round(green * (1 - darkWeight));
    let blueNew = Math.round(blue * (1 - darkWeight));
    // 16進数にに再変換
    let rHex = redNew.toString(16);
    rHex = ('00' + rHex).slice(-2);
    let gHex = greenNew.toString(16);
    gHex = ('00' + gHex).slice(-2);
    let bHex = blueNew.toString(16);
    bHex = ('00' + bHex).slice(-2);
    
    return '#' + rHex + gHex + bHex;
  }

  return {
    AnalyticeModeType,
    isSlider,
    AnalyticeIsShowAoi,
    usageGuideunit,
    ModeTypes,
    handleChangeAnalyticeMode,
    setAnalyticeIsShowAoi,
    getDarkColor,
  }
}