import { useState } from 'react';

  //AOIの内側領域の色を変更する機能のロジック
export const useAoiInsideColor = () => {
  const [selectInsideColor, setSelectInsideColor] = useState('rankAllAve'); // 解析対象範囲の変動ランクの表示設定で選択された値
  const [aoiInsideColorBtn, setAoiInsideColorBtn] = useState('rankAllAve'); // 設定ボタン押下時に変動ランクの表示設定で選択された値を格納
  const [prevInsideColorId, setprevInsideColorId] = useState({}); // AOI内側領域の色を設定したAOIのidのリストを格納

  /** 解析対象範囲の変動ランクの表示設定の変更処理 */
  const handleChangeSelectInsideColor = (event) => {
    setSelectInsideColor(event.target.value);
  }

  /** 変動ランクの表示設定で選択された値で変動ランクごとに各AOIのidを分ける処理 */
  const handleClickAoiInsideColorChange = () => {
    setAoiInsideColorBtn(selectInsideColor);
  }
  return {
    selectInsideColor,
    aoiInsideColorBtn,
    prevInsideColorId,
    handleChangeSelectInsideColor,
    handleClickAoiInsideColorChange,
    setprevInsideColorId,
  }
}