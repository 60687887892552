import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { S3_URL } from "utils/constants";
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
  logoImg: {
    padding: '0 0 0 20px',
    width: '380px',
  },
  siteName: {
    position: 'relative',
    top: '2px',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  accoutIcon: {
    fontSize: '1.2rem',
    marginRight: '10px'
  },
}));

export default function Header(props) {
  const {
    user,
    userName,
    accountGroupName,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <div id="logoArea" className={classes.textAlignLeft}>
        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
          <img
            className={classes.logoImg}
            src={`${S3_URL}img/logo.png`}
            alt="スカパーJSAT NIPPON KOEI ZENRIN"
          />
        </Link>
      </div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
              <span className={classes.siteName}>LIANA</span>
            </Link>
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClick}>
            <AccountCircleRoundedIcon />
          </IconButton>
          <Menu
            style={{ top: '25px' }}
            id="simple-menu"
            className="accountMenu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem><GroupIcon className={classes.accoutIcon} />{accountGroupName && accountGroupName}</MenuItem>
            <MenuItem><PersonIcon className={classes.accoutIcon} />{userName && userName}</MenuItem>
            <MenuItem><MailIcon className={classes.accoutIcon} />{user.attributes && user.attributes.email}</MenuItem>
            <AmplifySignOut onClick={handleClose}></AmplifySignOut>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
