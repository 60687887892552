import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Getパラメータによって各機能の挙動を制御する
export const useUrlGetParamConfig = () => {
  // Getパラメータを取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  /**
   * 配列内の各要素は各機能の挙動を状況を表している
   * 0→通常,1→挙動変更
   * 1番目:凡例値の上限変更
   * 2番目:
   * 3番目:
   * 4番目:
   * 5番目:
   * 6番目:
   * 7番目:
   * 8番目:
   */
  const [enhanceOptions, setEnhanceOptions] = useState([0,0,0,0,0,0,0,0]);

  // 1番目:凡例値の上限変更
  const [qmaxValue, setQmaxValue] = useState(50); // 凡例の上限値を定義

  /** 画面初期描画時 */
  useEffect(()=> {
    // 1番目:凡例値の上限変更
    if(query.get('qmax')){
      let qmax = Number(query.get('qmax'));
      // qmaxの値が、50~999の場合は上限値を更新
      if (qmax >= 50 && qmax <= 999) {
        setQmaxValue(qmax);
      }
      // オプション配列の更新
      setEnhanceOptions(
        enhanceOptions.map((enhanceOption, index) => (index === 0 ? 1 : enhanceOption))
      );
    }
  },[]);

  return{
    enhanceOptions,
    qmaxValue,
  }
}