import { useState, useEffect } from 'react';
import { postRequest } from 'utils/_function';

//　初期表示位置情報を取得するロジック
export const useConfigUserLatLng = (projectId, useId) => {
  const [configUserLat, setConfigUserLat] = useState(0); // 設定した緯度を格納
  const [configUserLng, setConfigUserLng] = useState(0); // 設定した経度を格納
  const [configUserLatLngFlag, setConfigUserLatLngFlag] = useState(false); // 初期表示位置の設定・未設定のフラグ
  const [configUserLatLngFetchFlag, setConfigUserLatLngFetchFlag] = useState(false); // fetchが完了したフラグ
  const [configUserLatLngErrorFlag, setConfigUserLatLngErrorFlag] = useState(false); // 初期表示位置情報の取得に失敗した際のフラグ

  const fetchConfigUserLatLng = async () => {
    const params = {
      projectId: String(projectId),
      userId: String(useId),
    }
    const response = await postRequest('IM_Lambda', '/im-getuserprojectlatlng', params);
    if (response.errorMessage) {
      setConfigUserLatLngErrorFlag(true);
      return;
    }
    if (!response.statusCode) {
      setConfigUserLatLngErrorFlag(true);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        setConfigUserLat(response.ConfigLatitude);
        setConfigUserLng(response.Configlongitude);
        setConfigUserLatLngFlag(response.ConfigFlag);
        setConfigUserLatLngFetchFlag(true);
        break;
      case "E0270": //　必須項目がない
        setConfigUserLatLngErrorFlag(true);
        break;
      case "E0260": // その他のエラー
        setConfigUserLatLngErrorFlag(true);
        break;
      default:
        setConfigUserLatLngErrorFlag(true);
        break;
    }
  }

  /** 画面初期描画時 */
  useEffect(() => {
    fetchConfigUserLatLng();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    configUserLat,
    configUserLng,
    configUserLatLngFetchFlag,
    configUserLatLngFlag,
    configUserLatLngErrorFlag,
    setConfigUserLatLngErrorFlag,
    setConfigUserLatLngFetchFlag,
  }
}