import { useState, useEffect } from "react";

// 解析点の色やサイズを変更する処理
export const useConfigAnalysisPointState = (analysisPointSize, AnalyticeModeType, selectedSelectBoxStartDate, selectedSelectBoxEndDate, setQuantitySearchDate, setSelectedLegendMin, setSelectedLegendMax, legendMin, legendMax) => {
  const [analysisStateChangeFlag, setAnalysisStateChangeFlag] = useState(false); // 解析点の状態変更ボタンがクリックを検知
  const [analysisStateChangeButtonState, setAnalysisStateChangeButtonState] = useState(false); // 解析点の状態変更ボタンのdisbleを定義
  const [nowAnalysisPointState, setNowAnalysisPointState] = useState({ // 現在の解析点の状態を保存
    size: analysisPointSize, // 解析点の大きさ
    type: AnalyticeModeType // 解析点の色
  });

  // // 解析点の変更(セレクトボックス)が行われた際の処理
  const handleChangeAnalysisPointState = (value) => {
    // 解析点の状態を変更する処理
    setAnalysisStateChangeFlag(prevState => !prevState);
    // 現在の設定値を保存
    setNowAnalysisPointState(prevState => ({
      ...prevState,
      size: value.size,
      type: value.type
    }));
    //凡例の値を更新
    setQuantitySearchDate({start: selectedSelectBoxStartDate, end: selectedSelectBoxEndDate});
    setSelectedLegendMin(value.legendMin);
    setSelectedLegendMax(value.legendMax);
  }
  
  // 解析点の状態が前回の設定内容と異なる場合はボタンを活性化する
  // NOTE:前回の解析点の設定を記憶する機能を実装後に反映ボタンの非活性のタイミングの処理を追加する
  useEffect(() => {
    // 凡例スライダーの値が0の場合はエラーになるの反映ボタンを非活性にする
    if (legendMin === 0) {
      setAnalysisStateChangeButtonState(true); // 非活性
      return;
    }
    setAnalysisStateChangeButtonState(false); // 活性
  }, [analysisPointSize, AnalyticeModeType, legendMin])

  return {
    analysisStateChangeFlag,
    nowAnalysisPointState,
    handleChangeAnalysisPointState,
    analysisStateChangeButtonState
  };
};