import { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useGeologyDataPopup } from 'hooks/useGeologyDataPopup'

const useStyles = makeStyles({
  table: {
    width: '100%',
    fontSize: '12px',
    boxSizing: 'border-box',
    backfaceVisibility: 'hidden',
  },
  cell:{
    fontSize: '11px',
    padding: '12px'
  },
  th:{
    width: '30%',
  },
  td:{
    width: '70%',
  },
  progressBox:{
    padding: '10px 0 3px 0',
  },
  progress:{
    width: '30px !important',
    height: '30px !important',
    margin: '0 auto',
  }
});

const createData = (name, calories) => {
  return { name, calories };
}

// 地質図凡例情報のマーカーのポップアップのコンポーネント
export default function GeologyPopup(props){
  const {lng, lat, geologyDataFetchFlag, setGeologyDataFetchFlag } = props;
  const { loadingFlag, getGeologyData, rowsData } = useGeologyDataPopup(lng, lat);
  const classes = useStyles();

  const rows = [
    createData('凡例記号', rowsData.symbol),
    createData('形成時代', rowsData.formationAge),
    createData('大区分', rowsData.group),
    createData('岩相', rowsData.lithology),
  ];

  //マップ上をクリックした際処理
  useEffect(() => {
    if(!geologyDataFetchFlag) return;

    // 地質図凡例情報を取得する関数を実行
    getGeologyData();

    setGeologyDataFetchFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[geologyDataFetchFlag])

  return(
    <TableContainer component={Paper}>
    { loadingFlag ? 
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell className={classes.cell + " " + classes.th} align="left" component="th" scope="row">{row.name}</TableCell>
              <TableCell className={classes.cell + " " + classes.td} align="left">{row.calories}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      :
      <div className={classes.progressBox}>
        <CircularProgress
          className={classes.progress}
        />
      </div>
    }
  </TableContainer>
  )
}