import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import 'css/PopupAoiList.scss';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useSetRecoilState } from 'recoil';
import { refProjectIdState, currentLangState, latitudeCenterState, longitudeCenterState, datesState, myProjectIdState, qmaxValueState, zoomState } from 'atoms/projectData';

const useStyles = makeStyles(() => ({
  link: {
    color: '#004ea2',
    display: 'block',
    padding: '0 10px',
    textDecoration: 'none',
  },
  listItem: {
    paddingLeft: '5px;',
    wordBreak: 'break-all',
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
}));

/**
 * AOI一覧
 */
export default function PopupAoiList(props) {
  const {
    t,
    openAoiPopup,
    handleCloseOpenAoiPopup,
    popupAoiDatas,
    selectedRefProjectId,
    clickAoiListDates,
    qmaxValue,
    currentLang
  } = props;

  // Recoil更新関数を定義
  const setRefProjectId = useSetRecoilState(refProjectIdState); // ProjectId
  const setCurrentLang = useSetRecoilState(currentLangState);
  const setLatitudeCenter = useSetRecoilState(latitudeCenterState);
  const setLongitudeCenter = useSetRecoilState(longitudeCenterState);
  const setProjectDates = useSetRecoilState(datesState);
  const setMyProjectId = useSetRecoilState(myProjectIdState);
  const setQmaxValue = useSetRecoilState(qmaxValueState);
  const setZoom = useSetRecoilState(zoomState);

  // Recoilに保存
  const handleChangeProjectId = (lat, lng, selectedRefProjectId, clickAoiListDates, qmaxValue, currentLang) => {
    setLatitudeCenter(lat);
    setLongitudeCenter(lng);
    setRefProjectId(selectedRefProjectId);
    setMyProjectId(selectedRefProjectId);
    setProjectDates(clickAoiListDates);
    setQmaxValue(qmaxValue);
    setCurrentLang(currentLang);
    setZoom(16);
  }

  const classes = useStyles();

  //　変動ランク一覧のheader情報
  const columns = [
    { id: 'id', label: t("dashboard.no"), minWidth: 30, align: 'right' },
    { id: 'aoiName', label: t("dashboard.district"), minWidth: 90, align: 'right'  },
    { id: 'lat',
      label: t("dashboard.lng"),
      minWidth: 65,
      align: 'right', 
      format: (value) => value.toFixed(4)
    },
    { id: 'lng',
      label: t("dashboard.lat"),
      minWidth: 65,
      align: 'right',
      format: (value) => value.toFixed(4)
    },
    { id: 'rankAllAve', label: t("dashboard.wholePeriodAverage"), minWidth: 100, align: 'center' },
    { id: 'rankAllMax', label: t("dashboard.wholePeriodMax"), minWidth: 100, align: 'center' },
    { id: 'rankRecAve', label: t("dashboard.twoPeriodsAverage"), minWidth: 100, align: 'center' },
    { id: 'rankRecMax', label: t("dashboard.twoPeriodsMax"), minWidth: 100, align: 'center' },
    { id: 'link', label: t("dashboard.linkToMap"), minWidth: 80, align: 'center' },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={openAoiPopup}
        onClose={handleCloseOpenAoiPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg'
      >
        <DialogContent>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table
               stickyHeader
               aria-label="sticky table"
               className='popupAoiListArea'
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {popupAoiDatas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            (
                              column.id === 'rankAllAve'
                              ?
                                // 全期間の斜面変動ランク(平均変位)列
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={`${row.rankAllAve === '変動A’’(警戒変動)' ? 'cellRank1' : ''}${row.rankAllAve === '変動A’(注意変動)' ? 'cellRank2' : ''}${row.rankAllAve === '変動A(確定変動)' ? 'cellRank3' : ''}${row.rankAllAve === '変動B(準確定変動)' ? 'cellRank4' : ''}${row.rankAllAve === '変動C(潜在変動)以下' ? 'cellRank5' : ''}${row.rankAllAve === '解析データなし' ? 'nodata' : ''}`}
                                >
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              :
                              column.id === 'rankAllMax'
                              ?
                                // 全期間の斜面変動ランク(最大変位)列
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={`${row.rankAllMax === '変動A’’(警戒変動)' ? 'cellRank1' : ''}${row.rankAllMax === '変動A’(注意変動)' ? 'cellRank2' : ''}${row.rankAllMax === '変動A(確定変動)' ? 'cellRank3' : ''}${row.rankAllMax === '変動B(準確定変動)' ? 'cellRank4' : ''}${row.rankAllMax === '変動C(潜在変動)以下' ? 'cellRank5' : ''}${row.rankAllMax === '解析データなし' ? 'nodata' : ''}`}
                                >
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              :
                              column.id === 'rankRecAve'
                              ?
                                // 直近2時期の斜面変動ランク(平均変位)列
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={`${row.rankRecAve === '変動A’’(警戒変動)' ? 'cellRank1' : ''}${row.rankRecAve === '変動A’(注意変動)' ? 'cellRank2' : ''}${row.rankRecAve === '変動A(確定変動)' ? 'cellRank3' : ''}${row.rankRecAve === '変動B(準確定変動)' ? 'cellRank4' : ''}${row.rankRecAve === '変動C(潜在変動)以下' ? 'cellRank5' : ''}${row.rankRecAve === '解析データなし' ? 'nodata' : ''}`}
                                >
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              :
                              column.id === 'rankRecMax'
                              ?
                                // 直近2時期の斜面変動ランク(最大変位)列
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={`${row.rankRecMax === '変動A’’(警戒変動)' ? 'cellRank1' : ''}${row.rankRecMax === '変動A’(注意変動)' ? 'cellRank2' : ''}${row.rankRecMax === '変動A(確定変動)' ? 'cellRank3' : ''}${row.rankRecMax === '変動B(準確定変動)' ? 'cellRank4' : ''}${row.rankRecMax === '変動C(潜在変動)以下' ? 'cellRank5' : ''}${row.rankRecMax === '解析データなし' ? 'nodata' : ''}`}
                                >
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              :
                              column.id === 'link'
                              ?
                                // 地図へのリンク
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                >
                                  <Link
                                    className={classes.link}
                                    onClick={() => handleChangeProjectId(row.lat, row.lng, selectedRefProjectId, JSON.parse(clickAoiListDates.replace(/'/g, '"')), qmaxValue, currentLang)}
                                    to={{
                                      pathname: `/map`,
                                    }}
                                  >
                                    {value}
                                  </Link>
                                </TableCell>
                              :
                                //その他の列
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                            )
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={popupAoiDatas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </DialogContent>
      </Dialog>
    </div>
  );
}
