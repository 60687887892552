import { useState } from 'react';

//地質図の凡例情報を取得し、マップ上に表示するロジック
export const useGeologyDataPopup = (lng, lat) => {
  const [geologyLngLatData, setgeologyLngLatData] = useState({}); // 凡例情報を取得する座標を格納 
  const [geologyDataFetchFlag, setGeologyDataFetchFlag] = useState(true); // 凡例情報取得APIを叩くフラグ

  // マップ上に凡例情報用のマーカーを作成する
  const geologyCreateMarker = () =>{
    const el = document.createElement('div');
    el.className = 'geology-marker';
    return el;
  }

  // 凡例情報取得モード時にマップをクリックしたときのイベント
  const getGeologyLngLat = (e) => {
    setGeologyDataFetchFlag(true);
    setgeologyLngLatData(e.lngLat);
  }

  // ローディングの表示非表示を管理
  const [loadingFlag, setLoadingFlag] = useState(false);
  // URLを定義
  const geologyDataUrl = `https://gbank.gsj.jp/seamless/v2/api/1.3/legend.json?point=${lat},${lng}`;

  // 凡例情報表示するまでのダミーデータ
  const [rowsData, setRowsData] = useState({
    symbol: '取得中...',
    formationAge: '取得中...',
    group: '取得中...',
    lithology: '取得中...',
  })

  // 地質図凡例情報を取得
  const getGeologyData = async () => {
    await fetch(geologyDataUrl).then(response => response.json())
    .then((response) => { // 成功時
      setRowsData({
        symbol: response.symbol,
        formationAge: response.formationAge_ja,
        group: response.group_ja,
        lithology: response.lithology_ja,
      })
      setLoadingFlag(true);
    })
    .catch((error) => { // エラー時
      setRowsData({
        symbol: '取得できませんでした',
        formationAge: '取得できませんでした',
        group: '取得できませんでした',
        lithology: '取得できませんでした',
      })
      setLoadingFlag(true);
    });
  }

  return {
    geologyLngLatData,
    geologyDataFetchFlag,
    setGeologyDataFetchFlag,
    geologyCreateMarker,
    getGeologyLngLat,
    loadingFlag,
    getGeologyData,
    rowsData,
  }
}