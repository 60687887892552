import { API } from 'aws-amplify';
/**
 * APIリクエスト用
 */
export const postRequest = (apiName, path, params) => {
  const myInit = {
    body: { Keys: params }
  };
  return API.post(apiName, path, myInit);
}
/**
 * APIリクエスト用 Keysが無い場合
 */
export const postRequestNoneKey = (apiName, path, params) => {
  const myInit = {
    body: params
  };
  return API.post(apiName, path, myInit);
}
/**
 * 日付のフォーマット変更
 */
export const changeDateFormat = (dateTime) => {
  if (!dateTime) return;
  const dateTimeArray = dateTime.split("_");
  let yyyyMMdd = dateTimeArray[0];
  yyyyMMdd = `${yyyyMMdd.slice(0, 4)}-${yyyyMMdd.slice(
    4,
    6
  )}-${yyyyMMdd.slice(6, 8)}`;
  return `${yyyyMMdd}`;
};
/**
 * AOIの名前からAOIのIDに変換
 */
export const getAoiIdFromAoiName = (aoiName, aoisData) => {
  const targetAoi = aoisData.filter(aoiData => aoiData.properties.aoiName === aoiName)[0];
  return targetAoi ? targetAoi.id : null;
}
/**
 * AOIのIDからAOIの名前に変換
 */
export const getAoiNameFromAoiId = (aoId, aoisData) => {
  const targetAoi =  aoisData.filter(aoiData => aoiData.id === aoId)[0];
  return targetAoi ? targetAoi.properties.aoiName : null;
}

/**
 * 斜面変動ランクの数値を文字列に変換
 */
export const getAoiRankName = (rankDataNum) => {
  let rankNameList = {};
  rankDataNum.forEach(value => {
    let rankText =  '';
    switch (value.num){
      case 1:
        rankText = '変動A’’(警戒変動)';
      break;
      case 2:
        rankText = '変動A’(注意変動)';
      break;
      case 3:
        rankText = '変動A(確定変動)';
      break;
      case 4:
        rankText = '変動B(準確定変動)';
      break;
      case 5:
        rankText = '変動C(潜在変動)以下';
      break;
      case 'no_data':
        rankText = '解析データなし';
      break;
    }
    rankNameList[value.name] = rankText;
  })
  return rankNameList;
}