import { atom } from 'recoil';
import { recoilPersist } from "recoil-persist";

//標準でrecoil-persistというkey名でwebstorageに保存される（オプションで指定可能）
const { persistAtom } = recoilPersist();

// 言語設定
export const currentLangState = atom({
  key: 'currentLangState',
  default: '',
  effects_UNSTABLE: [persistAtom] // 永続化
});

// lat
export const latitudeCenterState = atom({
  key: 'latitudeCenterState',
  default: '',
  effects_UNSTABLE: [persistAtom] // 永続化
});

// lon
export const longitudeCenterState = atom({
  key: 'longitudeCenterState',
  default: '',
  effects_UNSTABLE: [persistAtom] // 永続化
});

// 日付
export const datesState = atom({
  key: 'datesState',
  default: [],
  effects_UNSTABLE: [persistAtom] // 永続化
});

// myProjectId
export const myProjectIdState = atom({
  key: 'myProjectIdState',
  default: '',
  effects_UNSTABLE: [persistAtom] // 永続化
});

// qmaxValue
export const qmaxValueState = atom({
  key: 'qmaxValueState',
  default: '',
  effects_UNSTABLE: [persistAtom] // 永続化
});

// pjName
export const pjNameState = atom({
  key: 'pjNameState',
  default: '',
  effects_UNSTABLE: [persistAtom] // 永続化
});

// ProjectId
export const refProjectIdState = atom({
  key: 'refProjectIdState',
  default: '',
  effects_UNSTABLE: [persistAtom] // 永続化
});

// zoom
export const zoomState = atom({
  key: 'zoomState',
  default: 12, // 初期ズーム率
  effects_UNSTABLE: [persistAtom] // 永続化
});