import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  copy_right:{
    position: 'absolute',
    top: '-22px',
    right: '114px',
    background: 'rgba(255,255,255,0.5);',
    padding: '2px 5px 2px 5px',
    textAlign: 'right',
    visibility: 'visible'
  },
  geologyLayerOpen:{
    top: '-55px',
  },
  copy_right_link:{
    textDecoration: 'none',
    color: '#000',
    fontSize: '11px',
    fontWeight: 'normal',
    paddingRight: '5px',
    '&:hover':{
      textDecoration: 'underline',
    },
    '&:last-child':{
      paddingRight: '0',
    }
  },
  no_hover:{
    cursor: 'default',
    '&:hover':{
      textDecoration: 'none',
    },
  },
}));

// 著作権表記のコンポーネント
export default function CopyRight(props){
  const classes = useStyles();
  const { mapStyle, geologyLayerState } = props;

  // 国土地理院の地図
  // @see https://maps.gsi.go.jp/development/ichiran.html
  const mapStylesWithGsi = [
    'gsi_map',
    'gsi_img',
  ]

  // ZENRINの地図
  const mapStylesWithZenrin = [
    'zenrin',
    'znet',
  ]

  // mapboxの地図
  // @see https://docs.mapbox.com/help/getting-started/attribution/
  const mapStylesWithMapbox = [
    'mapbox://styles/mapbox/dark-v10',
    'mapbox://styles/mapbox/satellite-v9',
  ]

  return(
    <div className={classes.copy_right + " " + (geologyLayerState ? classes.geologyLayerOpen : '')}>
      {/* https://gbank.gsj.jp/seamless/agreement.html */}
      {geologyLayerState &&
        <div>
          <div className={classes.copy_right_link + " " + classes.no_hover}>産総研地質調査総合センター,20万分の1日本シームレス地質図V2(地質図更新日:2022年3月11日)</div>
          <div className={classes.copy_right_link + " " + classes.no_hover}>
            <a className={classes.copy_right_link} href='https://gbank.gsj.jp/seamless' target="_blank" rel="noreferrer">https://gbank.gsj.jp/seamless</a>
            <span className={classes.copy_right_link + " " + classes.no_hover}>GSJ,AIST,20万分の1日本シームレス地質図V2</span>
          </div>
        </div>
      }
      { mapStylesWithMapbox.includes(mapStyle) &&
        <div>
          <a className={classes.copy_right_link} style={{pointerEvents: 'none'}}>&copy; LIANA</a>
          <a className={classes.copy_right_link} href='https://www.mapbox.com/about/maps/' target="_blank" rel="noreferrer">&copy; Mapbox</a>
          <a className={classes.copy_right_link} href='https://www.openstreetmap.org/about/' target="_blank" rel="noreferrer">&copy; OpenStreetMap</a>
          <a className={classes.copy_right_link} href='https://www.mapbox.com/contribute/' target="_blank" rel="noreferrer">Improve this map</a>
          <a className={classes.copy_right_link} href='https://www.maxar.com/' target="_blank" rel="noreferrer">&copy; Maxar</a>
          <span className={classes.copy_right_link + " " + classes.no_hover}>&copy; Original ALOS-2 data provided by JAXA Tellus Satellite Data Master is used.</span>
        </div>
      }
      { mapStylesWithZenrin.includes(mapStyle) &&
        <div>
          <a className={classes.copy_right_link} style={{pointerEvents: 'none'}}>&copy; LIANA</a>
          <a className={classes.copy_right_link} href='https://www.mapbox.com/about/maps/' target="_blank" rel="noreferrer">&copy; 2022 ZENRIN CO.,LTD.</a>
          <span className={classes.copy_right_link + " " + classes.no_hover}>&copy; Original ALOS-2 data provided by JAXA Tellus Satellite Data Master is used.</span>
        </div>
      }
      { mapStylesWithGsi.includes(mapStyle) &&
          <div>
            <a className={classes.copy_right_link} style={{pointerEvents: 'none'}}>&copy; LIANA</a>
            <a className={classes.copy_right_link} href='https://maps.gsi.go.jp/development/ichiran.html' target="_blank" rel="noreferrer">&copy; 国土地理院</a>
            <span className={classes.copy_right_link + " " + classes.no_hover}>&copy; Original ALOS-2 data provided by JAXA Tellus Satellite Data Master is used.</span>
          </div>
      }
    </div>
  )
}

