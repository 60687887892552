import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import * as turf from '@turf/turf';
import { makeStyles } from '@material-ui/core/styles';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Legend from 'components/map/Legend';
import Summary from 'components/map/Summary';
import ProjectDetail from 'components/map/ProjectDetail';
import MemoPopup from 'components/map/MemoPopup';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import 'css/Geocoder.scss';
import 'css/MapboxMap.scss';
import StreetviewIcon from '@material-ui/icons/Streetview';
import CreateIcon from '@material-ui/icons/Create';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ColorizeIcon from '@material-ui/icons/Colorize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from '@material-ui/core/Snackbar';
import AlertMessage from 'components/common/AlertMessage';
import AoiFilterModal from 'components/map/AoiFilterModal';
import AoiGridFilterModal from 'components/map/AoiGridFilterModal';
import PurchaseAlertPopup from 'components/map/PurchaseAlertPopup';
import AlertMessageCloseable from 'components/common/AlertMessageCloseable';
import { postRequest, getAoiIdFromAoiName, getAoiNameFromAoiId } from 'utils/_function';
import { isEmpty } from '@aws-amplify/core';
import { useConfigUserLatLng } from 'hooks/useConfigUserLatLng'
import Tooltip from '@material-ui/core/Tooltip';
import { useGeologyDataPopup } from 'hooks/useGeologyDataPopup'
import GeologyPopup from 'components/map/GeologyPopup'
import { S3_URL } from "utils/constants";
import ToysIcon from '@material-ui/icons/Toys';
import emptyGeojson from 'geojson/empty.geojson'

mapboxgl.accessToken = 'pk.eyJ1IjoiaHlhdHQyNDI0IiwiYSI6ImNrZ3JiNGl0MjAwb2UzM3BydWE2end2cGsifQ.HlTgB4LpT2bzoyrO0zxAIA';

/*
* @see https://docs.mapbox.com/jp/mapbox-gl-js/example/mapbox-gl-geocoder/
* @see https://docs.mapbox.com/jp/mapbox-gl-js/example/mapbox-gl-geocoder-accept-coordinates/
* given a query in the form "lng, lat" or "lat, lng" returns the matching
* geographic coordinate(s) as search results in carmen geojson format,
* https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
*/
const coordinatesGeocoder = (query) => {
  // match anything which looks like a decimal degrees coordinate pair
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
    return null;
  }

  const coordinateFeature = (lng, lat) => {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat]
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature'
    };
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  const geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
};

const geocoder = new MapboxGeocoder({
  accessToken: mapboxgl.accessToken,
  localGeocoder: coordinatesGeocoder,
  mapboxgl: mapboxgl,
  collapsed: true,
  zoom: 13,
  placeholder: 'マップを検索する 例:【東京タワー】【138.4256 39.5942】',
});

/**
 * 距離測定・面積測定用のオブジェクト
 * @see https://github.com/mapbox/mapbox-gl-draw
 */
const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    line_string: true,
    polygon: true,
    trash: true
  },
});

/**
 * 選択中のAOIのIDを取得
 * @param {Object} aoisCheckState AOIが選択中か管理するオブジェクト ex) {'aoi 1', true, 'aoi 2', false, ...}
 * @param {Array} aoisData プロジェクトに紐づくAOIの情報が格納された配列
 * @returns [1, 3...] 選択中のAOIのIDの配列
 */
const getSelectedAoiIds = (aoisCheckState, aoisData) => {
  return Object.keys(aoisCheckState)
    .filter(aoiName => aoisCheckState[aoiName])
    .map(selectedAoi => getAoiIdFromAoiName(selectedAoi, aoisData));
}

const useStyles = makeStyles((theme) => ({
  calculationBox: {
    zIndex: 1200,
    position: 'absolute',
    bottom: '15px',
    left: '255px',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '10px 15px',
    textAlign: 'center',
  },
  calculationText: {
    fontFamily: 'Open Sans',
    margin: 0,
    fontSize: '13px',
  },
  listingGroup: {
    fontWeight: 600,
    position: 'absolute',
    top: '285px',
    right: '10px',
    zIndex: '1',
    borderRadius: '3px',
    color: '#fff'
  },
  listingGroupLabel: {
    border: 'none',
    display: 'inline-block',
  },
  listingGroupInput: {
    width: '100%',
    margin: '0',
    position: 'absolute',
    height: '100%',
    opacity: '0',
    cursor: 'pointer',
  },
  dragRotateButton: {
    padding: '0',
    minWidth: '0',
    width: '29px',
    height: '29px',
    background: '#fff',
  },
  active: {
    background: '#004ea2',
    '&:hover' : {
      background: '#004ea2',
    },
  },
  googleStreetViewButton: {
    background: '#fff',
    borderRadius: '3px',
    fontWeight: 600,
    minWidth: '0',
    padding: '5px',
    position: 'absolute',
    right: '10px',
    top: '325px',
    zIndex: '1',
    cursor: 'pointer',
  },
  googleStreetViewButtonActive: {
    background: '#004ea2',
    cursor: 'pointer',
    pointerEvents: 'auto',
    '&:hover' : {
      background: '#004ea2',
    },
  },
  legendWithSummaryOpen: {
    right: '55px',
    position: 'absolute',
    bottom: '450px',
    zIndex: '10',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
  },
  legendWithSummaryClose: {
    right: '55px',
    position: 'absolute',
    bottom: '55px',
    zIndex: '10',
    transition: 'left .3s ease-out',
  },
  memoButton: {
    background: '#fff',
    borderRadius: '3px',
    fontWeight: 600,
    minWidth: '0',
    padding: '5px',
    position: 'absolute',
    right: '10px',
    top: '365px',
    zIndex: '1',
  },
  memoButtonActive: {
    background: '#004ea2',
    borderRadius: '3px',
    fontWeight: 600,
    minWidth: '0',
    padding: '5px',
    position: 'absolute',
    right: '10px',
    top: '365px',
    zIndex: '1',
    '&:hover': {
    background: '#004ea2',
    },
  },
  currentLocationButtonActive: {
    background: '#fff',
    borderRadius: '3px',
    fontWeight: 600,
    minWidth: '0',
    padding: '5px',
    position: 'absolute',
    right: '10px',
    top: '405px',
    zIndex: '1',
  },
  defaultLocationButtonActive: {
    background: '#fff',
    borderRadius: '3px',
    fontWeight: 600,
    minWidth: '0',
    padding: '5px',
    position: 'absolute',
    right: '10px',
    top: '445px',
    zIndex: '1',
  },
  geologyCheckButtonActive: {
    background: '#fff',
    borderRadius: '3px',
    fontWeight: 600,
    minWidth: '0',
    padding: '5px',
    position: 'absolute',
    right: '10px',
    top: '485px',
    zIndex: '1',
  },
  geologyCheckButtonClicked: {
    backgroundColor: '#004ea2',
    '&:hover': {
      background: '#004ea2',
    }
  },
  placeIcon: {
    bottom: '-3px',
    fontSize: '1.2rem',
    margin: '0 3px 0 0',
    position: 'relative',
  },
  loading: {
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "0",
    margin: "0 0 0 -20px",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9999999",
  },
  highlightOffSharpIcon: {
    fontSize: '1.2rem',
    margin: '0 0 0 10px',
    position: 'absolute',
    right: '10px',
    top: '14px',
  },
  customTooltip: {
    background: 'rgb(158, 204, 255)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
    top: '5px',
  },
  customArrow: {
    color: 'rgb(158, 204, 255)',
  },
  HelpTwoToneIcon: {
    fontSize: "1rem",
    height: "auto",
  },
  helpButton: {
    cursor: "pointer",
    minWidth: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  helpAreaInner: {
    padding: "5px",
    position: "relative",
  },
  observationPopupContent: {
    marginTop: "10px",
  },
  observationPopupItem: {
    marginBottom: "5px",
    fontSize: "14px",
  },
}));

/**
 * 地図表示
 * @see https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
 * @param {Object} props MapPage.js からpropsを受け取る
 * @returns Mapboxの地図
 */
export default function MapboxMap(props) {
  // NOTE propsで受け取る以下コメントアウトしているパラメータは今後使う予定
  const {
    t,
    currentLang,
    user,             // ユーザー
    projectId,        // プロジェクトID
    accountGroupId,   // アカウントグループ
    mapLoading,
    setMapLoading,
    aoisGeojson,
    outputType,       // quantity(変位) or velocity(変動速度)
    mapInstanceStyle,
    mapLayerState,    // {地図ID: boolean, ...}
    mapLayersUrls,
    mapStyle,       //マップの背景地図
    latitudeCenter, // Map画面表示時の中心緯度
    longitudeCenter, // Map画面表示時の中心経度
    zoom,
    aoisState,
    handleChangeAoisState,
    aoisData,
    zoomAoiId,
    setZoomAoiId,
    aoisTableData,
    quantityDates,
    quantitySearchDate,
    selectedVelocityThreshold, // 変動速度閾値
    selectedQuantityThreshold, // 変位閾値
    selectedLegendMin, // 変動速度 or 変位 の凡例のmin
    selectedLegendMax, // 変動速度 or 変位 の凡例のmax
    objectLayerState, // {オブジェクトID: boolean, ...}
    setObjectLayerState,
    myProjectId,
    graphMaxConfig,
    graphMinConfig,
    sortAoiDataIdList,
    sortResetAoiDataIdList,
    prevAoiDeleteIdList,
    setPrevAoiDeleteIdList,
    prevAoiSearchIdList,
    setPrevAoiSearchIdList,
    setSelectRankPeriod,
    setSelectRankLevel,
    setSelectRankPeriodErrorFlag,
    setSelectRankLevelErrorFlag,
    // prevAoiDeleteIdList,
    selectInsideColor,
    aoiInsideColorBtn,
    prevInsideColorId,
    setprevInsideColorId,
    memoItems,
    memoStatusCode,
    memoErrorMessage,
    memoGetFirstFlag,
    setMemoGetFirstFlag,
    memoZoomItem,
    setMemoItems,
    setMemoStatusCode,
    nowLocationLatLng,
    nowLocationLoadingFlag,
    handleClickNowLocation,
    handleChangeInputAdminComment,
    handleClickAdminCommentKeep,
    setAdminCommentAoiId,
    aoiClickFlag,
    setAoiClickFlag,
    registerAdminCommentLoadingFlag,
    adminComment,
    getAdminCommentAlertFlag,
    setGetAdminCommentAlertFlag,
    fetchAoiAdminComment,
    mapNowLatLngFlag,
    setMapNowLatLngData,
    setMapNowLatLngFlag,
    setConfigUserLatLngDatas,
    mapRegsiterLatLngData,
    regsiterMessageFlag,
    setRegsiterMessageFlag,
    regsiterMessage,
    isSuccess,
    mapNowLatLngData,
    regsiterResetFlag,
    regsiterFetchFlag,
    setRegsiterFetchFlag,
    adminCommentResultMessage,
    isSuccessAdminComment,
    summaryTextData,
    setSummaryTextData,
    zenrinMapLoading,
    setZenrinMapLoading,
    zenrinErrorMessage,
    zenrinFetchErrorFlag,
    setZenrinFetchErrorFlag,
    znetFetchErrorFlag,
    setZnetFetchErrorFlag,
    znetErrorMessage,
    znetMapLoading,
    setZnetMapLoading,
    resetRegisterAdminComment,
    geologyLayerState,
    geologyMapOpacity,
    handleChangeGeologyDataMode,
    GeologyDataGetMode,
    setgeologyDataGetMode,
    AnalyticeModeType,
    memoUpdateFormStatus,
    memoUpdateTitle,
    memoUpdateText,
    memoUpdateId,
    openUpdateMemoPopup,
    isMemoTitleUpdateValid,
    isMemoFormUpdateValid,
    isUpdateAlertFormOpen,
    handleChangeUpdateMemoTitleValidate,
    handleChangeUpdateMemoFormValidate,
    handleUpdatePopupCancelButton,
    updateMemo,
    alertUpdateMessageMemoForm,
    upDateLoadingMemo,
    isUpdateMemoSuccess,
    AnalyticeIsShowAoi,
    setAnalyticeIsShowAoi,
    usageGuideunit,
    getDarkColor,
    isSnackbar,
    snackbarMessage,
    snackbarColor,
    handleCloseSnackbar,
    weaterObservationData,
    weatherPopupStatus,
    handleClickIsWeatherPopup,
    weaterObservationDataFetchFlag,
    observationPopupContent,
    handleClickCloseWeatherPopup,
    fetchWeatherData,
    observationPointData,
    raineyToggleFlag,
    weatherLoadingFlag,
    aoiIds,
    filterExecuteFlag,
    setIsFilterModal,
    setFilterExecuteFlag,
    isFilterModal,
    filterSearchFlag,
    filteringAois,
    aoiPeriodValue,
    aoiLevelValue,
    handleClickAoiFilterModalBtn,
    handleClickFilterResetBtn,
    handleChangeSearchFlag,
    handleClickFilterBtn,
    handleChangeInputFilterAoiName,
    handleChangeFilterPeriod,
    handleChangeFilterLevel,
    aoiNameInputValue,
    filterInputFlag,
    aoiPurchaseData,
    projectAoiType,
    isShowSnakbar,
    handleChangePurchaseFill,
    handleChangePurchaseOutLine,
    purchaseFlag,
    handleChangeFilterPurchaseCheck,
    aoiPurchaseExeFlag,
    setAoiPurchaseExeFlag,
    isShowPurchaseAlertPopup,
    isGridFilterModal,
    adjoinNum,
    gridAoiShowType,
    handleClickGridFilterModalBtn,
    handleChangeGridFilterAdjon,
    handleChangeGridFilterShowType,
    handleClickGridFilterExeBtn,
    handleClickGridFilterReset,
    isFetchfirstAoigeojson,
    reFetchAoiLoading,
    setReFetchAoiLoading,
    setIsShowPurchaseAlertPopup,
    analysisPointSize,
    pjName,
    analysisStateChangeFlag,
    analysisVer,
    nowAnalysisPointState,
  } = props;

  const classes = useStyles();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [clickedAoiId, setClickedAoiId] = useState(null);
  const clickedAoiIdRef = useRef();
  clickedAoiIdRef.current = clickedAoiId; // clickedAoiIdを最新の状態を参照する
  const aoisStateRef = useRef();
  aoisStateRef.current = aoisState; // aoiStateの最新の状態を参照する
  const [aoiTableData, setAoiTableData] = useState();
  const [aoiTableDataGeneratFlag, setAoiTableDataGeneratFlag] = useState(false); // summaryページへ渡すデータが作成されたフラグ
  const outputTypeRef = useRef();
  outputTypeRef.current = outputType; // outputTypeの最新の状態を参照する
  const quantitySearchDateRef = useRef();
  quantitySearchDateRef.current = quantitySearchDate; // quantitySearchDate（変位の日付）の最新の状態を参照する
  const selectedVelocityThresholdRef = useRef();
  selectedVelocityThresholdRef.current = selectedVelocityThreshold; // selectedVelocityThresholdの最新の状態を参照する
  const selectedQuantityThresholdRef = useRef();
  selectedQuantityThresholdRef.current = selectedQuantityThreshold; // selectedQuantityThresholdの最新の状態を参照する
  const selectedLegendMinRef = useRef();
  selectedLegendMinRef.current = selectedLegendMin; // selectedLegendMinの最新の状態を参照する
  const selectedLegendMaxRef = useRef();
  selectedLegendMaxRef.current = selectedLegendMax; // selectedLegendMaxの最新の状態を参照する
  const [pointDatasState, setPointDatasState] = useState([]);
  const [pointRows, setPointRows] = useState([]);
  const aoisTableDataRef = useRef();
  aoisTableDataRef.current = aoisTableData; // aoisTableDataの最新の状態を参照する
  const aoisDataRef = useRef();
  aoisDataRef.current = aoisData; // aoisDataの最新の状態を参照する
  const mapLayersUrlsRef = useRef();
  mapLayersUrlsRef.current = mapLayersUrls; // mapLayers
  const adminCommentRef = useRef();
  adminCommentRef.current = adminComment; // 管理者コメント情報の最新の状態を参照する
  const prevAoiDeleteIdListRef = useRef();
  prevAoiDeleteIdListRef.current = prevAoiDeleteIdList; // 絞り込み検索で該当しなかったaoiのidの最新の状態を参照する
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isAlertFormOpen, setIsAlertFormOpen] = useState(false);
  const [alertMessageMemo, setAlertMesseageMemo] = useState();
  const [alertMessageMemoForm, setAlertMessageMemoForm] = useState();
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [addLoadingMemo, setAddLoadingMemo] = useState(false); // ローディングアニメーションを表示するか
  const [currentZoom, setCurrentZoom] = useState(zoom);
  const [noDataAoiIds, setNoDataAoiIds] = useState([]); // 解析データが無いAOIのidを格納する
  const [clickAoiId, setClickAoiId] = useState(); // クリックしたAOIのidを格納
  const noDataAoiIdsRef = useRef();
  const memoErrorMessageRef = useRef();
  memoErrorMessageRef.current = memoErrorMessage; // メモ情報取得した際の最新のエラーメッセージを格納する
  noDataAoiIdsRef.current = noDataAoiIds;
  const memoUpdateIdRef = useRef();
  memoUpdateIdRef.current = memoUpdateId; // 更新したメモのIDの最新の状態を参照する
  const AnalyticeModeTypeRef = useRef();
  AnalyticeModeTypeRef.current = AnalyticeModeType; // 解析モードの最新状態を参照する
  const aoiPurchaseDataRef = useRef();
  aoiPurchaseDataRef.current = aoiPurchaseData; // AOIの購入済みフラグの最新状態を参照する
  const projectAoiTypeRef = useRef();
  projectAoiTypeRef.current = projectAoiType; // AOItypeの最新状態を参照する
  const analysisPointSizeRef = useRef();
  analysisPointSizeRef.current = analysisPointSize; // 解析ポイントの大きさの最新状態を参照する
  const analysisVerRef = useRef();
  analysisVerRef.current = analysisVer; // 解析ポイントの大きさの最新状態を参照する

  // mapの初期表示が終わっているかどうか
  // NOTE: 画面初期描画時のmapの初期化とmapInstanceStyle変更時のmapの初期化を区別するための変数
  const [isMapFirstLoadingDone, setIsMapFirstLoadingDone] = useState(false);
  const isMapFirstLoadingDoneRef = useRef();
  isMapFirstLoadingDoneRef.current = isMapFirstLoadingDone; // isMapFirstLoadingDoneの最新の状態を参照する

  /** 初期表示位置情報を取得するロジック */
  const { configUserLat, configUserLng, configUserLatLngFetchFlag, configUserLatLngFlag, configUserLatLngErrorFlag, setConfigUserLatLngErrorFlag, setConfigUserLatLngFetchFlag } = useConfigUserLatLng(projectId, user.attributes.email);

  // 解析データのGeoJSONのURLのprefix
  const analysisPointsUrlPrefix = `${S3_URL}geojson`;

  /** AOIのレイヤーを追加 */
  const addAoiLayers = (aoiData) => {

    // AOIソース
    if (!map.current.getSource('projectAois')) {
      map.current.addSource("projectAois", {
        type: "geojson",
        data: aoiData,
      });
    }

    // AOI上のマウスカーソルの扱い方を定義
    map.current.on('mouseenter', 'aoisInner', function () {
      map.current.getCanvas().style.cursor = 'pointer'; // AOI上にマウスが乗った際にカーソルを変更
    });
    map.current.on('mouseleave', 'aoisInner', function () {
      map.current.getCanvas().style.cursor = 'grab'; // AOI上からマウスが外れた際にカーソルを変更
    });

    // AOIレイヤー(内側領域全体)
    if (map.current.getLayer('aoisInner')) map.current.removeLayer('aoisInner');
    map.current.addLayer({
      id: 'aoisInner',
      type: 'fill',
      source: 'projectAois',
      paint: {
        'fill-color':[
          'case',
            ['boolean', ['feature-state', 'nodata'], false],
            '#3b3b3b', // 解析データなし
            ['boolean', ['feature-state', 'rank1'], false],
            '#7e017c', // 変動A’’(警戒変動)
            ['boolean', ['feature-state', 'rank2'], false],
            '#fb00ff', // 変動A’(注意変動)
            ['boolean', ['feature-state', 'rank3'], false],
            '#fa0800', // 変動A(確定変動)
            ['boolean', ['feature-state', 'rank4'], false],
            '#fdfd12', // 変動B(準確定変動)
            ['boolean', ['feature-state', 'rank5'], false],
            '#0affff', // 変動C(潜在変動)以下
            '#fff',
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'sort'], false],
          0.1,
          ['boolean', ['feature-state', 'rank1'], false],
          0.4, // 変動A’’(警戒変動)
          ['boolean', ['feature-state', 'rank2'], false],
          0.4, // 変動A’(注意変動)
          ['boolean', ['feature-state', 'rank3'], false],
          0.4, // 変動A(確定変動)
          ['boolean', ['feature-state', 'rank4'], false],
          0.4, // 変動B(準確定変動)
          ['boolean', ['feature-state', 'rank5'], false],
          0.4, // 変動C(潜在変動)以下
          0.1,
        ],
      },
    });

    // AOIレイヤー(アウトライン)
    if (map.current.getLayer('aoisOutline')) map.current.removeLayer('aoisOutline');
    map.current.addLayer({
      id: 'aoisOutline',
      type: 'line',
      source: 'projectAois',
      paint: {
        'line-opacity':[
          'case',
          ['boolean', ['feature-state', 'sort'], false],
          0.3, // 絞り込み検索時に該当しなかったaoiはアウトラインを少し薄くする
          1,
        ],
        'line-width': [
          'case',
            ['boolean', ['feature-state', 'zoom'], false],
            4, // zoom時にlineを太く
            ['boolean', ['feature-state', 'search'], false],
            2, // 絞り込み検索時に該当したaoiはアウトラインを少し太くする
            1,
        ],
        'line-color': [
          'case',
            ['==', ['feature-state', 'clicked'], true],
            'red', // if clicked true, paint in red
            ['==', ['feature-state', 'zoom'], true],
            '#8CFF00', // if zoom true, paint in #8CFF00
            ['==', ['feature-state', 'alert'], true],
            '#FF9900', // if alert true, paint in #FF9900
            ['boolean', ['feature-state', 'selected'], false],
            '#008CFF', // if selected true, paint in blue
            '#f850ff', // else drow gray
        ],
      },
    });
  }

  // 画面描画時に各AOIの内側領域に色を付ける処理
  const changeAoiInsideColor = (aoiData, mapData, rank) => {

    // 二回目のAOIの内側領域の色の変更の際は前回の変更を一旦もとに戻す
    if (!isEmpty(prevInsideColorId)){
      prevInsideColorId.rankLevel1.forEach(value => {
        mapData.setFeatureState(
          { source: 'projectAois', id: value },
          { rank1: false }
        );
      })
      prevInsideColorId.rankLevel2.forEach(value => {
        mapData.setFeatureState(
          { source: 'projectAois', id: value },
          { rank2: false }
        );
      })
      prevInsideColorId.rankLevel3.forEach(value => {
        mapData.setFeatureState(
          { source: 'projectAois', id: value },
          { rank3: false }
        );
      })
      prevInsideColorId.rankLevel4.forEach(value => {
        mapData.setFeatureState(
          { source: 'projectAois', id: value },
          { rank4: false }
        );
      })
      prevInsideColorId.rankLevel5.forEach(value => {
        mapData.setFeatureState(
          { source: 'projectAois', id: value },
          { rank5: false }
        );
      })
    }
  
    // AOIの内側領域の色を変更する
    let rankByAoiIds = {};
    let noDataAoiList = [];
    rankByAoiIds['rankLevel1'] = [];
    rankByAoiIds['rankLevel2'] = [];
    rankByAoiIds['rankLevel3'] = [];
    rankByAoiIds['rankLevel4'] = [];
    rankByAoiIds['rankLevel5'] = [];
    aoiData.features.forEach(value => {
      switch (value.properties[rank]){
        case 1:
          rankByAoiIds.rankLevel1.push(value.id);
          break;
        case 2:
          rankByAoiIds.rankLevel2.push(value.id);
          break
        case 3:
          rankByAoiIds.rankLevel3.push(value.id);
          break
        case 4:
          rankByAoiIds.rankLevel4.push(value.id);
          break
        case 5:
          rankByAoiIds.rankLevel5.push(value.id);
          break
      }
    })
  
    rankByAoiIds.rankLevel1.forEach(value => {
      mapData.setFeatureState(
        { source: 'projectAois', id: value },
        { rank1: true }
      );
    })
    rankByAoiIds.rankLevel2.forEach(value => {
      mapData.setFeatureState(
        { source: 'projectAois', id: value },
        { rank2: true }
      );
    })
    rankByAoiIds.rankLevel3.forEach(value => {
      mapData.setFeatureState(
        { source: 'projectAois', id: value },
        { rank3: true }
      );
    })
    rankByAoiIds.rankLevel4.forEach(value => {
      mapData.setFeatureState(
        { source: 'projectAois', id: value },
        { rank4: true }
      );
    })
    rankByAoiIds.rankLevel5.forEach(value => {
      mapData.setFeatureState(
        { source: 'projectAois', id: value },
        { rank5: true }
      );
    })

    // 解析データが無いAOIの内側描画色を変更する
    aoiData.features.forEach((value) => {
      if(value.properties.no_data){
        noDataAoiList.push(value.id);
      }
    });
    noDataAoiList.forEach(value => {
      mapData.setFeatureState(
        { source: 'projectAois', id: value },
        { nodata: true }
      );
    })
    setNoDataAoiIds(noDataAoiList);

    // NOTE 二回目の内側領域の色の変更時は、前回の絞り込みを行ったaoiを再度リセットする必要があるため保存しておく
    setprevInsideColorId(rankByAoiIds); // 内側領域の色を変更したAOIのidを一旦格納
  }

  /** 指定されたidのAOIレイヤーを削除 */
  const removeAnalysisPointsLayer = (aoiId) => {

    // メモリ未開放問題に対応(稀にメモリ開放が行われる場合がある)
    // https://github.com/mapbox/mapbox-gl-js/issues/9126
    map.current.off('click', `analysisPoints_${aoiId}`, handleClickPoint);
    if (map.current.getSource(`analysisPoints_${aoiId}`)) {
      map.current.getSource(`analysisPoints_${aoiId}`).setData(emptyGeojson);
    }

    // 解析点を非表示
    if (map.current.getLayer(`analysisPoints_${aoiId}`)) map.current.removeLayer(`analysisPoints_${aoiId}`);
    if (map.current.getSource(`analysisPoints_${aoiId}`)) map.current.removeSource(`analysisPoints_${aoiId}`);
  }

  // 日付をYYMMDDで出力する
  const getDateYMD = (date) => {

    // analysis.geojsonのバージョンが2の場合は日付データを変換する
    if(analysisVerRef.current >= 2.0){
      const parts = date.split('/'); // 日付を '/' で分割
      const year = parts[0].substring(2); // 年の下2桁を取得
      const month = parts[1]; // 月を取得
      const day = parts[2]; // 日を取得
      return year + month + day; // YYMMDDの形で返す
    }

    // analysis.geojsonのバージョンが1の場合はYYYY/MM/DDの形に変換する
    var dt = new Date(date);
    var y = dt.getFullYear();
    var m = ("00" + (dt.getMonth()+1)).slice(-2);
    var d = ("00" + dt.getDate()).slice(-2);
    var result = y + "/" + m + "/" + d;
    return result;
  }

  /** 解析ポイントレイヤーを追加 */
  const addAnalysisPointsLayer = (aoiId, analysisPointsUrl) => {
    if (!map.current) return;
    // すでに同じ名前のレイヤーがあったら削除
    if (map.current.getLayer(`analysisPoints_${aoiId}`)) map.current.removeLayer(`analysisPoints_${aoiId}`);
    if (map.current.getSource(`analysisPoints_${aoiId}`)) map.current.removeSource(`analysisPoints_${aoiId}`);

    let velocity = '';
    // バージョン毎に読み込むanalysis.geojsonのkeyを変更する処理
    if(analysisVerRef.current >= 2.0){
      velocity = 'v';
    }else if(analysisVerRef.current >= 1.0){
      velocity = 'velocity';
    }

    map.current.addSource(`analysisPoints_${aoiId}`, {
      type: "geojson",
      data: analysisPointsUrl,
      generateId: true,
    })
    let paintOption;
    let paintOption2;
    // 表示モードがランクの時は値を絶対値にする
    if(AnalyticeModeTypeRef.current === 'slope'){
      /** 解析モードがランクの場合 */
      if (outputTypeRef.current === 'velocity') { //変動速度の場合
        paintOption = ['abs', ['get', velocity]];
      } else { // 期間変位の場合
        paintOption = ['-', ['to-number', ['get', getDateYMD(quantitySearchDateRef.current.end)], -1], ['to-number', ['get', getDateYMD(quantitySearchDateRef.current.start)], -1]];
      }
    }else if(AnalyticeModeTypeRef.current === 'ground'){
      /** 解析モードが計測値の場合 */
      if (outputTypeRef.current === 'velocity') { //変動速度の場合
        paintOption = ['get', velocity];
      } else { // 期間変位の場合
        paintOption = ['-', ['to-number', ['get', getDateYMD(quantitySearchDateRef.current.end)], -1], ['to-number', ['get', getDateYMD(quantitySearchDateRef.current.start)], -1]];
      }
    }else if(AnalyticeModeTypeRef.current === 'gDirection'){
      /** 解析モードが勾配の向きの場合 */
      paintOption = ['get', 'd'];
      paintOption2 = ['get', 'g'];
    }else if(AnalyticeModeTypeRef.current === 'gAngle'){
      paintOption = ['get', 'g'];
    }else if(AnalyticeModeTypeRef.current === 'theta'){
      paintOption = ['get', 's'];
    }

    // 閲覧が許可されたAOIかどうか判定する
    const isAllowAoi = aoiPurchaseDataRef.current.includes(aoiId);

    //凡例のmin値と0の中間の値を取得
    let selectedLegendMinharf = selectedLegendMinRef.current / 2;
    //凡例のmax値と0の中間の値を取得
    let selectedLegendMaxharf = selectedLegendMaxRef.current / 2;
    // 解析ポイントの色の定義
    let pointColorA2d = '#7e017c';
    let pointColorA1d = '#fb00ff';
    let pointColorA = '#fa0800';
    let pointColorB = '#fdfd12';
    let pointColorC = '#0affff';
    //　解析ポイントの色分けの値の定義
    let pointDataA2d = 300 * 12;
    let pointDataA1d = 30 * 12;
    let pointDataA = 10 * 12;
    let pointDataB = 2 * 12;

    let circleColorType = []; // 解析点の色
    let circleEdgeColor = []; // 解析点の縁の色
    let denyColor = '#888'; // 閲覧が許可されていない解析点の色
    let circleOpacity = 1; // デフォルトの解析点の透明度
    let circleStrokeOpacity = 1; // デフォルトの解析点の縁の透明

    // 解析モードと閲覧許可状況によって、解析ポイントの色を変更する
    if (AnalyticeModeTypeRef.current === 'slope'){
      /** 解析モードが斜面の場合 */
      if(!isAllowAoi && projectAoiTypeRef.current === 'Grid'){
        //閲覧が許可されていないかつ、AOIタイプがGridの場合
        circleColorType = denyColor;
        circleEdgeColor = denyColor;
      }else{
        circleColorType = [
          "step",
          [...paintOption],
          pointColorC, pointDataB,
          pointColorB, pointDataA,
          pointColorA, pointDataA1d,
          pointColorA1d, pointDataA2d,
          pointColorA2d
        ];
  
        circleEdgeColor = [
          "step",
          [...paintOption],
          getDarkColor(pointColorC, 0.3), pointDataB,
          getDarkColor(pointColorB, 0.3), pointDataA,
          getDarkColor(pointColorA, 0.3), pointDataA1d,
          getDarkColor(pointColorA1d, 0.3), pointDataA2d,
          getDarkColor(pointColorA2d, 0.3)
        ];
      }

    }else if(AnalyticeModeTypeRef.current === 'ground'){
      /** 解析モードが都市の場合 */
      if(!isAllowAoi && projectAoiTypeRef.current === 'Grid'){
        //閲覧が許可されていないかつ、AOIタイプがGridの場合
        circleColorType = denyColor;
        circleEdgeColor = denyColor;
      }else{
        circleColorType = [
          "interpolate",
          ['linear'],
          [...paintOption],
          //未定義の場合は黒になる
          selectedLegendMinRef.current,'#0029ff',
          selectedLegendMinharf,'#00f1ff',
          0,'#03ff00',
          selectedLegendMaxharf,'#ffed00',
          selectedLegendMaxRef.current,'#ff0000'
        ];

        // 解析点の縁の色
        circleEdgeColor = [
          "interpolate",
          ['linear'],
          [...paintOption],
          selectedLegendMinRef.current,getDarkColor('#0029ff', 0.3),
          selectedLegendMinharf,getDarkColor('#00f1ff', 0.3),
          0,getDarkColor('#03ff00', 0.3),
          selectedLegendMaxharf,getDarkColor('#ffed00', 0.3),
          selectedLegendMaxRef.current,getDarkColor('#ff0000', 0.3),
        ];
      }
    }else if(AnalyticeModeTypeRef.current === 'gDirection'){
      if(!isAllowAoi && projectAoiTypeRef.current === 'Grid'){
        //閲覧が許可されていないかつ、AOIタイプがGridの場合
        circleColorType = denyColor;
        circleEdgeColor = denyColor;
      }else{
        circleColorType = [
          "interpolate",
          ['linear'],
          [...paintOption],
          //未定義の場合は黒になる
          0,'#ff0000', // 北(赤)
          90,'#0000ff', // 東(青)
          180,'#03ff00',// 南(緑)
          270,'#ffff00',// 西(黄)
          360,'#ff0000' // 北(赤)
        ];

        // 解析点の縁の色
        circleEdgeColor = [
          "interpolate",
          ['linear'],
          [...paintOption],
          0,getDarkColor('#ff0000', 0.3),
          90,getDarkColor('#0000ff', 0.3),
          180,getDarkColor('#03ff00', 0.3),
          270,getDarkColor('#ffff00', 0.3),
          360,getDarkColor('#ff0000', 0.3),
        ];

        // 解析点の透明度
        circleOpacity = [
          "interpolate",
          ["exponential", 1.04],
          [...paintOption2],
          0,0,
          60,1,
        ];

        // 解析点の縁の透明度
        circleStrokeOpacity = [
          "interpolate",
          ["exponential", 1.04],
          [...paintOption2],
          0,0,
          60,1,
        ];

      }
    }else if(AnalyticeModeTypeRef.current === 'gAngle'){
      if(!isAllowAoi && projectAoiTypeRef.current === 'Grid'){
        //閲覧が許可されていないかつ、AOIタイプがGridの場合
        circleColorType = denyColor;
        circleEdgeColor = denyColor;
      }else{
        circleColorType = [
          "interpolate",
          ['linear'],
          [...paintOption],
          //未定義の場合は黒になる
          0,'#03ff00', // 北
          60,'#ff0000', // 東
        ];

        // 解析点の縁の色
        circleEdgeColor = [
          "interpolate",
          ['linear'],
          [...paintOption],
          0,getDarkColor('#03ff00', 0.3),
          60,getDarkColor('#ff0000', 0.3),
        ];
      }
    }else if(AnalyticeModeTypeRef.current === 'theta'){
      if(!isAllowAoi && projectAoiTypeRef.current === 'Grid'){
        //閲覧が許可されていないかつ、AOIタイプがGridの場合
        circleColorType = denyColor;
        circleEdgeColor = denyColor;
      }else{
        circleColorType = [
          "interpolate",
          ['linear'],
          [...paintOption],
          //未定義の場合は黒になる
          0,'#03ff00', // 緑
          90,'#ff0000', // 赤
          180,'#0000ff', // 青
        ];

        // 解析点の縁の色
        circleEdgeColor = [
          "interpolate",
          ['linear'],
          [...paintOption],
          0,getDarkColor('#03ff00', 0.3),
          90,getDarkColor('#ff0000', 0.3),
          180,getDarkColor('#0000ff', 0.3),
        ];
      }
    }

    map.current.addLayer({
      id: `analysisPoints_${aoiId}`,
      type: 'circle',
      source: `analysisPoints_${aoiId}`,
      paint: {
        'circle-color': circleColorType,
        'circle-opacity': circleOpacity,
        'circle-stroke-opacity' : circleStrokeOpacity,
        'circle-stroke-width':[
          'case',
            ['boolean', ['feature-state', 'clicked'], false],
            3,
            0.5,
        ],
        'circle-stroke-color':[
          'case',
            ['boolean', ['feature-state', 'clicked'], false],
            '#000',
            circleEdgeColor,
        ],
        'circle-radius': {
          'base': analysisPointSizeRef.current,
          'stops': [
            [0, 0],
            [20, 20]
          ]
        },
      }
    })
    //クリックしたAOIのIDを配列に格納する
    setAnalyticeIsShowAoi((oldValue => [...oldValue, aoiId]));
  }

  /** AOIがクリックされたとき */
  const handleClickAoi = (e) => {
    if (!e || e.features.length < 1) return;
    if (projectAoiTypeRef.current === null) return; // 非購入AOI情報取得前にAOIをクリックした場合は以下の処理を行わない
    if(prevAoiDeleteIdListRef.current.includes(e.features[0].id)) return; // 絞り込み検索で削除した要素がクリックされた場合は処理を行わない

    const nowClickedAoiId = e.features[0].id; // クリックされたAOIのID
    setClickAoiId(e.features[0].id) // クリックされたAOIのID
    const clickedAoiName = getAoiNameFromAoiId(nowClickedAoiId, aoisDataRef.current); // クリックしたAOIの名前を取得
    const drawerTogglecheckFlag = aoisStateRef.current[clickedAoiName]; // クリックしたAOIのドロワーメニュートグルスイッチの状態を格納
    if (clickedAoiIdRef.current === clickAoiId && drawerTogglecheckFlag === true) return; // 前回クリックしたAOIとクリックしたAOIが同じ場合かつ、クリックしたAOIのドロワーメニュートグルスイッチがON状態の場合

    setAdminCommentAoiId(nowClickedAoiId)//管理者コメント用のhooksにAoiのIDを渡す
    setAoiClickFlag(true) // 管理者コメント機能をアクティブにする
      
    //ログインアカウントが管理者アカウントの場合は管理者コメント入力欄にコメントを挿入する
    if(document.getElementById('adminCommentInput')){
      let adminCommentInput = document.getElementById('adminCommentInput');
      let clickAoiAdminComment = {};
      clickAoiAdminComment['AdminComment'] = 'null';

      // 管理者コメントが1つも登録されていない場合
      if(!adminCommentRef.current.length){

        adminCommentInput.value = '';

      // 管理者コメントが1つ以上登録されている場合
      }else{

        //クリックしたAOIのidとfetchした管理者コメントデータのidが一致した場合の処理
        adminCommentRef.current.forEach(value => {
          if(value.id === nowClickedAoiId) clickAoiAdminComment['AdminComment'] = value.AdminComment;
        })

        //管理者コメントをテキストボックスに反映する
        if(clickAoiAdminComment.AdminComment === 'null' || clickAoiAdminComment.AdminComment === ''){
          adminCommentInput.value = '';
        }else if(!Object.keys(clickAoiAdminComment).length){
          adminCommentInput.value = '';
        }
        else{
          adminCommentInput.value = clickAoiAdminComment.AdminComment;
        }
      }
    }

    //ログインアカウントが管理者アカウント以外の場合はSummaryエリアに管理者コメントを表示する
    if(document.getElementById('adminCommentResult')){
      let clickAoiAdminCommentResult = {};
      clickAoiAdminCommentResult['AdminComment'] = 'null';

      // 管理者コメントが1つも登録されていない場合
      if(!adminCommentRef.current.length){ 
        setSummaryTextData(t("map.adminNoComments"));
      // 管理者コメントが1つ以上登録されている場合
      }else{

        //クリックしたAOIのidとfetchした管理者コメントデータのidが一致した場合の処理
        adminCommentRef.current.forEach(value => {
          if(value.id === nowClickedAoiId) clickAoiAdminCommentResult['AdminComment'] = value.AdminComment;
        })
        
        //管理者コメントをSummaryエリアに表示する
        if(clickAoiAdminCommentResult.AdminComment === 'null' || clickAoiAdminCommentResult.AdminComment === ''){
          setSummaryTextData(t("map.adminNoComments"));
        }else if(!Object.keys(clickAoiAdminCommentResult).length){
          setSummaryTextData(t("map.adminNoComments"));
        }else{
          setSummaryTextData(clickAoiAdminCommentResult.AdminComment);
        }
      }
    }

    if (clickedAoiIdRef.current) {
      // 前回クリックしたAOIのクリック状態をfalseに
      map.current.setFeatureState(
        { source: 'projectAois', id: clickedAoiIdRef.current },
        { clicked: false }
      );
    }
    // クリックしたAOIのクリック状態をtrueに
    map.current.setFeatureState(
      { source: 'projectAois', id: nowClickedAoiId },
      { clicked: true }
    );

    setClickedAoiId(nowClickedAoiId); // クリックされたAOIのIDを更新
    calculationAnalysisData(nowClickedAoiId); // 選択中のAOI解析データの計算処理

    const previousSelectedIds = Object.keys(aoisStateRef.current)
      .filter(aoiName => aoisStateRef.current[aoiName]) // 選択中のAOIの名前
      .map(aoiName => getAoiIdFromAoiName(aoiName, aoisDataRef.current)); // 選択中のAOIのID

    if (previousSelectedIds.indexOf(nowClickedAoiId) === -1) { // クリックしたAOIのIDが選択中のIDにない場合
      map.current.setFeatureState(
        { source: 'projectAois', id: nowClickedAoiId },
        { selected: true }
      );
      //解析データが無いAOIの場合はAOIに解析ポイントを追加しない
      if(!noDataAoiIdsRef.current.includes(nowClickedAoiId)){

        const getAnalysisPointsGeojson = async () => {
          try {
            const pointsGeojson = await fetch(`${analysisPointsUrlPrefix}/${projectId}/analysis/analysis_${nowClickedAoiId}.geojson`)
                .then(response => response.json());
            addAnalysisPointsLayer(nowClickedAoiId, pointsGeojson); // 無印geojsonを読み込む
          } catch (error) {
            // 無印geojsonが存在しない場合は連番geojsonをwhile文で回わす
            let n = 1;
            let isGeojson = true;
            while (isGeojson) {
              try {
                // 分割geojsonの場合は末尾に番号を付与する
                const nowClickedNumberAoiId = `${nowClickedAoiId}_${n}`

                // 分割geojsonを番号順に取得
                const pointsGeojson = await fetch(`${analysisPointsUrlPrefix}/${projectId}/analysis/analysis_${nowClickedAoiId}_${n}.geojson`)
                    .then(response => response.json());
                addAnalysisPointsLayer(nowClickedNumberAoiId, pointsGeojson); // 無印geojsonを読み込む
                n++;
              } catch (error) {
                // これ以上分割されたgeojsonは存在しないと判断し、処理を停止
                isGeojson = false;
              }
            }
          }
        };
        getAnalysisPointsGeojson();
      }
      const aoiName = getAoiNameFromAoiId(nowClickedAoiId, aoisDataRef.current);
      if (!aoiName) return;
      handleChangeAoisState(aoiName, true);
    }
  }

  /** 3D描画するためのレイヤーを追加 */
  const add3DLayer = () => {
    if (!map.current.getSource('mapbox-dem')) {
      map.current.addSource('mapbox-dem', { // Mapbox3Dソース
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 22,
      });
      // add the DEM source as a terrain layer with exaggerated height
      map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 0 }); //デフォルト2d
      map.current['dragRotate'].disable();//デフォルト右クリック無効
    }
  }

  /** ラスターを追加 */
  const addMapLayer = (mapName, mapUrl) => {
    if (!map.current.getSource(`${mapName}_source`)) {
      map.current.addSource(`${mapName}_source`, { // AOIソース
        type: 'raster',
        tiles: [
          mapUrl,
        ],
        tileSize: 256,
      });
    }

    if (map.current.getLayer(mapName)) map.current.removeLayer(mapName);
    map.current.addLayer({
      id: mapName,
      type: 'raster',
      source: `${mapName}_source`,
      minzoom: 1,
      maxzoom: 18,
      layout: {
        visibility: 'none' // Map画面表示時は非表示
      },
    })
  }

  /** 地質図ラスターを追加 */
  // https://gbank.gsj.jp/seamless/v2/api/1.3/#map
  const addGeologyLayer = () => {
    if (!map.current.getSource('Geology_source')) {
      map.current.addSource('Geology_source', { // AOIソース
        type: 'raster',
        tiles: [
          'https://gbank.gsj.jp/seamless/v2/api/1.3/tiles/{z}/{y}/{x}.png',
        ],
        tileSize: 256,
      });
    }

    if (map.current.getLayer('Geology')) map.current.removeLayer('Geology');
    map.current.addLayer({
      id: 'Geology',
      type: 'raster',
      source: 'Geology_source',
      minzoom: 0,
      maxzoom: 22,
      layout: {
        visibility: 'none' // Map画面表示時は非表示
      },
    })
    //ラスター画像を少し透過させる
    map.current.setPaintProperty('Geology', 'raster-opacity', 0.6);
  }

  /**
   * 表示中のプロジェクトの ラスターを追加
   * @param {Object} mapLayers {'マップレイヤーの名前': 'マップレイヤーのURL', ...}
   */
  const addMapLayers = (mapLayers) => {
    Object.keys(mapLayers).forEach(mapLayerName => {
      addMapLayer(mapLayerName, mapLayers[mapLayerName]);
    });
  }

  /** 独自ラスターの表示・非表示をセット */
  const showMapLayers = () => {
    // レイヤー名取得
    let mapLayerNames = Object.keys(mapLayerState);
    mapLayerNames.forEach(mapLayerName => {
      if (mapLayerState[mapLayerName] === true) {
        map.current.setLayoutProperty(mapLayerName, 'visibility', 'visible');
        loadingEvent(); // ローディング処理
      } else {
        map.current.setLayoutProperty(mapLayerName, 'visibility', 'none');
      }
    })
  }

  /** 汎用ラスター(地質図)の表示・非表示をセット */
  const showGeologyLayers = () =>{
    if (geologyLayerState === true) {
      map.current.setLayoutProperty('Geology', 'visibility', 'visible');
      loadingEvent(); // ローディング処理
    } else {
      map.current.setLayoutProperty('Geology', 'visibility', 'none');

      //一旦すべての地質図マーカーを削除
      const geologyMarkers = document.getElementsByClassName('geology-marker');
      if (geologyMarkers.length > 0) geologyMarkers[0].remove('geology-marker');
      
      // 地質図凡例取得ボタン：オフ
      setgeologyDataGetMode(false);
    }
  }

  /** AOIに持たせているFeatureState（clicked, selected）を設定 */
  const setAoisFeatureState = () => {
    // 最後にクリックしたAOIのclicked状態をtrueに → AOIの外枠が赤色に
    map.current.setFeatureState(
      { source: 'projectAois', id: clickedAoiIdRef.current },
      { clicked: true }
    );
    // 現在選択中のAOIのIDを取得
    const selectedAoisIds = getSelectedAoiIds(aoisStateRef.current, aoisDataRef.current);
    // 選択されているAOIのselected状態をtrueに → AOIの外枠が青色に
    selectedAoisIds.forEach(id => {
      map.current.setFeatureState(
        { source: 'projectAois', id: id },
        { selected: true }
      );
    });
  }

  /** 選択中のAOIの解析データを表示する */
  const addSelectedAoiAnalysisLayer = async(aoisSelectedState, aoisData) => {
    // 現在選択中のAOIのIDを取得
    const selectedAoisIds = getSelectedAoiIds(aoisSelectedState, aoisData);
    // 選択されているAOIの解析データレイヤーを追加
    for (const id of selectedAoisIds) {
      if (!noDataAoiIdsRef.current.includes(id)) { // 解析データが無いAOIの場合はAOIに解析ポイントを追加しない
          try {
            // 無印geojsonを読み込む
            const response = await fetch(`${analysisPointsUrlPrefix}/${projectId}/analysis/analysis_${id}.geojson`);
            const pointsGeojson = await response.json();
            addAnalysisPointsLayer(id, pointsGeojson);
          } catch (e) {
            // 分割geojsonの読み込み
            let n = 1;
            let isGeojson = true;
            while (isGeojson) {
              try {
                // 分割geojsonの場合は末尾に番号を付与する
                const splitGeojsonId = `${id}_${n}`;

                // 分割geojsonを番号順に取得
                const pointsGeojson = await fetch(`${analysisPointsUrlPrefix}/${projectId}/analysis/analysis_${id}_${n}.geojson`)
                    .then(response => response.json());
                addAnalysisPointsLayer(splitGeojsonId, pointsGeojson); // 無印geojsonを読み込む
                n++;
              } catch (error) {
                // これ以上分割されたgeojsonは存在しないと判断し、処理を停止
                isGeojson = false;
              }
            }
          }
      }
    }
  }

  /** 距離測定・面積測定ボタンがクリックされたときの処理 */
  const handleClickControl = () => {
    // GoogleStreetViewボタン：オフ
    setGsvButtonStatus(false);
    map.current.off('click', openGoogleStreetViewConfirmDialog);
    // メモモード：オフ
    setMemoButtonStatus(false);
    map.current.off('click', openMemoFormDialog);
  }

  /** 距離測定・面積測定 */
  const showDrawingPolygonArea = () => {
    map.current.addControl(draw);

    // 距離測定ボタンにクリックイベント追加
    const draw_line = document.getElementsByClassName('mapbox-gl-draw_line');
    draw_line[0] && draw_line[0].addEventListener('click', handleClickControl, true);
    // 面積測定ボタンにクリックイベント追加
    const draw_polygon = document.getElementsByClassName('mapbox-gl-draw_polygon');
    draw_polygon[0] && draw_polygon[0].addEventListener('click', handleClickControl, true);

    map.current.on('draw.create', updateArea);
    map.current.on('draw.delete', updateArea);
    map.current.on('draw.update', updateArea);

    /** 結果表示 */
    const addAnswerAreaInnerHtml = (answer, answerArea, unit) => {
      answerArea.innerHTML =
      `<div class="${classes.calculationBox}">` +
      `<p class="${classes.calculationText}"><strong>` +
      answer +
      `</strong></p><p class="${classes.calculationText}">${unit}</p></div>`;
    }

    /** 測定 */
    function updateArea(e) {
      const data = draw.getAll(),
            answerArea = document.getElementById('calculated-area');
      if (data.features.length > 0) {
        if (data.features[data.features.length - 1].geometry.type === 'Polygon') {
          // 面積を小数点以下2桁に制限する
          const area = turf.area(data),
                roundedArea = Math.round(area * 100) / 100;
          addAnswerAreaInnerHtml(roundedArea, answerArea, 'square meters');
        } else if (data.features[data.features.length - 1].geometry.type === 'LineString') {
          // 距離を小数点以下2桁に制限する
          const distance = turf.lineDistance(data.features[data.features.length - 1]),
                roundedDistance = Math.round(distance * 100) / 100;
          addAnswerAreaInnerHtml(roundedDistance, answerArea, 'km');
        }
      } else {
        answerArea.innerHTML = '';
        if (e.type !== 'draw.delete') {
          alert('Use the draw tools to draw a polygon!');
        }
      }
    }
  }

  const [threeDeeMode, setThreeDeeMode] = useState(false);
  /** 2D⇔3D切替 */
  const switchBetween2dAnd3d = (e) => {
    const handler = e.target.id,
          button = document.getElementById(handler).parentNode.parentNode;
    if (e.target.checked) {
      map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 }); //3d
      map.current[handler].enable();
      button.classList.add(classes.active);
      setThreeDeeMode(true);
    } else {
      map.current.setPitch(0); // ピッチを初期値に戻す
      map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 0 }); //2d
      map.current[handler].disable();
      button.classList.remove(classes.active);
      setThreeDeeMode(false);
    }
  }

  /** AOIのポイントがクリックされたとき */
  const [prevAnalysisClickData, setPrevAnalysisClickData] = useState({}); // クリックした解析ポイントのID情報を格納する
  const prevAnalysisClickDataRef = useRef();
  prevAnalysisClickDataRef.current = prevAnalysisClickData; // 最新のクリックした解析ポイントのID情報を参照する
  const handleClickPoint = (e) => {

    // AOItypeがGridでかつ、閲覧が禁止されてるAOI内の解析点の場合は以降の処理を行わない
    if(projectAoiType === 'Grid'){
      const layerName = e.features[0].layer.id;
      const layerId = Number(layerName.split("_")[1]);
      const isAllowAoi = aoiPurchaseDataRef.current.includes(layerId);
      if(!isAllowAoi) {
        isShowSnakbar('閲覧が許可されていません。', 'warning')
        return;
      }
    }

    // 前回クリックした解析ポイントのスタイルをもとに戻す
    if(Object.keys(prevAnalysisClickDataRef.current).length){
      map.current.setFeatureState(
        { source: prevAnalysisClickDataRef.current.clickAnalysisId, id: prevAnalysisClickDataRef.current.analysisPointId },
        { clicked: false }
      );
    }

    //クリックした解析ポイントのスタイルを変化させる
    let clickPointIdDatas = {};
    clickPointIdDatas['analysisPointId'] = e.features[0].id
    clickPointIdDatas['clickAnalysisId'] = e.features[0].layer.id;
    map.current.setFeatureState(
      { source: clickPointIdDatas['clickAnalysisId'], id: clickPointIdDatas['analysisPointId'] },
      { clicked: true }
    );
    setPrevAnalysisClickData(clickPointIdDatas)// クリックした解析ポイントのデータは一旦格納

    /** グラフ情報作成 */
    let pointGraphDatas = [];
    let pointProperties = {};
    //プロパティ内の日付の先頭の0を除外する
    Object.keys(e.features[0].properties).map(key =>
      pointProperties[key.replace(/\/0/, '/').replace(/\/0/, '/')] = e.features[0].properties[key]
    );

    // analysis.geojsonがverの場合は日付データをフォーマットする
    if (analysisVerRef.current >= 2.0){

      // 与えられた日付データを変換する処理
      const convertKeyToDate = (key) => {
        const year = '20' + key.substring(0, 2);
        const month = parseInt(key.substring(2, 4), 10); // 先頭の0を取り除くためにparseIntを使用
        const day = parseInt(key.substring(4, 6), 10); // 同様に、先頭の0を取り除く
        return `${year}/${month}/${day}`;
      }

      /**
       * 省略形の日付データ(yymmdd)をフォーマットする処理
       * 例) 150625→2015/06/25
       * 正規表現で日付データ(省略形)かどうか判断する。
       * (?:[0-9]{2}) : 最初の2桁は年として任意の数字。
       * (?:0[1-9]|1[0-2]) : 次の2桁は月として01から12の範囲。
       * (?:0[1-9]|[12][0-9]|3[01]) : 最後の2桁は日として01から31の範囲。
       */
      Object.keys(pointProperties).forEach(key => {
        if (key.match(/^(?:[0-9]{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01])$/)) {
          const newKey = convertKeyToDate(key);
          pointProperties[newKey] = pointProperties[key];
          delete pointProperties[key]; // フォーマット前の日付データを削除
        }
      });
    }

    quantityDates.forEach((date) => {
      const quantityData = pointProperties[date];
      if (quantityData !== undefined) {
        // const replaceDate = date.replace( '/', '-'); // 不要の可能性あり。replaceしなくてもOKだった。
        pointGraphDatas.push({ ...pointDatasState, 'date': new Date(date).getTime(), 'quantity': Number(quantityData) });
      }
    })

    setPointDatasState(pointGraphDatas);

    /** ポイント情報作成 */
    let pid = '';
    let velocity = '';
    // バージョン毎に読み込むanalysis.geojsonのkeyを変更する処理

    if(analysisVerRef.current >= 2.0){ // バージョン1
      pid =  e.features[0].properties.p;
      velocity =  Number(Math.floor(e.features[0].properties.v * Math.pow(10, 3)) / Math.pow(10, 3)).toFixed(1);
    }else if(analysisVerRef.current >= 1.0){ // バージョン2
      pid =  e.features[0].properties.pid;
      velocity =  Number(Math.floor(e.features[0].properties.velocity * Math.pow(10, 3)) / Math.pow(10, 3)).toFixed(1);
    }

    const propertiesInfo = [
      getAoiNameFromAoiId(clickedAoiIdRef.current, aoisDataRef.current), // AOI名
      pid, // PID
      Number(Math.floor(e.features[0].geometry.coordinates[0] * Math.pow(10, 3)) / Math.pow(10, 3)),// 経度
      Number(Math.floor(e.features[0].geometry.coordinates[1] * Math.pow(10, 3)) / Math.pow(10, 3)),// 緯度
      velocity // 変動速度
    ]

    //バージョン2のanalysis.geojsonの場合は、標高、勾配の向き、勾配の角度を追加する
    if(analysisVerRef.current >= 2.0){
      propertiesInfo.push(e.features[0].properties.h, e.features[0].properties.d, e.features[0].properties.g, e.features[0].properties.s);
    }

    handleDrawerOpen();
    setPointRows(propertiesInfo);
  }

  /** ウィンドウ用 選択中のAOIの情報を取得 */
  const calculationAnalysisData = async (aoiId) => {
    if (!aoiId) return;
    const clickAoiTableData = aoisTableDataRef.current.filter(object => object.aoiId === aoiId)[0]; // aoiIdでクリックしたAOI情報を取得
    setAoiTableData({ ...clickAoiTableData });
    setAoiTableDataGeneratFlag(true);
  }

  const [gsvButtonStatus, setGsvButtonStatus] = useState(false);
  const [gsvPopupStatus, setGsvPopupStatus] = useState(false);
  const [lngLatData, setLngLatData] = useState(false);

  /** GoogleStreetViewボタンのクリックイベント */
  const handleClickGoogleStreetViewButton = () => {
    // メモモード：オフ
    setMemoButtonStatus(false);
    // 地質図凡例取得ボタン：オフ
    setgeologyDataGetMode(false);
    map.current.off('click', openMemoFormDialog);
    // 距離測定・面積測定モードをリセット
    draw.changeMode('simple_select');

    if (gsvButtonStatus) {
      map.current.off('click', openGoogleStreetViewConfirmDialog);
      map.current.getCanvas().style.cursor = '';
      setGsvButtonStatus(false);
    } else {
      map.current.on('click', openGoogleStreetViewConfirmDialog);
      map.current.getCanvas().style.cursor = 'crosshair'; // 十字カーソル表示
      setGsvButtonStatus(true);
    }
  }

  // マップ上でデフォルトの表示位置に移動する処理
  const handleClickDefaultMove = () => {
    // デフォルトの位置へ移動
    map.current.flyTo({
      center: [longitudeCenter, latitudeCenter],
      zoom: 12,
    });
  }

  /**
   * GoogleStreetViewモード時にマップをクリックしたときのイベント
   * map.on、map.offで同じ関数オブジェクトを参照したいため、useCallbackを使用し、第2引数に[]を指定
   */
  const openGoogleStreetViewConfirmDialog = useCallback((e) => {
    setLngLatData(e.lngLat);
    setGsvPopupStatus(true);
  }, []);

  /** 閉じるをクリックした時の処理 */
  const closeGsvPopup = () => {
    setGsvPopupStatus(false);
  }

  /** GoogleStreetViewへのリンク */
  const gsvLink = () => {
    const gsvLink = document.createElement('a');
    gsvLink.target = "_blank"; // 別窓で開く
    gsvLink.href = `https://www.google.co.jp/maps/?cbll=${lngLatData.lat},${lngLatData.lng}&layer=c`; // NOTE latとlngの順番に注意
    gsvLink.click();
    setGsvPopupStatus(false);
    setGsvButtonStatus(false);
    map.current.off('click', openGoogleStreetViewConfirmDialog);
    map.current.getCanvas().style.cursor = '';
  }

  // サマリのドロワーメニューが表示されているか
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const handleDrawerOpen = () => {
    setIsSummaryOpen(true);
  };
  const handleDrawerClose = () => {
    setIsSummaryOpen(false);
  };

  const [memoFormStatus, setMemoFormStatus] = useState(false);
  const [memoButtonStatus, setMemoButtonStatus] = useState(false);
  const [latestId, setLatestId] = useState(false);
  const latestIdRef = useRef();
  latestIdRef.current = latestId;

  /** メモモードボタン押下時のイベント */
  const handleClickMemoButton = () => {
    // GoogleStreetViewボタン：オフ
    setGsvButtonStatus(false);
    // 地質図凡例取得ボタン：オフ
    setgeologyDataGetMode(false);
    map.current.off('click', openGoogleStreetViewConfirmDialog);
    // 距離測定・面積測定モードをリセット
    draw.changeMode('simple_select');

    if (memoButtonStatus) {
      map.current.getCanvas().style.cursor = '';
      setMemoButtonStatus(false);
      map.current.off('click', openMemoFormDialog);
    } else {
      map.current.getCanvas().style.cursor = 'crosshair'; // 十字カーソル表示
      setMemoButtonStatus(true);
      map.current.on('click', openMemoFormDialog);
      // ドロワーメニューでメモがOFFになっていたらONにしてメモマーカーを表示
      if (!objectLayerState['メモ']) setObjectLayerState({ ...objectLayerState, 'メモ': true });
    }
  }

  /**
   * メモモード時にマップをクリックしたときのイベント
   * map.on、map.offで同じ関数オブジェクトを参照したいため、useCallbackを使用し、第2引数に[]を指定
   */
  const openMemoFormDialog = useCallback((e) => {
    setLngLatData(e.lngLat);
    setMemoFormStatus(true);
  }, []);

  /** キャンセルをクリックした時の処理 */
  const handleClickMemoFormCloseButton = () => {
    setMemoFormStatus(false);
    setIsAlertFormOpen(false);
  }

  /** アラートを閉じたとき */
  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  /** メモ登録処理 */
  const registerMemo = async () => {
    const errorMessage = "メモの登録に失敗しました。";
    setAddLoadingMemo(true);
    const params = {
      userId: user.attributes.email,
      projectId: myProjectId,
      latitude: String(lngLatData.lat), //緯度
      longitude: String(lngLatData.lng), // 経度
      memo: document.getElementById("memoTextarea").value, // メモ内容
      title: document.getElementById("memoTitlearea").value, // メモタイトル内容
    }
    const response = await postRequest('IM_Lambda', '/im-addmemoinfo', params);
    setAddLoadingMemo(false);
    if (response.errorMessage) {
      setIsAlertFormOpen(true);
      setAlertMessageMemoForm(errorMessage);
      return;
    }
    if (!response.statusCode) {
      setIsAlertFormOpen(true);
      setAlertMessageMemoForm(errorMessage);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        setMemoFormStatus(false);
        setMemoButtonStatus(false);
        map.current.off('click', openMemoFormDialog);
        map.current.getCanvas().style.cursor = '';
        setLatestId(response.memoId);
        removeMemoMarkers(); // 一度表示中のメモマーカーを削除
        setMemoGetFirstFlag(false);
        showMemoMarkers(); // 最新のメモ一覧を表示
        break;
      case "E0240": // 対象データが既に登録済みのデータであった
        setIsAlertFormOpen(true);
        setAlertMessageMemoForm('登録済みです[' + response.statusCode + ']');
        break;
      case "E0250": // バリデーションチェックによるエラー
        setIsAlertFormOpen(true);
        setAlertMessageMemoForm(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0260": // コード内で指定されたデータが、タグごと入力JSONにない
        setIsAlertFormOpen(true);
        setAlertMessageMemoForm(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0270": //コード内で指定されたデータについて、値が入力JSONにない
        setIsAlertFormOpen(true);
        setAlertMessageMemoForm(errorMessage + '[' + response.statusCode + ']');
        break;
      default:
        setIsAlertFormOpen(true);
        setAlertMessageMemoForm(errorMessage);
        break;
    }
  }

  /** メモを削除する処理 */
  const deleteMemo = async (e) => {
    const errorMessage = "メモの削除に失敗しました。";
    const memoId = e.target.dataset.id;
    const params = {
      userId: user.attributes.email,
      projectId: myProjectId,
      memoId: memoId,
    }
    if (window.confirm("メモを削除しますか？")) {
      setAddLoadingMemo(true);
      const response = await postRequest('IM_Lambda', '/im-deletememoinfo', params);
      setAddLoadingMemo(false);
      if (response.errorMessage) {
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage);
        return;
      }
      if (!response.statusCode) {
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage);
        return;
      }
      switch (response.statusCode) {
        case "N0000": // 正常終了
          document.getElementById('popArea_' + memoId).parentNode.parentNode.remove();
          setLatestId(false); // 登録IDリセット
          removeMemoMarkers(); // 一度表示中のメモマーカーを削除
          setMemoGetFirstFlag(false);
          showMemoMarkers(); // 最新のメモ一覧を表示
          break;
        case "E0210": // 対象のメモが存在しない
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
          break;
        case "E0240": // 対象データが既に登録済みのデータであった
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
          break;
        case "E0250": // バリデーションチェックによるエラー
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
          break;
        case "E0260": // コード内で指定されたデータが、タグごと入力JSONにない
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
          break;
        case "E0270": //コード内で指定されたデータについて、値が入力JSONにない
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
          break;
        default:
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage);
          break;
      }
    }
  }

  // メモの更新が完了した場合の処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!isUpdateMemoSuccess) return; // 管理者コメント取得時にエラーがある場合に以下の処理を行う

    setMemoButtonStatus(false); // メモモードオフ
    showMemoMarkers(); // 最新のメモの一覧を取得

  },[isUpdateMemoSuccess])

  // 更新したメモを即時更新する処理
  const updateMemoData = (memoList) => {
    memoList.forEach(value => {
      if (value.memoId === memoUpdateIdRef.current){
        //更新したメモデータを取得
        let updateMemoTitle = value.memoTitle;
        let updateMemoText = value.memo;
        // メモのタイトルと本文を更新する
        let updatePopup = document.getElementById('popArea_' + memoUpdateIdRef.current);
        updatePopup.children[1].innerHTML = updateMemoTitle;
        updatePopup.children[2].innerHTML = updateMemoText;
      }
    })
  }

  /** メモマーカーを削除 */
  const removeMemoMarkers = () => {
    const currentMarkers = Array.from(document.getElementsByClassName('mapboxgl-marker'));
    currentMarkers.forEach(marker => marker.remove());
  }

  /** メモマーカーを表示(最新のメモ情報を取得用) */
  const showMemoMarkers = async () => {
    const errorMessage = "メモの取得に失敗しました。";
    setAddLoadingMemo(true);
    const params = {
      userId: user.attributes.email,
      projectId: myProjectId,
    }
    const response = await postRequest('IM_Lambda', '/im-getmemoinfolist', params);
    setAddLoadingMemo(false);
    if (response.errorMessage) {
      setIsAlertOpen(true);
      setAlertMesseageMemo(errorMessage);
      return;
    }
    if (!response.statusCode) {
      setIsAlertOpen(true);
      setAlertMesseageMemo(errorMessage);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        response.memoInfoList.map((memo) => (
          attachMemoMarker(memo.memoId, memo.createUserName, memo.createDateTime, memo.longitude, memo.latitude, memo.memo, memo.memoTitle) // マーカー設置
        ));
        //jsxで描画するためにidを追加
        response.memoInfoList.map((value, index) => {
          value['id'] = index;
        });
        updateMemoData(response.memoInfoList);
        setMemoItems(response.memoInfoList)
        setMemoStatusCode(response.statusCode)
        break;
      case "E0110": // 対象のプロジェクトに対する権限がない
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0180": // 対象のユーザが存在しない
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0210": // 対象のメモが存在しない
        setIsAlertOpen(true);
        setAlertMesseageMemo('メモの登録がありません[' + response.statusCode + ']');
        setAlertSeverity('info');
        setMemoItems([]);
        break;
      case "E0250": // バリデーションチェックによるエラー
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0260": // コード内で指定されたデータが、タグごと入力JSONにない
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0270": //コード内で指定されたデータについて、値が入力JSONにない
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage + '[' + response.statusCode + ']');
        break;
      default:
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage);
        break;
    }
  }

  /** メモマーカー表示非表示を設定 */
  const setMemoMarker = (isShow) => {
    removeMemoMarkers();
    const errorMessage = "メモの取得に失敗しました。";
    //メモの登録、削除を行うまでは下記の処理を実行
    if (isShow && memoGetFirstFlag){
      if (memoErrorMessage) {
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage);
        return;
      }
      if (!memoStatusCode) {
        setIsAlertOpen(true);
        setAlertMesseageMemo(errorMessage);
        return;
      }
      //画面描画時に取得したメモ情報でメモを表示
      switch (memoStatusCode) {
        case "N0000": // 正常終了
        memoItems.map((memo) => (
            attachMemoMarker(memo.memoId, memo.createUserName, memo.createDateTime, memo.longitude, memo.latitude, memo.memo, memo.memoTitle) // マーカー設置
          ));
          break;
        case "E0110": // 対象のプロジェクトに対する権限がない
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + memoStatusCode + ']');
          break;
        case "E0180": // 対象のユーザが存在しない
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + memoStatusCode + ']');
          break;
        case "E0210": // 対象のメモが存在しない
          setIsAlertOpen(true);
          setAlertMesseageMemo('メモの登録がありません[' + memoStatusCode + ']');
          setAlertSeverity('info');
          break;
        case "E0250": // バリデーションチェックによるエラー
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + memoStatusCode + ']');
          break;
        case "E0260": // コード内で指定されたデータが、タグごと入力JSONにない
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + memoStatusCode + ']');
          break;
        case "E0270": //コード内で指定されたデータについて、値が入力JSONにない
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage + '[' + memoStatusCode + ']');
          break;
        default:
          setIsAlertOpen(true);
          setAlertMesseageMemo(errorMessage);
          break;
      }
      return
    }

    //メモの登録、削除を行ったあとは最新のメモ情報を参照する
    if (isShow) showMemoMarkers();
  }

  /** 選択中のメモマーカーの処理 */
  const selectedMarker = (e) => {
    setMemoButtonStatus(false);
    map.current.off('click', openMemoFormDialog);
    map.current.getCanvas().style.cursor = '';
    const redMarkers = document.getElementsByClassName('mapboxgl-red-marker');
    if (redMarkers.length > 0) {
      redMarkers[0].classList.remove('mapboxgl-red-marker');
    }
    const div = document.getElementById(e.target.id);
    div.classList.add('mapboxgl-red-marker');
  }

  /** マップ上にメモマーカーを添付  */
  const attachMemoMarker = (memoId, userName, createDateTime, lng, lat, memoText, memoTitle) => {
    const monument = [lng, lat];
    const maxLength = 7; //文字数上限
    let userId = userName;
    if(userId.length > maxLength){
      userId = userId.substr(0, maxLength) + '...'; // 8文字以上は「…」で省略する
    }
    // ポップアップ用DomContentを作成
    const memoPopup = MemoPopup({memoId, userId, createDateTime, lng, lat, memoText, memoTitle});
    // domContentForMemoPopupの中から削除ボタンを取得
    // NOTE memoPopupの中で削除ボタンを位置が変わる場合は、変更する必要がある
    const deleteButton = memoPopup.lastElementChild.lastElementChild.lastElementChild;
    deleteButton.addEventListener('click', deleteMemo, false);
    // domContentForMemoPopupの中から更新ボタンを取得
    const updateButton = memoPopup.lastElementChild.lastElementChild.firstElementChild;
    updateButton.addEventListener('click', openUpdateMemoPopup, false);
    // ポップアップを作成する
    const popup = new mapboxgl.Popup({ offset: 25 }).setText( memoText ).setDOMContent(memoPopup);
    // マーカーを作成する
    const el = document.createElement('div');
    el.id = 'marker_' + memoId;
    if (latestIdRef.current === memoId) {
      el.classList.add('mapboxgl-red-marker'); // 登録した最新memoIdにクラス追加
    }
    el.addEventListener('click', selectedMarker, false);
    // マーカーを作成する
    new mapboxgl.Marker(el)
    .setLngLat(monument)
    .setPopup(popup) // sets a popup on this marker
    .addTo(map.current);
  }

  // メモフォームがサブミット可能か
  const [isMemoFormValid, setIsMemoFormValid] = useState(false);
  const [isMemoTitleValid, setIsMemoTitleValid] = useState(false);

  /** メモフォームのchangeハンドラー */
  const handleChangeMemoForm = (e) => {
    if (e.target.value.length > 0 && e.target.value.length <= 250) {
      setIsMemoFormValid(true);
    } else {
      setIsMemoFormValid(false);
    }
  }

  // メモタイトルのchangeハンドラー
  const handleChangeMemoTitle = (e) => {
    if (e.target.value.length > 0 && e.target.value.length <= 50) {
      setIsMemoTitleValid(true);
    } else {
      setIsMemoTitleValid(false);
    }
  }

  /**
   * マップ情報作成
   * @param {Object} updateCenter {lng: ***, lat: ***}
   */
  const createMapInstance = (updateCenter) => {
    return new mapboxgl.Map({
      container: mapContainer.current,
      style: mapInstanceStyle,
      center: updateCenter ? [updateCenter.lng, updateCenter.lat] : [longitudeCenter, latitudeCenter],
      zoom: currentZoom,
    });
  }

  /**
   * マップのズーム率が変更された時の処理
   * 変更後のズーム率を変数にセット
   */
  const handleChangeMapZoomLevel = () => {
    setCurrentZoom(map.current.getZoom());
  }

  /** mapにイベント等を設定 */
  const setMap = () => {
    // ラスターを追加
    addMapLayers(mapLayersUrlsRef.current);
    // 地質図ラスターを追加
    addGeologyLayer();
    // AOIのレイヤーを追加
    addAoiLayers(aoisGeojson);
    // // 3Dレイヤー追加
    add3DLayer();
    // MapLayerの表示・非表示をセット
    showMapLayers();
    // 汎用ラスター(地質図)の表示・非表示をセット
    showGeologyLayers();
    // 選択中のAOIの解析データを表示
    addSelectedAoiAnalysisLayer(aoisStateRef.current, aoisDataRef.current);
    // AOIの状態を設定
    setAoisFeatureState();
    // 距離測定・面積測定
    showDrawingPolygonArea();
    // 地図にズームと回転のコントロールを追加する
    map.current.addControl(new mapboxgl.NavigationControl());
    // ジオコーダーの追加
    map.current.addControl(geocoder);
    // 3Dボタン マップが描画されてからアクテイブ
    document.getElementById('listingGroup') && document.getElementById('listingGroup').classList.add('buttonActive');
    // memoボタン マップが描画されてからアクテイブ
    document.getElementById('memo') && document.getElementById('memo').classList.add('buttonActive');
    // googleStreetViewボタン マップが描画されてからアクテイブ
    document.getElementById('googleStreetView') && document.getElementById('googleStreetView').classList.add('buttonActive');
    // 現在地ボタン マップが描画されてからアクテイブ
    document.getElementById('currentLocation') && document.getElementById('currentLocation').classList.add('buttonActive');
    // 初期設定位置に戻るボタン マップが描画されてからアクテイブ
    document.getElementById('defaultLocation') && document.getElementById('defaultLocation').classList.add('buttonActive');
    // AOIがクリックされたときのイベントを設定
    map.current.on('click', 'aoisInner', handleClickAoi);
    // マップのズーム率が変更された時の処理
    map.current.on('zoom', handleChangeMapZoomLevel);
    // メモマーカー表示非表示を設定
    setMemoMarker(objectLayerState['メモ']);
    //　画面描画時のAOIレイヤー(内側領域全体)を設定
    changeAoiInsideColor(aoisGeojson, map.current, selectInsideColor);
    // 縮尺スケールを表示
    map.current.addControl(new mapboxgl.ScaleControl({
      maxWidth: 200,
      unit: 'metric'
    }));
  }

  /** Map読み込み中の時の処理 */
  const loadingEvent = () => {
    setMapLoading(true); // マップロード：オン
    const intervalId = setInterval(()=>{
      //0.5秒ごとにマップのロード状態を確認する
      if (map.current.loaded()) {
        setMapLoading(false); // マップロード：オフ
        clearInterval(intervalId);
      }
    }, 500); // 描画中か確認する
  }

  /** zenrin地図選択時にローディングを走らせる */
  useEffect(() => {
    if(!zenrinMapLoading) return;
    setMapLoading(true); // マップロード：オン
    setZenrinMapLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zenrinMapLoading])

  /** znet地図選択時にローディングを走らせる */
  useEffect(() => {
    if(!znetMapLoading) return;
    setMapLoading(true); // マップロード：オン
    setZnetMapLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [znetMapLoading])

  /** 初期描画時 */
  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (!aoisGeojson) return;
    setIsMapFirstLoadingDone(false);

    /** Mapの初期化 */
    map.current = createMapInstance();

    // 0.5秒ごとにマップのロード状態を確認する
    const intervalId = setInterval(()=>{
      // マップの描画が完了かつ非購入AOIデータの取得が完了したタイミングでローディングを終了
      if (map.current.loaded() && projectAoiTypeRef.current !== null) {
        setMapLoading(false); // マップロード：オフ
        clearInterval(intervalId);
      }
    }, 500); // 描画中か確認する

    map.current.on('load', () => {
      setMap();
      setIsMapFirstLoadingDone(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoisGeojson]);

  // aoisGeojsonが更新された際の処理 
  useEffect(() => {
    if (!map.current) return;
    if (!isFetchfirstAoigeojson) return; // 初回のaoisGeojson読み込み時には以下の処理を行わない
    const aoisGeojsonIds = aoisGeojson.features.map(value => value.properties.id);

    // 解析点を表示しているAOIのIDを整理
    const removeNullShowIds = AnalyticeIsShowAoi.filter(item => item !== null); // Nullが存在する場合があるので削除
    const AnalyticeShowIds = [...new Set(removeNullShowIds)]; // IDの重複が存在する場合があるので削除

    // 現在解析点を表示しているAOIと最新のaoisGeojsonのIDを比較して一致しないものを格納
    const removeAnalysisIds = AnalyticeShowIds.filter(item => !aoisGeojsonIds.includes(item));

    // 一致していないものは不要なので解析点を削除及びドロワメニューのスイッチをオフにする
    removeAnalysisIds.forEach(aoiId => {
      map.current.removeLayer(`analysisPoints_${aoiId}`);// 不要な解析点は削除
      // 削除したAOIに紐づくドロワメニューのSwitchをオフにする
      const aoiName = getAoiNameFromAoiId(aoiId, aoisGeojson.features);
      handleChangeAoisState(aoiName, false);
    })

    let onDrawerSwitchIDs; // ドロワメニューのスイッチをオンにするものをここに貯める
    onDrawerSwitchIDs = AnalyticeShowIds.filter(item => aoisGeojsonIds.includes(item)); // 現在表示されている解析点のIDを格納
    // geojsonの更新で削除した解析点があれば実行
    if (removeAnalysisIds.length !== 0){
      onDrawerSwitchIDs = onDrawerSwitchIDs.filter(item => !removeAnalysisIds.includes(item)); // 削除した解析点のIDを配列から除外
      setAnalyticeIsShowAoi(prevState => prevState.filter(item => !removeAnalysisIds.includes(item))); // 現在表示している解析点のIDリストから削除したIDを除外
    }

    map.current.getSource('projectAois').setData(aoisGeojson); // AOIを再描画
    changeAoiInsideColor(aoisGeojson, map.current, selectInsideColor); // AOIの色を変更

    // 0.5秒ごとにマップのロード状態を確認する
    const intervalId = setInterval(()=>{
      // マップの描画が完了かつ非購入AOIデータの取得が完了したタイミングでローディングを終了
      if (map.current.loaded() && projectAoiTypeRef.current !== null) {
        setAoiPurchaseExeFlag(true); // 非購入AOIのスタイルを変更
        // ドロワメニューのAOIのSwitchをオンにする
        onDrawerSwitchIDs.forEach(aoiId => {
          const aoiName = getAoiNameFromAoiId(aoiId, aoisGeojson.features);
          handleChangeAoisState(aoiName, true);
        })
        setReFetchAoiLoading(false); // マップロード：オフ
        clearInterval(intervalId);
      }
    }, 500); // 描画中か確認する

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[aoisGeojson])

  /** 絞り込み検索時のAOIの処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない

    // リセットボタン押下時の処理
    if(sortResetAoiDataIdList){
      if (prevAoiSearchIdList.length){
        prevAoiSearchIdList.forEach(value => {
          map.current.setFeatureState(
            { source: 'projectAois', id: value },
            { search: false }
          );
        })
        setPrevAoiSearchIdList([]); // 絞り込み検索に一致したaoiのidを初期化
      }
      if (prevAoiDeleteIdList.length){
        prevAoiDeleteIdList.forEach(value => {
          map.current.setFeatureState(
            { source: 'projectAois', id: value },
            { sort: false }
          );
        })
        setPrevAoiDeleteIdList([]); // 絞り込み検索に一致しなかったaoiのidを初期化
      }
      return;
    }

    //aoiの数の配列を作成
    const featureLength = [...Array(aoisGeojson.features.length).keys()].map(i => ++i);

    // 検索条件に一致しなかったaoiのリストを作成
    let deleteAoiList = []
    deleteAoiList = featureLength.filter(i => sortAoiDataIdList.indexOf(i) == -1);

    //2回目以降の検索時は削除したaoiを一旦再度表示する
    if (prevAoiDeleteIdList.length){

      //　検索条件に一致したaoiのアウトラインをもとに戻す
      prevAoiSearchIdList.forEach(value => {
        map.current.setFeatureState(
          { source: 'projectAois', id: value },
          { search: false }
        );
      })

      //　検索条件に一致しなかったaoiのアウトラインをもとに戻す
      prevAoiDeleteIdList.forEach(value => {
        map.current.setFeatureState(
          { source: 'projectAois', id: value },
          { sort: false }
        );
      })
    }

    //検索条件に一致したaoiのアウトラインは少し太くする
    sortAoiDataIdList.forEach(value => {
      map.current.setFeatureState(
        { source: 'projectAois', id: value },
        { search: true }
      );
    })

    //検索条件に一致しなかったaoiのアウトラインは少し薄くする
    deleteAoiList.forEach(value => {
      map.current.setFeatureState(
        { source: 'projectAois', id: value },
        { sort: true }
      );
    })

    // NOTE 二回目の絞り込み検索時は、前回の絞り込みを行ったaoiを再度リセットする必要があるため保存しておく
    setPrevAoiSearchIdList(sortAoiDataIdList) // 削除したaoiのidを保存
    setPrevAoiDeleteIdList(deleteAoiList) // 削除したaoiのidを保存

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAoiDataIdList, sortResetAoiDataIdList]);

  //AOIの内側領域色の設定の処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない

    changeAoiInsideColor(aoisGeojson, map.current, aoiInsideColorBtn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoiInsideColorBtn]);

  // AOIのTYPEがGridの場合、非購入AOIのスタイルを変更する
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDone) return; // 画面初期描画時は以下の処理は行わない
    if (!aoiPurchaseExeFlag) return;
    if (projectAoiType === null) return;

    // Gridパターンの場合は購入情報を参照し、非購入AOIのスタイルを変更する
    if(projectAoiType === 'Grid'){
      // 全てのAOIのIDを取得
      const aoiGeojsonIds = aoisGeojson.features.map(value => {return value.id})
      // 非購入AOIのIDを抽出
      const noPurchaseIds = aoiGeojsonIds.filter(value => !aoiPurchaseData.includes(value)).concat(aoiPurchaseData.filter(value => !aoiGeojsonIds.includes(value)));
      // 非購入AOIのスタイルを変更する処理
      // https://chat.openai.com/share/b2355a5a-57d4-41e7-9035-2938fa1d8446
      let filter = ['all', ['!', ['in', ['get', 'id'], ['literal', noPurchaseIds]]]];
      handleChangePurchaseFill(map.current, filter, 0.1, 0.4);
      handleChangePurchaseOutLine(map.current, filter, 0.1, 1);
    }
    setAoiPurchaseExeFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[aoiPurchaseData, projectAoiType, isMapFirstLoadingDone, aoiPurchaseExeFlag])

  //現在地ボタン押下時の処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (Object.keys(nowLocationLatLng).length === 0) return;// 現在地が取得できない場合は以下の処理を行わない

    // マップ上で現在地に移動する
    map.current.flyTo({
      center: [nowLocationLatLng.lng, nowLocationLatLng.lat],
      zoom: 13,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowLocationLatLng])

  //現在地取得時のローディング処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    setMapLoading(nowLocationLoadingFlag); // 現在値取得中はローディングをオンにする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowLocationLoadingFlag])

  //管理者コメント登録時のローディング処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    setMapLoading(registerAdminCommentLoadingFlag); // 現在値取得中はローディングをオンにする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerAdminCommentLoadingFlag])

  //初期表示位置登録時のローディング処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    setMapLoading(mapRegsiterLatLngData); // 初期表示位置登録中はローディングをオンにする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRegsiterLatLngData])

  /** Map Style 変更時 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    setMapLoading(true); // マップロード：オン

    // 3Dモードが有効だったら無効にする
    if (threeDeeMode) document.getElementById('dragRotate').click();
    // GoogleStreetViewボタン：オフ
    setGsvButtonStatus(false);
    map.current.off('click', openGoogleStreetViewConfirmDialog);
    // メモモード：オフ
    setMemoButtonStatus(false);
    map.current.off('click', openMemoFormDialog);
    //絞り込み検索の内容をリセット
    setPrevAoiSearchIdList([]);
    setPrevAoiDeleteIdList([]);
    setSelectRankPeriod('selectNone');
    setSelectRankLevel('selectNone');
    setSelectRankPeriodErrorFlag(false);
    setSelectRankLevelErrorFlag(false);

    /** Mapの初期化 */
    map.current.remove(); // mapを初期化
    map.current = createMapInstance(map.current.getCenter());
    map.current.on('load', () => {
      setMap();
    });

    // 0.5秒ごとにマップのロード状態を確認する
    const intervalId = setInterval(()=>{
      // マップ描画完了時に実行
      if (map.current.loaded()) {
        setAoiPurchaseExeFlag(true); // 非購入AOIのスタイルを変更
        setMapLoading(false); // マップロード：オフ
        clearInterval(intervalId); 
      }
    }, 500); // 描画中か確認する

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstanceStyle]);

  /** 初期表示位置の処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!configUserLatLngFetchFlag) return; // 初期表示位置の値をfetchしてから以下を実行
    if (!configUserLatLngFlag) return; //　初期表示位置設定のフラグがtrueであれば以下を実行

    // 初期表示位置が保存されている場合はドロワーメニューに情報を渡す
    let UserLatLngDatas = {};
    UserLatLngDatas['lng'] = configUserLng;
    UserLatLngDatas['lat'] = configUserLat;
    UserLatLngDatas['flag'] = configUserLatLngFlag;
    setConfigUserLatLngDatas(UserLatLngDatas);

    // 初期表示位置に移動
    map.current.jumpTo({
      center: [configUserLng, configUserLat],
      zoom: 13,
    });
    setConfigUserLatLngFetchFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configUserLatLngFetchFlag])

  /** AOIのポイントがクリックされたときのイベント */
  useEffect(() => {
    if (!map.current) return; // wait for map.current to initialize
    if (!clickedAoiIdRef.current) return;
    
    // geojson内に分割数を記述されていることを想定
    // geojson取得時にイベントリスナーを登録しても良いのでは？
    // TODO: geojson内に分割数フラグがある場合は下記を実行
    let splitGeojsonIds = ['','_1','_2','_3','_4','_5','_6','_7','_8','_9','_10','_11','_12','_13','_14','_15','_16','_17','_18','_19','_20','_21','_22','_23','_24','_25','_26','_27','_28','_29','_30','_31','_32','_33','_34','_35','_36','_37','_38','_39','_40','_41','_42','_43','_44','_45','_46','_47','_48','_49']
    splitGeojsonIds.forEach(value => {
      map.current.on('click', `analysisPoints_${clickedAoiIdRef.current}${value}`, handleClickPoint);
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedAoiId, mapInstanceStyle]);

  /** ラスターのチェックボックスが変更されたときに、ラスターを再設定 */
  useEffect(() => {
    if (!map.current) return;
    if (!map.current.loaded()) return;
    showMapLayers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLayerState]);

  /** 独自ラスターのチェックボックスが変更されたときに、ラスターを再設定 */
  useEffect(() => {
    if (!map.current) return;
    if (!map.current.loaded()) return;
    showGeologyLayers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geologyLayerState]);

  /** 選択するAOI、凡例の上限・下限に変更があったとき、選択されているAOI中のPointを描画 */
  useEffect(() => {
    if (!map.current) return;
    // 選択中のAOIの解析データレイヤーを追加
    addSelectedAoiAnalysisLayer(aoisStateRef.current, aoisDataRef.current);
    // 選択中のAOI解析データの計算処理
    calculationAnalysisData(clickedAoiIdRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLegendMin, selectedLegendMax, quantitySearchDate]);

  /** ドロワーメニューでAOIの選択状態が変化した時の処理 */
  useEffect(() => {
    if (!map.current) return; // wait for map.current to initialize
    if (!map.current.loaded()) return;

    loadingEvent(); // ローディング処理 

    const aoiSwitchShowHide = async () => {
      const aoiNames = Object.keys(aoisState);
      // 選択中のAOIのID
      const selectedIds = aoiNames
        .filter(aoiName => aoisState[aoiName])
        .map(selectedAoiName => getAoiIdFromAoiName(selectedAoiName, aoisDataRef.current));
  
      // 全てのレイヤーを取得
      const allLayers = map.current.getStyle().layers;
      // 全てのレイヤーから解析データのレイヤーを抽出して、そのレイヤーのid（AOIのid）の配列を作成
      const previosSelectedAoiIds = allLayers
        .filter(layer => layer.id.indexOf("analysisPoints_") !== -1)
        .map(layer => Number(layer.id.split('_')[1]));
  
      // チェックが外されたAOIのID
      const uncheckedAoiIds = previosSelectedAoiIds.filter(id => selectedIds.indexOf(id) === -1);
      // チェックが外されたAOIがあれば
      uncheckedAoiIds.forEach(uncheckedAoiId => {
        map.current.setFeatureState(
          { source: 'projectAois', id: uncheckedAoiId },
          { selected: false }
        );
        removeAnalysisPointsLayer(uncheckedAoiId);

        // 分割geojsonも削除する
        let splitGeojsonIds = ['','_1','_2','_3','_4','_5','_6','_7','_8','_9','_10','_11','_12','_13','_14','_15','_16','_17','_18','_19','_20','_21','_22','_23','_24','_25','_26','_27','_28','_29','_30','_31','_32','_33','_34','_35','_36','_37','_38','_39','_40','_41','_42','_43','_44','_45','_46','_47','_48','_49']
        splitGeojsonIds.forEach(splitGeojsonId => {
          removeAnalysisPointsLayer(`${uncheckedAoiId}${splitGeojsonId}`);
        })
      });
  
      // 新たにチェックされたAOIのID
      const checkedAoiIds = selectedIds.filter(id => previosSelectedAoiIds.indexOf(id) === -1);
      // 新たにチェックされたAOIがあれば
      // for (const id of selectedAoisIds)
      for(const checkedAoiId of checkedAoiIds) {
        map.current.setFeatureState(
          { source: 'projectAois', id: checkedAoiId },
          { selected: true }
        );
        if(!noDataAoiIdsRef.current.includes(checkedAoiId)){ // 解析データが無いAOIの場合はAOIに解析ポイントを追加しない
          
          try {
            // 無印geojsonを読み込む
            const response = await fetch(`${analysisPointsUrlPrefix}/${projectId}/analysis/analysis_${checkedAoiId}.geojson`);
            const pointsGeojson = await response.json();
            addAnalysisPointsLayer(checkedAoiId, pointsGeojson);

          } catch (e) {
            // 分割geojsonの読み込み
            let n = 1;
            let isGeojson = true;
            while (isGeojson) {
              try {
                // 分割geojsonの場合は末尾に番号を付与する
                const splitGeojsonId = `${checkedAoiId}_${n}`;
  
                // 分割geojsonを番号順に取得
                const pointsGeojson = await fetch(`${analysisPointsUrlPrefix}/${projectId}/analysis/analysis_${checkedAoiId}_${n}.geojson`)
                    .then(response => response.json());
                addAnalysisPointsLayer(splitGeojsonId, pointsGeojson); // 無印geojsonを読み込む
                n++;
              } catch (error) {
                // これ以上分割されたgeojsonは存在しないと判断し、処理を停止
                isGeojson = false;
              }
            }
          }
        }
      };
    }
    aoiSwitchShowHide();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoisState]);

  /** AOIのZoomボタンが押されたとき */
  useEffect(() => {
    if (!aoisDataRef.current) return;
    const zoomAoi = aoisDataRef.current.find(aoi => aoi.id === zoomAoiId);
    if (!zoomAoi) return;
    map.current.flyTo({ // Zoomボタンが押されたAOIをMapの中心に
      center: [zoomAoi.properties.aoiLngCenter, zoomAoi.properties.aoiLatCenter],
      zoom: 15,
    });
    map.current.setFeatureState(
      { source: 'projectAois', id: zoomAoi.id },
      { zoom: true }
    );
    setZoomAoiId(null); // zoomするAOIを初期化
    setTimeout(() => { // Zoom時に色を変えた線を2秒後に元に戻す
      map.current.setFeatureState(
        { source: 'projectAois', id: zoomAoi.id },
        { zoom: false }
        );
      }, 2000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomAoiId])

  /** ドロワーメニューのメモ一覧のZoomボタンが押された時 */
  useEffect(() => {
    if (!aoisDataRef.current) return;
    if (!memoZoomItem) return;
    if (!objectLayerState['メモ']) setObjectLayerState({ ...objectLayerState, 'メモ': true });// トグルボタンがOFFの時はONにする
    setMemoMarker(true)//メモをマップ上に表示

    // NOTE メモの登録,削除を行うとエラーになる
    // const div = document.getElementById('marker_' + memoZoomItem.memoid);
    // div.classList.add('mapboxgl-red-marker');

    // Zoomボタンが押されたメモの緯度経度をMapの中心にする
    map.current.flyTo({
      center: [memoZoomItem.lng, memoZoomItem.lat],
      zoom: 15,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoZoomItem])

  /** オブジェクトのON/OFFの切り替え時 */
  useEffect(() => {
    if (!map.current) return; // wait for map.current to initialize
    if (!map.current.loaded()) return;
    //メモ一覧表示
    setLatestId(false); // 登録IDリセット
    setMemoMarker(objectLayerState['メモ'])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectLayerState]);
  
  /** 管理者コメントの登録時にsnackbarを表示させる処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!regsiterFetchFlag) return; // 管理者コメント登録用のAPIを叩いた際に以下を実行

    // snackbarの色の切り分け
    if (isSuccessAdminComment){
      // 管理者コメント登録成功時
      setAlertSeverity('success');
      fetchAoiAdminComment(); //　管理者コメント登録成功時にコメント情報を最新にする
    }else{
      // 管理者コメント登録失敗時
      setAlertSeverity('error');
    }

    // snackbarを表示
    setIsAlertOpen(regsiterFetchFlag);
    setAlertMesseageMemo(adminCommentResultMessage);
    setRegsiterFetchFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[regsiterFetchFlag])

  /** 管理者コメント取得時にエラーがあった場合、snackbarを表示する */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!getAdminCommentAlertFlag) return; // 管理者コメント取得時にエラーがある場合に以下の処理を行う

    // snackbarを表示
    setIsAlertOpen(getAdminCommentAlertFlag);
    setAlertMesseageMemo('管理者コメントの取得に失敗しました。');
    setGetAdminCommentAlertFlag(false);
    setAlertSeverity('error');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdminCommentAlertFlag])

  /** 初期表示位置設定ボタン押下時の処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!mapNowLatLngFlag) return;

    setMapNowLatLngData(map.current.getCenter());// MAP上の現在地を取得しstateに保存

    setMapNowLatLngFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapNowLatLngFlag])

  /** 初期表示位置の設定を保存orリセットした際の処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!regsiterMessageFlag) return;

    // snackbarの色の切り分け
    if (isSuccess){
      if (regsiterResetFlag){
        // 初期表示位置リセットボタン押下時はプロジェクトごとの初期表示位置の座標を表示
        mapNowLatLngData['flag'] = false;
      }else{
        // 初期表示位置保存ボタン押下時は保存した地点の座標を表示
        mapNowLatLngData['flag'] = true;
      }
      setAlertSeverity('success');
      setConfigUserLatLngDatas(mapNowLatLngData); // ドロワーメニューの現在の緯度経度の情報を更新
    }else{
      setAlertSeverity('error');
    }

    // snackbarを表示
    setIsAlertOpen(regsiterMessageFlag);
    setAlertMesseageMemo(regsiterMessage);
    setRegsiterMessageFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regsiterMessageFlag])

  /** 初期表示位置情報取得時にエラーがあった場合、snackbarを表示する */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!configUserLatLngErrorFlag) return; // 初期表示位置情報取得時にエラーがある場合に以下の処理を行う
    if (!configUserLatLngFlag) return; //　初期表示位置設定のフラグがtrueであれば以下を実行

    //ドロワーメニューにデフォルトの緯度経度情報を表示させるようにする
    let UserLatLngDatas = {};
    UserLatLngDatas['lng'] = null;
    UserLatLngDatas['lat'] = null;
    UserLatLngDatas['flag'] = false;
    setConfigUserLatLngDatas(UserLatLngDatas);

    // snackbarを表示
    setIsAlertOpen(configUserLatLngErrorFlag);
    setAlertMesseageMemo('初期表示位置情報の取得に失敗しました。');
    setAlertSeverity('error');
    setConfigUserLatLngErrorFlag(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[configUserLatLngErrorFlag])

  /** zenrin地図の認証URLの取得に失敗した際の処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!zenrinFetchErrorFlag) return;

    // snackbarを表示
    setAlertSeverity('error');
    setIsAlertOpen(zenrinFetchErrorFlag);
    setAlertMesseageMemo(zenrinErrorMessage);

    //ローディングは強制終了
    setMapLoading(false);

    setZenrinFetchErrorFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zenrinFetchErrorFlag])

  /** znet地図の認証URLの取得に失敗した際の処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!znetFetchErrorFlag) return;

    // snackbarを表示
    setAlertSeverity('error');
    setIsAlertOpen(znetFetchErrorFlag);
    setAlertMesseageMemo(znetErrorMessage);

    //ローディングは強制終了
    setMapLoading(false);

    setZnetFetchErrorFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [znetFetchErrorFlag])

  /** 凡例情報取得用のカスタムフック */
  const { geologyLngLatData, geologyDataFetchFlag, setGeologyDataFetchFlag, geologyCreateMarker, getGeologyLngLat } = useGeologyDataPopup();

  /** 凡例情報取得ボタン押下時の処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない

    map.current.getCanvas().style.cursor = ''; // デフォルトのカーソル表示
    map.current.off('click', getGeologyLngLat);
    //すべての地質図マーカーを削除
    const geologyMarkers = document.getElementsByClassName('geology-marker');
    if (geologyMarkers.length > 0) geologyMarkers[0].remove('geology-marker');
    if (!GeologyDataGetMode) return; // 地質図の凡例情報取得モードがOFFの場合は以下の処理を行わない。

    map.current.getCanvas().style.cursor = 'crosshair'; // 十字カーソル表示
    //凡例取得以外の機能は全てオフにする
    setGsvButtonStatus(false); // GoogleStreetViewボタン：オフ
    map.current.off('click', openGoogleStreetViewConfirmDialog);
    setMemoButtonStatus(false); // メモモード：オフ
    map.current.off('click', openMemoFormDialog);
    // 距離測定・面積測定モードをリセット
    draw.changeMode('simple_select');

    // 凡例情報取得モード時にマップをクリックした際の処理
    map.current.on('click', getGeologyLngLat);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[GeologyDataGetMode])

  // 凡例情報取得モード時にマップをクリックしたときのイベント
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!GeologyDataGetMode) return; // 地質図の凡例情報取得モードがOFFの場合は以下の処理を行わない。

    // マーカー情報の準備
    let el = geologyCreateMarker();
    let lnglatData = [geologyLngLatData.lng, geologyLngLatData.lat];

    // ポップアップを作成する
    // @see https://www.lostcreekdesigns.co/writing/how-to-create-a-map-popup-component-using-mapbox-and-react/
    const popupNode = document.createElement("div")
    ReactDOM.render(
      <GeologyPopup
        geologyDataFetchFlag={geologyDataFetchFlag}
        setGeologyDataFetchFlag={setGeologyDataFetchFlag}
        lng={geologyLngLatData.lng}
        lat={geologyLngLatData.lat}
      />, 
      popupNode
    )
    const popup = new mapboxgl.Popup({ offset: 25, closeButton: false }).setDOMContent(popupNode);

    //一旦すべての地質図マーカーを削除
    const geologyMarkers = document.getElementsByClassName('geology-marker');
    if (geologyMarkers.length > 0) geologyMarkers[0].remove('geology-marker');
    
    // マップ上に地質図凡例情報用のマーカーを添付
    const geologyMarkerDom = new mapboxgl.Marker(el)
      .setLngLat(lnglatData)
      .setPopup(popup) // sets a popup on this marker
      .addTo(map.current);

    // マーカーのホバーでpopupの表示を切り替え
    el.addEventListener('mouseenter', () => geologyMarkerDom.togglePopup());
    el.addEventListener('mouseleave', () => geologyMarkerDom.togglePopup());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[geologyLngLatData])

  /** 地質図の不透明度を変更する処理 */
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    
    let geologyOpacityRate = geologyMapOpacity / 100;
    map.current.setPaintProperty('Geology', 'raster-opacity', geologyOpacityRate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[geologyMapOpacity])

  // 解析点の大きさ,解析点の色分けを設定した値に変更する処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない

    // 解析ポイントIDが配列内に重複している場合は重複を排除する
    const deleteAnalyticePointId = new Set(AnalyticeIsShowAoi);
    const deleteAnalyticePointIds = [...deleteAnalyticePointId];

    // 前回クリックしたAOIの情報をリセット
    setClickAoiId(null);

    // 解析点の大きさを変更するため再度解析点を表示する
    deleteAnalyticePointIds.forEach((showAoiId) => {
      const pointsGeojson = `${analysisPointsUrlPrefix}/${projectId}/analysis/analysis_${showAoiId}.geojson`;
      addAnalysisPointsLayer(showAoiId, pointsGeojson);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[analysisStateChangeFlag])

  // 観測地点選択のトグルスイッチの状態が変化した際の処理
  useEffect(() => {
    if (!map.current) return;
    if (!isMapFirstLoadingDoneRef.current) return; // 画面初期描画時は以下の処理は行わない
    if (!weaterObservationDataFetchFlag) return; //観測点情報を取得していない場合は以下の処理は行わない

    weaterObservationData.forEach(value => {
      const el = document.createElement('div');
      el.classList.add('weatherMarker');
      el.setAttribute('id', value.stid);
      const lnglat = [value.longitude, value.latitude];
      new mapboxgl.Marker(el).setLngLat(lnglat).addTo(map.current);
      el.addEventListener('click',handleClickIsWeatherPopup, false);
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weaterObservationDataFetchFlag])

  // AOIフィルタリングが行われた場合の処理
  useEffect(() => {
    if (!filterExecuteFlag) return;

    // 現在表示されているAOIのIDを取得
    const aoiIdList = aoiIds.map(value => { return value.id })

    // フィルタリングの入力項目が全て空の場合はリセットとして以降の処理を行わない
    if(!filterInputFlag) {

      // 非表示にしていたAOIを一旦全て再表示する処理
      let resetAoi = ['all', ['!', ['in', ['get', 'id'], ['literal', []]]]];
      map.current.setFilter('aoisInner', resetAoi);
      map.current.setFilter('aoisOutline', resetAoi);

      setIsFilterModal(false); // フィルタリングモーダルを閉じる
      setFilterExecuteFlag(false); // フィルタリング実行フラグをリセット
      return;
    }

    // フィルタリングに結果に該当しなかったAOIを非表示にする処理
    const filteringAoiIds = aoiIdList.filter(element => !filteringAois.includes(element)).concat(filteringAois.filter(element => !aoiIdList.includes(element)));
    let hideAoi = ['all', ['!', ['in', ['get', 'id'], ['literal', filteringAoiIds]]]];
    map.current.setFilter('aoisInner', hideAoi);
    map.current.setFilter('aoisOutline', hideAoi);

    setIsFilterModal(false); // フィルタリングモーダルを閉じる
    setFilterExecuteFlag(false); // フィルタリング実行フラグをリセット
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterExecuteFlag])

  return (
    <>
      <div ref={mapContainer} className="map-container" style={{ height: '100vh' }} />
      <div id="calculated-area"></div>
      <nav id="listingGroup" className={classes.listingGroup}>
        <label className={classes.listingGroupLabel} htmlFor="dragRotate">
          <Tooltip title={t("map.3DDisplay")} arrow>
            <Button className={classes.dragRotateButton} variant="contained">
              <input className={classes.listingGroupInput} onChange={switchBetween2dAnd3d} type="checkbox" id="dragRotate" />
              <ThreeDRotationIcon />
            </Button>
          </Tooltip>
        </label>
      </nav>
      {/* GoogleStreetView */}
      <div id="googleStreetView">
        <Tooltip title={t("map.googleStreetView")} arrow>
          <Button onClick={handleClickGoogleStreetViewButton} className={gsvButtonStatus ? `${classes.googleStreetViewButtonActive} ${classes.googleStreetViewButton}` : classes.googleStreetViewButton} variant="contained">
            <StreetviewIcon />
          </Button>
        </Tooltip>
        <Dialog maxWidth='xs' id="gsvDialog" open={gsvPopupStatus}>
          <DialogContent>
            <Typography gutterBottom>
              GoogleStreetViewを開きますか？
            </Typography>
            <Typography gutterBottom>
              <PlaceIcon className={classes.placeIcon} />
              {Math.floor(lngLatData.lat * Math.pow(10, 5)) / Math.pow(10, 5)}, {Math.floor(lngLatData.lng * Math.pow(10, 5)) / Math.pow(10, 5)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeGsvPopup} variant='contained'>
              閉じる
            </Button>
            <Button onClick={gsvLink} variant='contained' color='primary'>
              開く
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* memo */}
      <div id="memo">
        <Tooltip title={t("map.memo")} arrow>
          <Button onClick={handleClickMemoButton} className={memoButtonStatus ? classes.memoButtonActive : classes.memoButton} variant="contained">
            <CreateIcon />
          </Button>
        </Tooltip>
        {/* メモ新規登録用ダイアログ */}
        <Dialog maxWidth='xs' fullWidth={true} id="Dialog" open={memoFormStatus} aria-labelledby="memo-form">
          <DialogContent>
            <FormControl fullWidth={true} variant="outlined" style={{ marginBottom: '20px' }}>
              <InputLabel htmlFor="memoTextarea">Title</InputLabel>
              <OutlinedInput
                label='Title'
                id="memoTitlearea"
                fullWidth={true}
                multiline={true}
                rows={1}
                aria-label="title-form"
                inputProps={{ minLength: 1, maxLength: 50 }}
                onChange={handleChangeMemoTitle}
              />
              <FormHelperText>50字以内で入力してください</FormHelperText>
            </FormControl>
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel htmlFor="memoTextarea">Memo</InputLabel>
              <OutlinedInput
                label='Memo'
                id="memoTextarea"
                fullWidth={true}
                multiline={true}
                rows={10}
                aria-label="memo-form"
                inputProps={{ minLength: 1, maxLength: 250 }}
                onChange={handleChangeMemoForm}
              />
              <FormHelperText>250字以内で入力してください</FormHelperText>
            </FormControl>
            {/* アラート */}
            {isAlertFormOpen &&
              <AlertMessage
                alertSeverity='error'
                alertMessage={alertMessageMemoForm}
              />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickMemoFormCloseButton} variant='contained'>
              キャンセル
            </Button>
            <Button disabled={!isMemoFormValid || !isMemoTitleValid} onClick={registerMemo} variant='contained' color='primary'>
              保存する
            </Button>
          </DialogActions>
        </Dialog>

        {/* メモ更新用ダイアログ */}
        <Dialog maxWidth='xs' fullWidth={true} id="Dialog" open={memoUpdateFormStatus} aria-labelledby="memo-form">
          <DialogContent>
            <FormControl fullWidth={true} variant="outlined" style={{ marginBottom: '20px' }}>
              <InputLabel htmlFor="memoTextarea">Title</InputLabel>
              <OutlinedInput
                label='Title'
                id="memoUpdateTitleArea"
                fullWidth={true}
                multiline={true}
                rows={1}
                aria-label="title-form"
                inputProps={{ minLength: 1, maxLength: 50 }}
                value={memoUpdateTitle}
                onChange={handleChangeUpdateMemoTitleValidate}
              />
              <FormHelperText>50字以内で入力してください</FormHelperText>
            </FormControl>
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel htmlFor="memoTextarea">Memo</InputLabel>
              <OutlinedInput
                label='Memo'
                id="memoUpdateTextArea"
                fullWidth={true}
                multiline={true}
                rows={10}
                aria-label="memo-form"
                inputProps={{ minLength: 1, maxLength: 250 }}
                value={memoUpdateText}
                onChange={handleChangeUpdateMemoFormValidate}
              />
              <FormHelperText>250字以内で入力してください</FormHelperText>
            </FormControl>
            {/* アラート */}
            {isUpdateAlertFormOpen &&
              <AlertMessage
                alertSeverity='error'
                alertMessage={alertUpdateMessageMemoForm}
              />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdatePopupCancelButton} variant='contained'>
              キャンセル
            </Button>
            <Button disabled={!isMemoFormUpdateValid || !isMemoTitleUpdateValid} onClick={updateMemo} variant='contained' color='primary'>
              更新する
            </Button>
          </DialogActions>
        </Dialog>
        {/* 雨量観測地点選択ポップアップ*/}
        <Dialog maxWidth='xs' id="weatherDialog" open={weatherPopupStatus}>
          <DialogContent>
            <Typography>
              この観測地点の雨量データを取得しますか？
            </Typography>
            <div className={classes.observationPopupContent}>
              <div className={classes.observationPopupItem}>{observationPopupContent.observationName}（標高:{observationPopupContent.elevation}m）</div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseWeatherPopup} variant='contained'>
              閉じる
            </Button>
            <Button onClick={()=>fetchWeatherData(observationPopupContent.observationName)} variant='contained' color='primary'>
              取得
            </Button>
          </DialogActions>
        </Dialog>
        {/* 非購入AOIアラートポップアップ */}
        <PurchaseAlertPopup
          isShowPurchaseAlertPopup={isShowPurchaseAlertPopup}
          setIsShowPurchaseAlertPopup={setIsShowPurchaseAlertPopup}
        />
        {/* AOIフィルタリングモーダル */}
        <AoiFilterModal
          aoiIds={aoiIds}
          open={isFilterModal}
          filterSearchFlag={filterSearchFlag}
          filteringAois={filteringAois}
          handleClickAoiFilterModalBtn={handleClickAoiFilterModalBtn}
          handleClickFilterResetBtn={handleClickFilterResetBtn}
          handleChangeSearchFlag={handleChangeSearchFlag}
          handleClickFilterBtn={handleClickFilterBtn}
          handleChangeInputFilterAoiName={handleChangeInputFilterAoiName}
          handleChangeFilterPeriod={handleChangeFilterPeriod}
          handleChangeFilterLevel={handleChangeFilterLevel}
          aoiNameInputValue={aoiNameInputValue}
          aoiPeriodValue={aoiPeriodValue}
          aoiLevelValue={aoiLevelValue}
          filterInputFlag={filterInputFlag}
          purchaseFlag={purchaseFlag}
          handleChangeFilterPurchaseCheck={handleChangeFilterPurchaseCheck}
          projectAoiType={projectAoiType}
        />
        {/* GridAOI用フィルタリングモーダル */}
        <AoiGridFilterModal
          isGridFilterModal={isGridFilterModal}
          adjoinNum={adjoinNum}
          gridAoiShowType={gridAoiShowType}
          handleClickGridFilterModalBtn={handleClickGridFilterModalBtn}
          handleChangeGridFilterAdjon={handleChangeGridFilterAdjon}
          handleChangeGridFilterShowType={handleChangeGridFilterShowType}
          handleClickGridFilterExeBtn={handleClickGridFilterExeBtn}
          handleClickGridFilterReset={handleClickGridFilterReset}
        />
      </div>
      {/* 現在地 */}
      <div id="currentLocation">
        <Tooltip title={t("map.moveToCurrentLocation")} arrow>
          <Button
            className={classes.currentLocationButtonActive}
            variant="contained"
            onClick={handleClickNowLocation}
          >
            <MyLocationIcon />
          </Button>
        </Tooltip>
      </div>
      {/* 初期設定位置 */}
      <div id="defaultLocation">
        <Tooltip title={t("map.goToInitialLocation")} arrow>
          <Button
            className={classes.defaultLocationButtonActive}
            variant="contained"
            onClick={handleClickDefaultMove}
          >
            <PinDropIcon />
          </Button>
        </Tooltip>
      </div>
      {/* 地質図を調べる */}
      <div id="geologyCheck">
        <Tooltip title="地質図の凡例を調べる" arrow>
          <Button
            className={GeologyDataGetMode ? `${classes.geologyCheckButtonActive} ${classes.geologyCheckButtonClicked}` : classes.geologyCheckButtonActive}
            variant="contained"
            onClick={() => handleChangeGeologyDataMode(GeologyDataGetMode)}
          >
            <ColorizeIcon />
          </Button>
        </Tooltip>
      </div>
      {/* 凡例エリア */}
      <section className={isSummaryOpen ? classes.legendWithSummaryOpen : classes.legendWithSummaryClose}>
        <Legend
          outputType={outputType}
          selectedLegendMin={selectedLegendMin}
          selectedLegendMax={selectedLegendMax}
          quantitySearchDate={quantitySearchDate}
          mapStyle={mapStyle}
          usageGuideunit={usageGuideunit}
          nowAnalysisPointState={nowAnalysisPointState}
        ></Legend>
      </section>
      {/* サマリエリア */}
      <Summary
        t={t}
        currentLang={currentLang}
        projectId={projectId}
        accountGroupId={accountGroupId}
        aoiRows={aoiTableData}
        pointDatasState={pointDatasState}
        pointRows={pointRows}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        isSummaryOpen={isSummaryOpen}
        graphMaxConfig={graphMaxConfig}
        graphMinConfig={graphMinConfig}
        handleChangeInputAdminComment={handleChangeInputAdminComment}
        handleClickAdminCommentKeep={handleClickAdminCommentKeep}
        aoiClickFlag={aoiClickFlag}
        aoiTableDataGeneratFlag={aoiTableDataGeneratFlag}
        setAoiTableDataGeneratFlag={setAoiTableDataGeneratFlag}
        summaryTextData={summaryTextData}
        clickAoiId={clickAoiId}
        resetRegisterAdminComment={resetRegisterAdminComment}
        observationPointData={observationPointData}
        raineyToggleFlag={raineyToggleFlag}
        mapStyle={mapStyle}
        geologyLayerState={geologyLayerState}
        analysisVer={analysisVer}
      ></Summary>
      {/* アラート */}
      <Snackbar open={isAlertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <AlertMessageCloseable
          variant = 'filled'
          alertMessage={alertMessageMemo}
          alertSeverity={alertSeverity}
          handleCloseAlert={handleCloseAlert}
        />
      </Snackbar>

      {/* 共通スナックバー */}
      <Snackbar open={isSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} style={{ opacity: 1 }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarColor}
          variant="filled"
        >
          {weaterObservationDataFetchFlag && <ToysIcon style={{marginRight: '5px'}}/>}
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* 画面上部のプロジェクト名 */}
      <ProjectDetail pjName={pjName}></ProjectDetail>

      {/* ローディングアニメーション */}
      {addLoadingMemo &&
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      }
      {upDateLoadingMemo &&
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      }
      {(mapLoading || weatherLoadingFlag) &&
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      }
      {reFetchAoiLoading &&
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      }
    </>
  );
}
