import { useState } from 'react';

// 共通スナックバーの管理
export const useCommonSnackbar = () => {
  const [snackbarColor, setSnackbarColor] = useState('warning'); // スナックバーの色の状態の管理
  const [isSnackbar, setIsSnackbar] = useState(false) // スナックバーの表示状態を管理
  const [snackbarMessage, setSnackbarMessage] = useState(''); // スナックバーに表示するメッセージを格納
  
  /** アラートを閉じたとき */
  const handleCloseSnackbar = () => {
    setIsSnackbar(false);
  };

  /**
   * snackBarを表示する処理
   * @param {String} errorMessage snackbarに表示する文字列
   * @param {string} color snackbarの種類(error or warning or info or success)
   */
  const isShowSnakbar = (errorMessage, color) => {
    setIsSnackbar(true);
    setSnackbarMessage(errorMessage);
    setSnackbarColor(color);
  }

  return{
    isSnackbar,
    snackbarMessage,
    snackbarColor,
    handleCloseSnackbar,
    isShowSnakbar,
  }
}