import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  list: {
    textAlign: "center",
  },
  form: {
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "0",
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: "9999999",
  },
}));

export default function Loading(props) {
  const {
    type, // list or form
  } = props;
  const classes = useStyles();

  if (type === 'list') { // 一覧の場合
    return (
      <div className={classes.list}>
        <CircularProgress />
      </div>
    );
  } else if(type === 'form') { // フォームの場合
    return (
      <div className={classes.form}>
        <CircularProgress />
      </div>
    );
  }

}
