import React from 'react';
import Alert from '@material-ui/lab/Alert';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  highlightOffSharpIcon: {
    fontSize: '1.2rem',
    margin: '0 0 0 10px',
    position: 'absolute',
    right: '10px',
    top: '14px',
  },
}));

export default function AlertMessageCloseable(props) {
  const {
    variant = 'standard',
    alertMessage,
    alertSeverity,
    handleCloseAlert,
  } = props;

  const classes = useStyles();

  return (
    <Alert variant={variant} onClick={handleCloseAlert} severity={alertSeverity}>
      {alertMessage}
      <HighlightOffSharpIcon className={classes.highlightOffSharpIcon} />
    </Alert>
  );
}
