import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';

export default function PurchaseAlertPopup(props){
  const {isShowPurchaseAlertPopup, setIsShowPurchaseAlertPopup} = props;

  //ダッシュボードにリダイレクト
  const history = useHistory();
  const location = useLocation();
  const handleButtonClick = () => {
    if(location.pathname === '/'){
      window.location.reload(); // ダッシュボードページでのエラーは画面リロード
    }else{
      history.push('/'); // ダッシュボードページ以外ではダッシュボードページにリダイレクト
    }
    setIsShowPurchaseAlertPopup(false);
  }

  return(
    <Dialog maxWidth='xs' open={isShowPurchaseAlertPopup}>
    <DialogContent>
      <Typography style={{textAlign: 'center'}}>
        解析対象範囲のデータ取得に失敗しました。<br/>管理者にお問い合わせ下さい。
      </Typography>
    </DialogContent>
    <DialogActions style={{margin: '5px auto 0 auto'}}>
      <Button onClick={handleButtonClick} variant='contained' color='primary'>
        閉じる
      </Button>
    </DialogActions>
  </Dialog>
  )
}