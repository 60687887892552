import { useState, useRef, useEffect } from 'react';

//ドロワーメニューの解析対象範囲名検索のロジック
export const useSeachAoiName = (handleChangeAoiListReset, aoisData, setAoisListData, setSortAoiDataIdList, setSortResetAoiDataIdList) =>{
  //解析対象範囲名検索機能
  const inputSeachAoiNameRef = useRef(); // AOI名検索窓で入力された値を格納
  const [inputSeachAoiName, setInputSeachAoiName] = useState(); //検索ボタン押下時に検索窓に入力されていた値を格納

  //検索窓で入力された文字列を変数に格納する処理
  const handleChangeInputSeachAoiName = (value) => {
    inputSeachAoiNameRef.current = value
  }

  //検索ボタン押下時に検索窓に入力されていた値をstateに格納
  const handleClickSeachAoiName = () => {
    setInputSeachAoiName(inputSeachAoiNameRef.current)
  }

  //リセットボタン押下時の処理
  const handleClickSortAoiNameReset = () => {
    setInputSeachAoiName(); // リセット時は検索窓に入力された値を初期化する

    //AOI名検索窓の入力値を初期状態にする
    let Myelement = document.getElementById('aoiNameInputBox');
    Myelement.value = '';

    let sortDatas = [];
    aoisData.forEach(value => {
      let sortData = [];
      let aoiNameValue = {};
      let aoiName = {};
      let rankAllAve = {};
      let rankAllMax = {};
      let rankRecAve = {};
      let rankRecMax = {};
      aoiName[value.properties.aoiName] = false;
      aoiNameValue['aoiNameValue'] = value.properties.aoiName;
      rankAllAve['rankAllAve'] = value.properties.rankAllAve;
      rankAllMax["rankAllMax"] = value.properties.rankAllMax;
      rankRecAve["rankRecAve"] = value.properties.rankRecAve;
      rankRecMax["rankRecMax"] = value.properties.rankRecMax;
      sortData.push(aoiName, aoiNameValue, rankAllAve, rankAllMax, rankRecAve, rankRecMax);
      sortDatas.push(sortData);
    })
    setAoisListData(sortDatas);
    setSortResetAoiDataIdList(true);
  }

  //検索ボタン押下時の処理
  useEffect(() =>{
    if(!inputSeachAoiName) return; // 未入力の場合は以下を実行しない
    handleChangeAoiListReset(); // 絞り込み検索はリセットする

    // 絞り込んだAOI一覧データを作成
    let sortDatas = [];
    aoisData.forEach(value => {
      //各データ名を定義
      let sortData = [];
      let aoiNameValue = {};
      let aoiName = {};
      let rankAllAve = {};
      let rankAllMax = {};
      let rankRecAve = {};
      let rankRecMax = {};

      //絞り込み検索条件に合致するAOIのみの配列を作成
      if(value.properties.aoiName.includes(inputSeachAoiName)){
        aoiName[value.properties.aoiName] = false;
        aoiNameValue['aoiNameValue'] = value.properties.aoiName;
        rankAllAve['rankAllAve'] = value.properties.rankAllAve;
        rankAllMax["rankAllMax"] = value.properties.rankAllMax;
        rankRecAve["rankRecAve"] = value.properties.rankRecAve;
        rankRecMax["rankRecMax"] = value.properties.rankRecMax;
        sortData.push(aoiName, aoiNameValue, rankAllAve, rankAllMax, rankRecAve, rankRecMax);
        sortDatas.push(sortData);
      }
    })
    setAoisListData(sortDatas); // ドロワーメニューAOI一覧
    setSortResetAoiDataIdList(false);

    //入力された文字列とAOI名が部分一致したAOIのidを配列に格納
    let textSortAoiId = [];
    aoisData.forEach(value => {
      if(value.properties.aoiName.includes(inputSeachAoiName)){
        textSortAoiId.push(value.id);
      }
    })
    setSortAoiDataIdList(textSortAoiId);
  }, [inputSeachAoiName])

  return{
    handleChangeInputSeachAoiName,
    handleClickSeachAoiName,
    handleClickSortAoiNameReset,
  }
}