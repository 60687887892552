import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LaunchIcon from '@material-ui/icons/Launch';
import ListItem from '@material-ui/core/ListItem';
import GridOnIcon from '@material-ui/icons/GridOn';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExploreIcon from '@material-ui/icons/Explore';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Switch from '@material-ui/core/Switch';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import 'css/Drawer.scss';
import { S3_URL } from "utils/constants";
import Slider from '@material-ui/core/Slider';
import NativeSelect from '@material-ui/core/NativeSelect';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { getAoiNameFromAoiId } from 'utils/_function';

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    position: 'fixed',
    top: '.5em',
    left: '1em',
    zIndex: '100',
    padding: '10px 13px',
    minWidth: '0',
  },
  menuCloseButton: {
    padding: '10px 13px',
    minWidth: '0',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  formControl: {
    display: 'block !important',
    padding: '0 20px 0 0',
  },
  formControlAoISortArea: {
    display: 'block !important',
    margin: '0 auto 15px auto',
    width: '80%',
  },
  formControlRankSortArea: {
    display: 'block !important',
    margin: '0 auto 15px auto',
  },
  formControlHarfSize: {
    display: 'block !important',
    width: '40%'
  },
  formControlModeSelectArea: {
    display: 'block !important',
    margin: '0 auto',
  },
  Form_label: {
    marginRight: '0 !important',
    marginLeft: '0 !important',
  },
  veiw_icon_button: {
    height: '2rem',
    width: '2rem',
    minWidth: '0 !important',
  },
  ZoomInIcon: {
    fontSize: '1.3rem !important',
  },
  slidersOuter: {
    margin: '10px 0 0',
    width: '100%',
  },
  slidersOuterFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0 0',
    width: '100%',
  },
  sliderOuter: {
    margin: '25px auto 0',
    width: '90%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  sliderTitle: {
    fontSize: '0.9rem',
  },
  sliderTitleHarfSize: {
    fontSize: '0.9rem',
    width: '58%'
  },
  alertFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sliderAoiAlertTitle: {
    fontSize: '0.9rem',
  },
  alertSelectName: {
    padding: '0 16px',
  },
  logoImg: {
    width: '93%',
    margin: '5px auto 0',
    '&:hover': {
      opacity: '0.8',
    },
  },
  heading: {
    padding: '2px 0 5px 5px',
  },
  homeLink: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
    padding: '5px 0 5px 7px',
    textDecoration: 'none',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  homeIcon: {
    bottom: '-6px',
    margin: '0 5px 0 0',
    position: 'relative',
  },
  accordion: {
    width: '100%',
  },
  selectDate: {
    borderBottom: '1px solid #004ea2',
  },
  selectDateMode: {
    width: '100%',
    marginTop: '10px !important',
    borderBottom: '1px solid #004ea2',
  },
  selectDateModeHarfSize: {
    width: '100%',
    borderBottom: '1px solid #004ea2',
  },
  selectErrorBorder:{
    borderBottom: '1px solid #f44336'
  },
  selectDateArea: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '5px 10px',
  },
  positionRelative: {
    position: 'relative',
  },
  dateFormHelperText: {
    marginTop: '7px',
  },
  customTooltip: {
    background: 'rgb(158, 204, 255)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
    top: '5px',
  },
  toolTip_custom: {
    top: '-5px !important',
    right: '10px !important'
  },
  customArrow: {
    color: 'rgb(158, 204, 255)',
  },
  HelpTwoToneIcon: {
    fontSize: "1rem",
    height: "auto",
  },
  helpButton: {
    cursor: "pointer",
    minWidth: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  helpAreaInner: {
    padding: "5px",
    position: "relative",
  },
  guideButton: {
    fontSize: '14px',
  },
  numberBoxArea: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  numberInputBox: {
    border: '1px solid #dfdfdf',
    padding: '3px 5px 0px 5px',
    borderRadius: '5px',
    width: '40%',
  },
  graphBox: {
    marginTop: '25px',
    paddingTop: '25px',
    borderTop: '1px solid #dfdfdf'
  },
  graphConfigBtnGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  graphConfigBtn:{
    paddingBottom: '2px',
  },
  rankSelectBtnGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  weatherPointName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    paddingTop: '4px'
  }
}));

const valuetext = (value) => {
  return value;
};

export default function PersistentDrawerLeft(props) {
  const {
    t,
    aoisData,
    isDrawerOpen,
    handleDrawerOpen,
    handleDrawerClose,
    outputType, // quantity(変位) or velocity(変動速度)
    handleChangeOutputType,
    mapStyle, // light or dark or outdoors or street or satellite
    handleChangeMapStyle,
    mapLayerState, // {地図ID: boolean, ...}
    handleChangeMapLayerCheckbox,
    objectLayerState, // {オブジェクトID: boolean, ...}
    handleChangeObjectLayerCheckbox,
    aoisState,
    aoisListData,
    handleChangeAoisState,
    // isAllAoisChecked, // AOIチェック欄でALLがチェックされているか
    handleClickAoiZoomButton,
    handleChangeQuantityDateSelectBox,
    selectedSelectBoxStartDate,
    selectedSelectBoxEndDate,
    quantityDates, // 変位の開始日、終了日の候補
    legendMin,
    legendMax,
    handleChangeSliderBar,
    selectedLegendMin,
    handleClickGraphMinMaxConfigButton,
    handleClickGraphMinMaxResetButton,
    handleChangeMaxValue,
    handleChangeMinValue,
    graphMaxValue,
    graphMinValue,
    selectRankPeriod,
    selectRankLevel,
    handleChangeSelectRankPeriod,
    handleChangeSelectRankLevel,
    selectRankPeriodErrorFlag,
    selectRankLevelErrorFlag,
    handleChangeAoiListSort,
    handleChangeAoiListReset,
    handleChangeSelectInsideColor,
    selectInsideColor,
    handleClickAoiInsideColorChange,
    memoItems,
    handleClickMemoZoom,
    handleChangeInputSeachAoiName,
    handleClickSeachAoiName,
    handleClickSortAoiNameReset,
    longitudeCenter,
    latitudeCenter,
    handleClickConfigLatLng,
    configUserLatLngDatas,
    handleClickAddResetUserLatLng,
    handleChangeAoisStateOnOffBtn,
    geologyLayerState,
    handleChangegeologyLayerState,
    geologyMapOpacity,
    handleChangeGeologyMapOpacity,
    AnalyticeModeType,
    isSlider,
    handleChangeAnalyticeMode,
    usageGuideunit,
    ModeTypes,
    handleInputChange,
    handleBlur,
    qmaxValue,
    weaterToggleFlag,
    weatherToggleChecked,
    showWeaterItem,
    raineyToggleFlag,
    raineyToggleChecked,
    weatherLoadingName,
    // handleClickAoiFilterModalBtn,
    filterExecuteFlag,
    filteringAois,
    filterInputFlag,
    handleClickGridFilterModalBtn,
    projectAoiType,
    handleChangeAnalysisPointSize,
    analysisPointSize,
    handleChangeAnalysisPointState,
    analysisStateChangeButtonState,
  } = props;

  const aoisDataRef = useRef();
  aoisDataRef.current = aoisData; // aoisDataの最新の状態を参照する

  const [aoiNameList, setAoiNameLists] = useState({}); // AOI一覧に表示するAOIデータ

  // AOI一覧用のデータを作成
  const createAoisListData = (aoisListData) => {
    let aoiNameLists = {};
    aoisListData.forEach(value => {
      aoiNameLists[Object.keys(value[0]).toString()] = false;
    })
    return aoiNameLists;
  }

  // AOI一覧のデフォルトの値を定義する処理
  useEffect(() => {
    if(!aoisListData) return

    const aoiNameLists = createAoisListData(aoisListData);
    setAoiNameLists(aoiNameLists);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[aoisListData])

  // AOIフィルタリングの実行時の結果をAOI一覧に反映させる
  useEffect(() => {
    if(!filterExecuteFlag) return;

    // フィルタリングの入力項目が全て空の場合はリセットする
    if(!filterInputFlag) {
      const aoiNameLists = createAoisListData(aoisListData);
      setAoiNameLists(aoiNameLists);
      return;
    }

    // AOI一覧に反映
    let aoiNameLists = {};
    filteringAois.forEach((value) => {
      let AoiName = getAoiNameFromAoiId(value, aoisDataRef.current);
      aoiNameLists[AoiName] = false;
    })
    setAoiNameLists(aoiNameLists);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterExecuteFlag])

  // 5刻みでmarksを生成する関数
  const generateMarks = (maxValue) => {
    const marks = [];
    for (let i = 0; i <= maxValue; i += 5) {
      //0及び最大値のときはメモリ(文字)を表示する
      if (i === 0 || i === maxValue) {
        marks.push({ value: i, label: `${i}` });
      } else {
        marks.push({ value: i, label: '' });
      }
    }
  
    return marks;
  };
  // スライダーのマークを定義
  const groundMarks = generateMarks(qmaxValue);

  //解析対象範囲の絞り込み検索(基準にする期間)
  const projectColumns = [
    {selectNone: t("map.pleaseSelect")},
    {rankAllAve: t("dashboard.wholePeriodAverage")},
    {rankAllMax: t("dashboard.wholePeriodMax")},
    {rankRecAve: t("dashboard.twoPeriodsAverage")},
    {rankRecMax: t("dashboard.twoPeriodsMax")},
  ];

  //解析対象範囲の絞り込み検索(変動ランク)
  const projectRankName = [
    {selectNone: t("map.pleaseSelect")},
    {1: t("map.variationA´´")},
    {2: t("map.variationA´")},
    {3: t("map.variationA")},
    {4: t("map.variationB")},
    {5: t("map.variationC")},
  ];

  //変動ランクの表示設定
  const aoiLayerColorColumn =[
    {rankAllAve: t("dashboard.wholePeriodAverage")},
    {rankAllMax: t("dashboard.wholePeriodMax")},
    {rankRecAve: t("dashboard.twoPeriodsAverage")},
    {rankRecMax: t("dashboard.twoPeriodsMax")},
  ];

  //解析点の大きさの設定
  const analysisPointSizeOptions = [
    { value: 1.53, label: t("map.large") },
    { value: 1.81, label: t("map.medium") },
    { value: 2.22, label: t("map.small") }
  ];

  const classes = useStyles();
  const theme = useTheme();

  //　各アコーディオンを開いた際は他のアコーディオンは強制的に閉じる処理
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // 日付をYYMMDDで出力する
  const getDateYMD = (date) => {
    var dt = new Date(date);
    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth() + 1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);
    var result = y + '/' + m + '/' + d;
    return result;
  };

  // 操作説明書PDFを表示する
  const OpenGuidePdf = () => window.open(`${S3_URL}document/liana_userguide.pdf`, '_blank');

  return (
    <>
      {/* ドロワーメニューを表示するボタン */}
      <Button
        color="primary"
        variant="contained"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, isDrawerOpen && classes.hide)}
      >
        <MenuIcon />
      </Button>
      {/* ドロワーメニュー */}
      <Drawer
        id="sideBar"
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Link to='/'>
          <img
            className={classes.logoImg}
            src={`${S3_URL}img/logo.png`}
            alt="スカパーJSAT NIPPON KOEI ZENRIN"
          />
        </Link>
        
        <div className={classes.drawerHeader}>
          <Link className={classes.homeLink} to="/">
            <HomeIcon className={classes.homeIcon} />
            {t("map.dashboard")}
          </Link>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List className="aoiArea rankSort">
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <RoomTwoToneIcon />
              <Typography className={classes.heading}>{t("map.scopeOfAnalysis")}</Typography>
            </AccordionSummary>
            <div
              style={{width: '90%', margin: '0 auto'}}
            >
              <Typography
                className={classes.sliderTitle + " " + classes.positionRelative}
                style={{textAlign: 'left',marginBottom: '10px'}}
              >
                {t("map.displaySettingOfFluctuationRanks")}
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow
                  }}
                  title={t("map.dsofrMessage")}
                  placement="top"
                  arrow
                >
                  <Button className={classes.helpButton}>
                    <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                  </Button>
                </Tooltip>
              </Typography>
              <div>
                <FormControl className={classes.formControlRankSortArea}>
                  <div>
                    <NativeSelect
                      className={classes.selectDate}
                      value={selectInsideColor}
                      onChange={handleChangeSelectInsideColor}
                    >
                      {aoiLayerColorColumn.map(value => (
                        <option
                          value={Object.keys(value)}
                          key={Object.keys(value)}
                          >
                          {Object.values(value)}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </FormControl>
              </div>
              <div className={classes.rankSelectBtnGroup}>
                <Button
                  size="small"
                  className={classes.graphConfigBtn}
                  style={{marginRight: '10px', width: '80%', padding: '6px 16px'}}
                  color="primary"
                  variant="contained"
                  onClick={handleClickAoiInsideColorChange}
                >
                  {t("map.setting")}
                </Button>
              </div>
            </div>
            <div
               className={classes.graphBox}
               style={{width: '90%', margin: '25px auto 0 auto'}}
             >
               <Typography
                 className={classes.sliderTitle + " " + classes.positionRelative}
                 style={{textAlign: 'left',marginBottom: '10px'}}
               >
                 {t("map.refineSearch")}
                 <Tooltip
                   classes={{
                     tooltip: classes.customTooltip,
                     arrow: classes.customArrow
                   }}
                   title={t("map.periodFluctuationRanksSearchMessage")}
                   placement="top"
                   arrow
                 >
                   <Button className={classes.helpButton}>
                     <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                   </Button>
                 </Tooltip>
               </Typography>
               <div>
                 <FormControl className={classes.formControlRankSortArea} error={selectRankPeriodErrorFlag}>
                   <div>
                     <FormHelperText className={classes.dateFormHelperText}>
                      {t("map.referencePeriod")}
                     </FormHelperText>
                     <NativeSelect
                       className={classes.selectDate + " " + (selectRankPeriodErrorFlag ? classes.selectErrorBorder : '')}
                       value={selectRankPeriod}
                       onChange={handleChangeSelectRankPeriod}
                     >
                       {projectColumns.map(value => (
                         <option
                           value={Object.keys(value)}
                           key={Object.keys(value)}
                           >
                           {Object.values(value)}
                         </option>
                       ))}
                     </NativeSelect>
                     <FormHelperText style={{display: selectRankPeriodErrorFlag ? 'block' : 'none'}}>{t("map.pleaseSelectPeriod")}</FormHelperText>
                   </div>
                 </FormControl>
               </div>
               <div>
                 <FormControl className={classes.formControlRankSortArea} error={selectRankLevelErrorFlag}>
                   <div>
                     <FormHelperText className={classes.dateFormHelperText}>
                      {t("map.variationRank")}
                     </FormHelperText>
                     <NativeSelect
                       className={classes.selectDate + " " + (selectRankLevelErrorFlag ? classes.selectErrorBorder : '')}
                       value={selectRankLevel}
                       onChange={handleChangeSelectRankLevel}
                     >
                       {projectRankName.map(value => (
                         <option
                           value={Object.keys(value)}
                           key={Object.keys(value)}
                           >
                           {Object.values(value)}
                         </option>
                       ))}
                     </NativeSelect>
                     <FormHelperText style={{display: selectRankLevelErrorFlag ? 'block' : 'none'}}>{t("map.pleaseSelectPeriod")}</FormHelperText>
                   </div>
                 </FormControl>
               </div>
               <div className={classes.rankSelectBtnGroup}>
                 <Button
                   size="small"
                   className={classes.graphConfigBtn}
                   style={{marginRight: '10px'}}
                   color="primary"
                   variant="contained"
                   onClick={handleChangeAoiListSort}
                 >
                   {t("map.search")}
                 </Button>
                 <Button
                   size="small"
                   variant="contained"
                   className={classes.graphConfigBtn}
                   onClick={handleChangeAoiListReset}
                 >
                   {t("map.reset")}
                 </Button>
               </div>
             </div>
            <AccordionDetails>
              <FormControl component="fieldset" style={{width:'100%'}}>
                <FormGroup>
                  {/* <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControlLabel
                      value="start"
                      labelPlacement="start"
                      className={classes.Form_label}
                      label="全ての対象範囲"
                      control={
                        <Switch
                          onChange={handleChangeAoisState}
                          checked={isAllAoisChecked}
                          color="primary"
                          name="ALL"
                          value="ALL"
                        />
                      }
                    />
                  </Box> */}
                  <div
                    className={classes.graphBox}
                    style={{width: '100%', margin: '5px auto 10px auto'}}
                  >
                    <Typography
                      className={classes.sliderTitle + " " + classes.positionRelative}
                      style={{textAlign: 'left',marginBottom: '10px'}}
                    >
                      {t("map.listOfRangeNames")}
                      <Tooltip
                        classes={{
                          tooltip: classes.customTooltip,
                          arrow: classes.customArrow
                        }}
                        title="フィルタリングで絞り込むことができます。"
                        placement="top"
                        arrow
                      >
                        <Button className={classes.helpButton}>
                          <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                        </Button>
                      </Tooltip>
                    </Typography>
                    <div
                      style={{width: '100%', margin: '0px auto 20px auto'}}
                    >
                      <div className='analysisNameBox'>
                        <FormControl className='control'>
                          <div
                            className='boxArea'
                          >
                            <TextField
                              id="aoiNameInputBox"
                              className='inputArea'
                              placeholder={t("map.searchRangeinput")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={ e => {
                                handleChangeInputSeachAoiName(e.target.value)
                              }}
                            />
                            <div className={classes.rankSelectBtnGroup}>
                              <Button
                                size="small"
                                className={classes.graphConfigBtn}
                                style={{marginRight: '10px'}}
                                color="primary"
                                variant="contained"
                                onClick={handleClickSeachAoiName}
                              >
                                {t("map.search")}
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                className={classes.graphConfigBtn}
                                onClick={handleClickSortAoiNameReset}
                              >
                                {t("map.reset")}
                              </Button>
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    </div>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingBottom="10px"
                    >
                      <div style={{fontSize: '0.9rem'}}>{t("map.allTargetRangesOn")}</div>
                      <div>
                      <Button
                        size="small"
                        className={classes.graphConfigBtn}
                        style={{minWidth: '55px', marginRight: '10px'}}
                        variant="contained"
                        color="primary"
                        onClick={() => handleChangeAoisStateOnOffBtn(true)}
                      >
                        {t("map.on")}
                      </Button>
                      <Button
                        size="small"
                        className={classes.graphConfigBtn}
                        style={{minWidth: '55px'}}
                        variant="contained"
                        onClick={() => handleChangeAoisStateOnOffBtn(false)}
                      >
                        {t("map.off")}
                      </Button>
                      </div>
                    </Box>
                    { projectAoiType === 'Grid' &&
                      <div className='analysisNameBox'>
                        <FormControl className='control'>
                          <div className='boxArea'>
                            <div className={classes.rankSelectBtnGroup}>
                              <Button
                                size="small"
                                className={classes.graphConfigBtn}
                                style={{width: '80%', padding: '8px 16px 4px 16px'}}
                                color="primary"
                                variant="contained"
                                onClick={() => handleClickGridFilterModalBtn(true)}
                              >
                                {t("map.selectScope")}
                              </Button>
                            </div>
                          </div>
                        </FormControl>
                      </div>
                    }
                  </div>
                  {!Object.keys(aoiNameList).length && t("map.noCorrespondingData")}
                  {Object.keys(aoiNameList).map((aoiName) => (
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      key={aoiName}
                    >
                      <FormControlLabel
                        value="start"
                        labelPlacement="start"
                        label={aoiName}
                        id={aoiName.replace(/\s+/g, "")}
                        className={classes.Form_label}
                        control={
                          <Switch
                            onChange={handleChangeAoisState}
                            checked={aoisState[aoiName]}
                            color="primary"
                            name={aoiName}
                            value={aoiName}
                          />
                        }
                      />
                      <div className={classes.veiw_icon_area}>
                        <Button
                          className={classes.veiw_icon_button}
                          onClick={() => handleClickAoiZoomButton(aoiName)}
                        >
                          <ZoomInIcon
                            className={classes.ZoomInIcon}
                          ></ZoomInIcon>
                        </Button>
                      </div>
                    </Box>
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </List>

        <List>
          <ListItem>
            <Accordion className={classes.accordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel0-content"
                id="panel0-header"
              >
                <DragIndicatorIcon />
                <Typography className={classes.heading}>
                  {t("map.legendAndGraphSettings")}
                </Typography>
              </AccordionSummary>
              <div style={{ padding: "0px 16px 16px" }}>
                <RadioGroup
                  aria-label="output type"
                  name="output_type"
                  value={outputType}
                  onChange={handleChangeOutputType}
                  className={classes.positionRelative}
                >
                  <Box display="flex" id="helpQuantityAndVelocityArea">
                    <FormControlLabel
                      value="velocity"
                      control={<Radio color="primary" />}
                      label={t("map.rateOfVariation")}
                    />
                    <FormControlLabel
                      value="quantity"
                      control={<Radio color="primary" />}
                      label={t("map.periodDisplacement")}
                      disabled={AnalyticeModeType === "ground" ? false : true}
                    />
                  </Box>
                  <Tooltip
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow
                    }}
                    title={t("map.lagsMessage")}
                    placement="top"
                    arrow
                  >
                    <Button className={classes.helpButton}>
                      <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                    </Button>
                  </Tooltip>
                </RadioGroup>
                <div>
                  <FormControl className={classes.formControl}>
                    <div
                      className={
                        classes.selectDateArea + " selectDateArea_" + outputType
                      }
                    >
                      <FormHelperText className={classes.dateFormHelperText}>
                        {t("map.startDate")}
                      </FormHelperText>
                      <NativeSelect
                        value={
                          selectedSelectBoxStartDate &&
                          selectedSelectBoxStartDate
                        }
                        onChange={(value) =>
                          handleChangeQuantityDateSelectBox(value, "start")
                        }
                        name="startDate"
                        className={classes.selectDate}
                        disabled={outputType === "quantity" ? false : true}
                      >
                        {quantityDates &&
                          quantityDates.map(
                            (date) =>
                              new Date(selectedSelectBoxEndDate).getTime() >=
                                new Date(date).getTime() && (
                                <option
                                  key={getDateYMD(date)}
                                  value={getDateYMD(date)}
                                >
                                  {getDateYMD(date)}
                                </option>
                              )
                          )}
                      </NativeSelect>
                    </div>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <div
                      className={
                        classes.selectDateArea + " selectDateArea_" + outputType
                      }
                    >
                      <FormHelperText className={classes.dateFormHelperText}>
                        {t("map.endDate")}
                      </FormHelperText>
                      <NativeSelect
                        value={
                          selectedSelectBoxEndDate && selectedSelectBoxEndDate
                        }
                        onChange={(value) =>
                          handleChangeQuantityDateSelectBox(value, "end")
                        }
                        name="endDate"
                        className={classes.selectDate}
                        disabled={outputType === "quantity" ? false : true}
                      >
                        {quantityDates &&
                          quantityDates.map(
                            (date) =>
                              new Date(selectedSelectBoxStartDate).getTime() <=
                                new Date(date).getTime() && (
                                <option
                                  key={getDateYMD(date)}
                                  value={getDateYMD(date)}
                                >
                                  {getDateYMD(date)}
                                </option>
                              )
                          )}
                      </NativeSelect>
                    </div>
                  </FormControl>
                </div>

                <div
                  className={classes.slidersOuter}
                >
                  <Typography
                    className={classes.sliderTitle + " " + classes.positionRelative}
                    id="points-color-legend"
                    gutterBottom
                  >
                    { outputType === 'velocity' ? `${t("map.legend")}(mm/${usageGuideunit})`: `${t("map.legend")}(mm)`}
                    <Tooltip
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow
                      }}
                      title={t("map.dgsMessage")}
                      placement="top"
                      arrow
                    >
                      <Button className={classes.helpButton}>
                        <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                      </Button>
                    </Tooltip>
                  </Typography>
                  <div
                   className={classes.sliderOuter}
                  >
                    <Slider
                      value={legendMax}
                      getAriaValueText={valuetext}
                      aria-labelledby="points-color-legend"
                      step={5}
                      marks={groundMarks}
                      min={0}
                      max={qmaxValue}
                      disabled={!isSlider}
                      valueLabelDisplay="on"
                      onChange={(event, value) =>
                        handleChangeSliderBar(event, "changeMinMax", value)
                      }
                    />
                    <Input
                      className='inputBox'
                      value={legendMax}
                      onChange={handleInputChange}
                      disabled={!isSlider}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 0,
                        max: qmaxValue,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </div>
                </div>
                <div className={classes.slidersOuterFlex} style={{marginTop: '15px'}}>
                  <Typography
                    className={classes.sliderTitleHarfSize + " " + classes.positionRelative}
                    id="points-color-legend"
                  >
                    {t("map.displayMode")}
                    <Tooltip
                      className={classes.toolTip_custom}
                      title={t("map.displayModeMessage")}
                      placement="top"
                      arrow
                    >
                      <Button className={classes.helpButton}>
                        <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                      </Button>
                    </Tooltip>
                  </Typography>
                  <>
                    <FormControl className={classes.formControlHarfSize}>
                      <NativeSelect
                        className={classes.selectDateModeHarfSize}
                        value={AnalyticeModeType}
                        onChange={handleChangeAnalyticeMode}
                      >
                        {ModeTypes.map(value => (
                          <option
                            value={Object.keys(value)}
                            key={Object.keys(value)}
                            >
                            {Object.values(value)}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </>
                </div>
                <div
                  className={classes.slidersOuterFlex}
                  style={{marginTop: '20px', marginBottom: '15px'}}
                >
                  <Typography
                    className={classes.sliderTitleHarfSize + " " + classes.positionRelative}
                    id="points-color-legend"
                  >
                    {t("map.analysisPointSizeConfig")}
                    <Tooltip
                      className={classes.toolTip_custom}
                      title={t("map.analysisPointSizeConfigMess")}
                      placement="top"
                      arrow
                    >
                      <Button className={classes.helpButton}>
                        <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                      </Button>
                    </Tooltip>
                  </Typography>
                  <>
                    <FormControl className={classes.formControlHarfSize}>
                      <NativeSelect
                        className={classes.selectDateModeHarfSize}
                        value={analysisPointSize}
                        onChange={handleChangeAnalysisPointSize}
                      >
                        {analysisPointSizeOptions.map(({ value, label }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </>
                </div>
                <Box display="flex" justifyContent="center">
                  <Button
                    style={{ width: "80%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleChangeAnalysisPointState({
                        size: analysisPointSize,
                        type: AnalyticeModeType,
                        legendMin: legendMin,
                        legendMax: legendMax,
                      })
                    }}
                    disabled={analysisStateChangeButtonState}
                  >
                    {t("map.reflection")}
                  </Button>
                </Box>
                <div className={classes.slidersOuter + " " + classes.graphBox}>
                  <Typography
                    className={classes.sliderTitle + " " + classes.positionRelative}
                    id="points-color-legend"
                    gutterBottom
                  >
                    {t("map.displacementGraphSettings")}
                    <Tooltip
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow
                      }}
                      title={t("map.dgsMessage")}
                      placement="top"
                      arrow
                    >
                      <Button className={classes.helpButton}>
                        <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                      </Button>
                    </Tooltip>
                  </Typography>
                  <div className='flex_common'>
                    <div>
                      <FormControl>
                        <div
                          className={classes.numberBoxArea}
                        >
                          <FormHelperText className={classes.dateFormHelperText}>
                            {t("map.maximumValue")}：
                          </FormHelperText>
                          <TextField
                            value={graphMaxValue}
                            id="standard-number"
                            type="number"
                            className={classes.numberInputBox}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={ e => {
                              handleChangeMaxValue(e.target.value)
                            }}
                          />
                        </div>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl>
                        <div
                          className={classes.numberBoxArea}
                        >
                          <FormHelperText className={classes.dateFormHelperText}>
                            {t("map.minimumValue")}：
                          </FormHelperText>
                          <TextField
                            value={graphMinValue}
                            id="standard-number"
                            type="number"
                            className={classes.numberInputBox}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={ e => {
                              handleChangeMinValue(e.target.value)
                            }}
                          />
                        </div>
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.graphConfigBtnGroup}>
                    <Button
                      size="small"
                      className={classes.graphConfigBtn}
                      style={{marginRight: '10px'}}
                      color="primary"
                      variant="contained"
                      onClick={handleClickGraphMinMaxConfigButton}
                    >
                      {t("map.setting")}
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      className={classes.graphConfigBtn}
                      onClick={handleClickGraphMinMaxResetButton}
                    >
                      {t("map.reset")}
                    </Button>
                  </div>
                </div>
              </div>
            </Accordion>
          </ListItem>
        </List>

        <List>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              <MapRoundedIcon />
              <Typography className={classes.heading}>{t("map.backgroundMap")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="map style"
                  name="map_style"
                  value={mapStyle}
                  onChange={handleChangeMapStyle}
                >
                  {/* <FormControlLabel
                    value="zenrin"
                    control={<Radio color="primary" />}
                    label={t("map.zenrin")}
                  /> */}
                  <FormControlLabel
                    value="znet"
                    control={<Radio color="primary" />}
                    label={t("map.zenrin")}
                  />
                  <FormControlLabel
                    value="mapbox://styles/mapbox/dark-v10"
                    control={<Radio color="primary" />}
                    label={t("map.dark")}
                  />
                  <FormControlLabel
                    value="mapbox://styles/mapbox/satellite-v9"
                    control={<Radio color="primary" />}
                    label={t("map.satellite")}
                  />
                  <FormControlLabel
                    value="gsi_map"
                    control={<Radio color="primary" />}
                    label={t("map.geographicalSurveyInstituteStandardMap")}
                  />
                  <FormControlLabel
                    value="gsi_img"
                    control={<Radio color="primary" />}
                    label={t("map.geographicalSurveyInstituteAerialImage")}
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </List>

        <List className="rasterArea">
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <GridOnIcon />
              <Typography className={classes.heading}>{t("map.genericLayer")}</Typography>
            </AccordionSummary>
            <AccordionDetails className='generalRasterArea'>
              <div className='generalRasterList'>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      label={t("map.geologicMap")}
                      control={
                        <Checkbox
                          checked={geologyLayerState}
                          onChange={handleChangegeologyLayerState}
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className='subtxt'>{t("map.transparency")}</div>
              <Slider
                value={geologyMapOpacity}
                onChange={handleChangeGeologyMapOpacity}
                aria-labelledby="continuous-slider"
                style={{paddingTop: '5px'}}
                disabled={!geologyLayerState}
                step={10}
                marks={true}
                min={0}
                max={100}
              />
            </AccordionDetails>
          </Accordion>
        </List>

        <List className="rasterArea">
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <ExtensionTwoToneIcon />
              <Typography className={classes.heading}>{t("map.uniqueLayer")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {!Object.keys(mapLayerState).length && t("map.rasterDataHasNotBeenRegistered")}
                  {Object.keys(mapLayerState).map((key) => {
                    return (
                      <FormControlLabel
                        key={key}
                        label={key}
                        control={
                          <Checkbox
                            checked={mapLayerState[key]}
                            onChange={handleChangeMapLayerCheckbox}
                            name="mapLayer"
                            color="primary"
                            value={key}
                          />
                        }
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </List>

        <List>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <LibraryAddIcon />
              <Typography className={classes.heading}>{t("map.memoList")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(objectLayerState).map((key) => {
                return (
                  <Box
                    display="flex"
                    key={key}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControlLabel
                      value="start"
                      labelPlacement="start"
                      className={classes.Form_label}
                      label={key}
                      id={key}
                      control={
                        <Switch
                          onChange={handleChangeObjectLayerCheckbox}
                          color="primary"
                          checked={objectLayerState[key]}
                          label={key}
                          value={key}
                        />
                      }
                    />
                  </Box>
                );
              })}
            </AccordionDetails>
            {!memoItems.length && t("map.noRegisteredMemos")}
            <AccordionDetails className='memoListArea'>
              {memoItems.map(value => {
                return (
                  <Box
                    className='memoListItem'
                    key={value.id}
                  >
                    <div className='memoTitleText'>
                      {value.memoTitle}
                    </div>
                    <div className='memoZoomIcon'>
                      <Button
                        className={classes.veiw_icon_button}
                        onClick={() => handleClickMemoZoom(value.latitude, value.longitude, value.memoId)}
                      >
                        <ZoomInIcon className={classes.ZoomInIcon}></ZoomInIcon>
                      </Button>
                    </div>
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </List>

        <List className="rankSort">
          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <SyncAltIcon />
              <Typography className={classes.heading}>{t("map.externalData")}</Typography>
            </AccordionSummary>
            <div
              style={{width: '90%', margin: '0 auto'}}
            >
              <div
                className={classes.sliderTitle + " " + classes.positionRelative}
                style={{textAlign: 'left'}}
              >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControlLabel
                      value="start"
                      labelPlacement="start"
                      className={classes.Form_label}
                      label={t("map.climateData")}
                      control={
                        <Switch
                          checked={weaterToggleFlag}
                          onChange={weatherToggleChecked}
                          color="primary"
                        />
                      }
                    />
                  </Box>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow
                  }}
                  title={t("map.weatherDataOnTheGraph")}
                  placement="top"
                  arrow
                >
                  <Button className={classes.helpButton}>
                    <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                  </Button>
                </Tooltip>
              </div>
              { showWeaterItem &&
                <div style={{display: 'flex', marginLeft: '10px'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>降水量</div>
                  <div>   
                    <Switch
                      checked={raineyToggleFlag}
                      onChange={raineyToggleChecked}
                      color="primary"
                    />
                  </div>
                  { weatherLoadingName && <div className={classes.weatherPointName}>観測地点:{weatherLoadingName}</div> }
                </div>
              }
            </div>
          </Accordion>
        </List>

        <List className="rasterArea">
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <ExploreIcon />
              <Typography className={classes.heading}>{t("map.setInitialDisplayPosition")}</Typography>
            </AccordionSummary>
            <AccordionDetails className='memoListArea'>
              <div style={{width: '100%', margin: '0 auto'}}>
                <Typography
                  className={classes.sliderTitle + " " + classes.positionRelative}
                  style={{textAlign: 'left',marginBottom: '10px'}}
                >
                  {t("map.currentlySettingPosition")}
                </Typography>
                { configUserLatLngDatas.flag
                  ?
                  <div className='NowPostionArea'>
                      <div className='now'>{t("map.Latitude")}：{configUserLatLngDatas.lat}</div>
                      <div className='now'>{t("map.Longitude")}：{configUserLatLngDatas.lng}</div>
                  </div>
                  :
                  <div className='NowPostionArea'>
                      <div className='now'>{t("map.Latitude")}：{latitudeCenter}</div>
                      <div className='now'>{t("map.Longitude")}：{longitudeCenter}</div>
                  </div>
                }
              </div>
            </AccordionDetails>
            <AccordionDetails className='memoListArea'>
              <div
                style={{width: '100%', margin: '0 auto'}}
                className='mapPostion'
              >
                <Typography
                  className={classes.sliderTitle + " " + classes.positionRelative}
                  style={{textAlign: 'left',marginBottom: '10px'}}
                >
                  {t("map.changeInitialDisplayPosition")}
                  <Tooltip
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow
                    }}
                    title={t("map.registerTheStartingPoint")}
                    placement="top"
                    arrow
                  >
                    <Button className={classes.helpButton}>
                      <HelpTwoToneIcon className={classes.HelpTwoToneIcon} />
                    </Button>
                  </Tooltip>
                </Typography>
                <div className='NowPostionArea'>
                  <div className='btnGroup'>
                    <div className='title'>{t("map.currentDisplayPosition")}</div>
                    <Button
                      size="small"
                      className={classes.graphConfigBtn}
                      color="primary"
                      variant="contained"
                      onClick={handleClickConfigLatLng}
                    >
                      {t("map.save")}
                    </Button>
                  </div>
                  <div className='btnGroup'>
                    <div className='title'>{t("map.resetDisplayPosition")}</div>
                    <Button
                      size="small"
                      className={classes.graphConfigBtn}
                      variant="contained"
                      onClick={handleClickAddResetUserLatLng}
                    >
                      {t("map.reset")}
                    </Button>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </List>

        <List>
          <Button
            className={classes.guideButton}
            onClick={OpenGuidePdf}
          >
            {t("map.clickHereForOperatingInstructions")}
            <LaunchIcon fontSize="small"/>
          </Button>
        </List>
      </Drawer>
    </>
  );
}
