import { useState, useEffect } from 'react';
import { postRequest } from 'utils/_function';

// 初期表示位置を保存するロジック
export const useAddConfigUserLatLng = (projectId, useId) => {
  const [addNowLatLngFlag, setAddNowLatLngFlag] = useState(false); // マップ上の現在地座標をPOSTするフラグ
  const [mapNowLatLngFlag, setMapNowLatLngFlag] = useState(false); // マップ上の緯度経度を取得するフラグ
  const [mapNowLatLngData, setMapNowLatLngData] = useState({}); // マップ上の緯度経度情報を格納
  const [mapRegsiterLatLngData, setMapRegsiterLatLngData] = useState(false) // 初期位置情報登録時のローディングフラグ
  const [regsiterMessage, setRegsiterMessage] = useState('') // snackbarに表示するメッセージを格納
  const [regsiterMessageFlag, setRegsiterMessageFlag] = useState(false) // snackbarを表示するフラグ
  const [regsiterResetFlag, setRegsiterResetFlag] = useState(false); // 保存ボタンorリセットボタンのフラグ
  const [isSuccess, setIsSuccess] = useState(false); //　登録が成功or失敗のフラグ

  // ドロワーメニューの表示位置設定の保存ボタン押下時の処理
  const handleClickConfigLatLng = () => {
    setMapNowLatLngFlag(true);
    setAddNowLatLngFlag(true);
  }

  // ドロワーメニューの表示位置設定のリセットボタン押下時の処理
  const handleClickAddResetUserLatLng = async () => {
    setMapRegsiterLatLngData(true);
    setRegsiterResetFlag(true);
    const params = {
      projectId: String(projectId),
      userId: String(useId),
      configLatitude: Number(mapNowLatLngData.lat),
      configlongitude: Number(mapNowLatLngData.lng),
      resetFlag: Boolean(false),
    }
    const response = await postRequest('IM_Lambda', '/im-adduserprojectlatlng', params);
    if (response.errorMessage) {
      setRegsiterMessage('初期表示位置のリセットに失敗しました。');
      return;
    }
    if (!response.statusCode) {
      setRegsiterMessage('初期表示位置のリセットに失敗しました。');
      return;
    }
    switch (response.statusCode) {
      case "N0000": // 正常終了
        setRegsiterMessage('初期表示位置をリセットしました。');
        setIsSuccess(true);
        break;
      case "E0280": //　リセットするレコードが見つからない
        setRegsiterMessage('初期表示位置はまだ未登録です。');
        break;
      case "E0270": //　必須項目がない
        setRegsiterMessage('初期表示位置のリセットに失敗しました。');
        break;
      case "E0260": // その他のエラー
        setRegsiterMessage('初期表示位置のリセットに失敗しました。');
        break;
      default:
        setRegsiterMessage('初期表示位置のリセットに失敗しました。');
        break;
    }
    setRegsiterMessageFlag(true);
    setMapRegsiterLatLngData(false);
  }

  // ドロワーメニューの表示位置設定の保存ボタン押下時の処理
  const addAddConfigUserLatLng = async () => {
    setMapRegsiterLatLngData(true);
    setIsSuccess(false);
    setRegsiterResetFlag(false);
    const params = {
      projectId: String(projectId),
      userId: String(useId),
      configLatitude: Number(mapNowLatLngData.lat),
      configlongitude: Number(mapNowLatLngData.lng),
      resetFlag: Boolean(true),
    }
    const response = await postRequest('IM_Lambda', '/im-adduserprojectlatlng', params);
    if (response.errorMessage) {
      setRegsiterMessage('初期表示位置の登録に失敗しました。');
      return;
    }
    if (!response.statusCode) {
      setRegsiterMessage('初期表示位置の登録に失敗しました。');
      return;
    }
    switch (response.statusCode) {
      case "N0000": // 正常終了
        if(response.logData === 'update'){
          setRegsiterMessage('初期表示位置を更新しました。');
        }else if(response.logData === 'put'){
          setRegsiterMessage('初期表示位置を登録しました。');
        }
        setIsSuccess(true);
        break;
      case "E0270": //　必須項目がない
        setRegsiterMessage('初期表示位置の登録に失敗しました。');
        break;
      case "E0260": // その他のエラー
        setRegsiterMessage('初期表示位置の登録に失敗しました。');
        break;
      default:
        setRegsiterMessage('初期表示位置の登録に失敗しました。');
        break;
    }
    setRegsiterMessageFlag(true);
    setAddNowLatLngFlag(false);
    setMapRegsiterLatLngData(false);
  }

  //　マップ上の座標データを取得したら実行
  useEffect(() => {
    if (!addNowLatLngFlag) return;
    addAddConfigUserLatLng();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapNowLatLngData])

  return{
    mapNowLatLngData,
    mapNowLatLngFlag,
    setMapNowLatLngFlag,
    setMapNowLatLngData,
    handleClickConfigLatLng,
    handleClickAddResetUserLatLng,
    mapRegsiterLatLngData,
    regsiterMessageFlag,
    setRegsiterMessageFlag,
    regsiterMessage,
    isSuccess,
    regsiterResetFlag,
  }
}