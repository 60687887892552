import { useState, useEffect } from 'react';
import { postRequest } from 'utils/_function';

//ドロワーメニューのメモ一覧部分のロジック
export const useMemoListZoom = (user, myProjectId) => {
  const [memoItems, setMemoItems] = useState([]); // メモの情報を格納
  const [memoStatusCode, setMemoStatusCode] = useState(''); // メモ情報のAPIを叩いた際のステータスコードを格納
  const [memoErrorMessage, setMemoErrorMessage] = useState(''); // メモ情報のAPIを叩いた際のエラーメッセージを格納
  const [memoGetFirstFlag, setMemoGetFirstFlag] = useState(true); // メモの登録,削除を行ったどうかのフラグ 
  const [memoZoomItem, setMemoZoomItem]  = useState(); // メモ一覧でZoomボタンを押下したメモの緯度,経度,memoidを格納

  //メモ一覧のZoomボタンが押下時に実行
  const handleClickMemoZoom = (lat, lng, memoId) =>{
      let memoDatas = {}
      memoDatas['lng'] = lng;
      memoDatas['lat'] = lat;
      memoDatas['memoid'] = memoId;
      setMemoZoomItem(memoDatas)
  }

  /** 画面初期描画時 */
  useEffect(() => {
    //メモ情報を取得する
    const fetchMemoInfoList = async() => {
      const errorMessage = "メモの取得に失敗しました。";
      const params = {
        userId: user.attributes.email,
        projectId: myProjectId,
      }
      const response = await postRequest('IM_Lambda', '/im-getmemoinfolist', params);
      if (response.errorMessage) {
        setMemoErrorMessage(errorMessage);
        return;
      }
      if (!response.statusCode) {
        setMemoErrorMessage(errorMessage);
        return;
      }

      switch (response.statusCode) {
        case "N0000": // 正常終了
          //jsxで描画するためにidを追加
          response.memoInfoList.map((value, index) => {
            value['id'] = index;
          })
          setMemoItems(response.memoInfoList)
          setMemoStatusCode(response.statusCode)
          break;
        case "E0110": // 対象のプロジェクトに対する権限がない
          setMemoStatusCode(response.statusCode);
          break;
        case "E0180": // 対象のユーザが存在しない
          setMemoStatusCode(response.statusCode);
          break;
        case "E0210": // 対象のメモが存在しない
          setMemoStatusCode(response.statusCode);
          break;
        case "E0250": // バリデーションチェックによるエラー
          setMemoStatusCode(response.statusCode);
          break;
        case "E0260": // コード内で指定されたデータが、タグごと入力JSONにない
          setMemoStatusCode(response.statusCode);
          break;
        case "E0270": //コード内で指定されたデータについて、値が入力JSONにない
          setMemoStatusCode(response.statusCode);
          break;
        default:
          setMemoErrorMessage(errorMessage);
          break;
      }
    }
    fetchMemoInfoList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    memoItems,
    memoStatusCode,
    memoErrorMessage,
    memoGetFirstFlag,
    memoZoomItem,
    setMemoItems,
    setMemoStatusCode,
    setMemoErrorMessage,
    setMemoGetFirstFlag,
    handleClickMemoZoom,
  }
}