import { useState, useEffect } from 'react';
import { postRequest } from 'utils/_function';
import axios from 'axios';
import axiosJsonpAdapter from 'axios-jsonp'

// mapboxに渡す用のznet地図情報を作成するロジック
export const useFetchZnetAuthUrl = () => {
  const [znetAuthUrlFetchFlag, setZnetAuthUrlFetchFlag] = useState(false); // 地図表示でZNETが選択された際のフラグ
  const [znetErrorMessage, setZnetErrorMessage] = useState(''); // ZNET地図の認証URL取得が失敗した際のメッセージを格納
  const [znetFetchErrorFlag, setZnetFetchErrorFlag] = useState(false); // ZNET地図の認証URL取得が失敗した際のフラグ
  const [znetMapNewAuthUrl, setZnetMapNewAuthUrl] = useState(null) // zenrinログイン時に新規発行した認証URLを格納
  const [znetMapStyleUrl, setZnetMapStyleUrl] = useState(null); // zenrin地図情報を作成する時に必要となる認証URLを格納
  const [znetMapStyle, setZnetMapStyle] = useState({}); // mapboxに渡すzerin地図情報を格納
  const [znetMapLoading, setZnetMapLoading] = useState(false); //zenrin地図選択時にローディング処理を走らせるフラグ

  // ZNET MAP API利用時のメモ
  /**
   * 1. DBに登録されている認証URLが有効期限以内かチェックする
   * 2. 期限内の場合は認証URLをレスポンスで返して、そのままmapbox側に渡して地図を表示する
   * 3. 期限が過ぎていた場合は、zenrinのログイン処理を行い認証URLを新規発行→DBに登録する
   * 4. DBへの登録が完了したらmapbox側に登録した認証URLを渡して地図を表示する
  */

  // 認証有効期限のURLかどうか調べる処理
  const CheckZnetAuthUrl = async () => {
    const params = {
      authUrl: null,
      checkFlag: true,
    }
    const response = await postRequest('IM_Lambda', '/im_addznetmapauthurl', params);
    if (response.errorMessage) {
      setZnetErrorMessage('ZNET地図の取得に失敗しました。')
      setZnetFetchErrorFlag(true);
      return;
    }
    if (!response.statusCode) {
      setZnetErrorMessage('ZNET地図の取得に失敗しました。')
      setZnetFetchErrorFlag(true);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        //　ZERIN MAP APIの有効期限が切れていなかった場合
        if(response.logData === 'limit') {
          setZnetMapStyleUrl(response.authUrl);
        }

        //　ZERIN MAP APIの有効期限が切れていた場合
        if(response.logData === 'excess') {
          loginZnetAuthUrl();
        }
        break;
      case "E0260": // その他のエラー
        setZnetErrorMessage('ZNET地図の取得に失敗しました。')
        setZnetFetchErrorFlag(true);
        break;
      default:
        setZnetErrorMessage('ZNET地図の取得に失敗しました。')
        setZnetFetchErrorFlag(true);
        break;
    }
  }

  // 新規発行した認証URLをDBに保存する
  const addZnetAuthUrl = async (zenrinMapNewAuthUrl) => {
    const params = {
      authUrl: zenrinMapNewAuthUrl,
      checkFlag: false,
    }
    const response = await postRequest('IM_Lambda', '/im_addznetmapauthurl', params);
    if (response.errorMessage) {
      setZnetErrorMessage('ZNET地図の取得に失敗しました。')
      setZnetFetchErrorFlag(true);
      return;
    }
    if (!response.statusCode) {
      setZnetErrorMessage('ZNET地図の取得に失敗しました。')
      setZnetFetchErrorFlag(true);
      return;
    }
    switch (response.statusCode) {
      case "N0000": // 正常終了
        setZnetMapStyleUrl(response.authUrl)
        break;
      case "E0260": // その他のエラー
        setZnetErrorMessage('ZNET地図の取得に失敗しました。')
        setZnetFetchErrorFlag(true);
        break;
      default:
        setZnetErrorMessage('ZNET地図の取得に失敗しました。')
        setZnetFetchErrorFlag(true);
        break;
    }
  }
  
  // 新規認証URLを発行する処理
  const loginZnetAuthUrl = () => {
    // loadイベントリスナー設定
    // 認証クラスでログイン可能状態になった場合にloadが発行される
    const login_url = "https://lab.zcgi.znet-town.net/cgi/auth_login.cgi?uid=t5pt29dho&pwd=hbboddrvs&cid=20091209&sid=99999999&serv_devcd=1&encode=1&otype=2"
    
    // Znet側にログイン
    axios.get(login_url,{adapter: axiosJsonpAdapter,callbackParamName: "cb",})
    .then(response => {
      /**
       * --memo--
       * id:0001, subid:1100がデフォルト:上位図、市街図[g]
       * subid:1010→上位図、全建地図[f]
       * subid:1011→上位図、全建地図[f]、住宅地図[H]
       * subid:1101→上位図、市街図[g]、住宅地図[H]
       */
      
      // 成功した場合の処理
      const aid = response.data.aid
      // const subid = '1100' // 市街図
      const subid = '1010' // 全建地図
      const result = response.data.items.find((u) => (u.id === '0001')&&(u.subid === subid)&&(u.name === '地図'));
      const lmtinf = result.areaCode + "," + result.funcInfoEncode;
      // WMTSAPIにログイン認証を付与
      const api_url = 'https://wmts2.znet-town.net/cgi/base_wmts_map/all/standard/ZNET-J3857/{z}/{x}/{y}.png';
      const authUrl = api_url + '?aid=' + aid + '&lmtinf=' + lmtinf + '&zver=1.0.0'
      setZnetMapNewAuthUrl(authUrl);
    }).catch(err => {
      // ログイン〜認証URLの間で何らかのエラーが起きた場合
      setZnetErrorMessage('ZNET地図の取得に失敗しました。')
      setZnetFetchErrorFlag(true);
    });
  }

  // 認証URLが新規発行された場合の処理
  useEffect(() => {
    if(znetMapNewAuthUrl === null) return;
    addZnetAuthUrl(znetMapNewAuthUrl);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [znetMapNewAuthUrl])

  // znet地図表示に使う認証URLが決定した際の処理
  useEffect(() => {
    if(znetMapStyleUrl === null) return;
    // Znet MAPのstyleを保存
    setZnetMapStyle(
      {
        'version': 8,
        'sources': {
          'gsi_image': {
            'type': 'raster',
            'tiles': [znetMapStyleUrl
            ],
            'tileSize': 256,
            'attribution': "ゼンリン地図"
          }
        },
        'layers': [
          {
            'id': 'ゼンリン画像',
            'type': 'raster',
            'source': 'gsi_image',
              'minzoom': 1,
            'maxzoom': 20,
            'paint': {
              'raster-opacity': 0.8
            }
          },
        ]
      }
    )
    setZnetMapStyleUrl(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [znetMapStyleUrl])

  //　地図選択でzenrin地図が選択された際の処理
  useEffect(() => {
    if(!znetAuthUrlFetchFlag) return;
    CheckZnetAuthUrl();
    setZnetAuthUrlFetchFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [znetAuthUrlFetchFlag])
  
  return{
    znetFetchErrorFlag,
    znetMapStyle,
    znetErrorMessage,
    znetMapLoading,
    setZnetAuthUrlFetchFlag,
    setZnetFetchErrorFlag,
    setZnetMapLoading,
  }
}
