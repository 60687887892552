import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import NativeSelect from '@material-ui/core/NativeSelect';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import 'css/AoiFilterModal.scss';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

// 隣接範囲のプルダウンの内容
const adJoinColumns = [{0: 0},{1: 1},{2: 2}];

// Gridフィルターモーダルのコンポーネント
export default function AoiGridFilterModal(props){
  const {
    isGridFilterModal,
    adjoinNum,
    gridAoiShowType,
    handleClickGridFilterModalBtn,
    handleChangeGridFilterAdjon,
    handleChangeGridFilterShowType,
    handleClickGridFilterExeBtn,
    handleClickGridFilterReset,
  } = props;
  const classes = useStyles();

  return(
    <Dialog maxWidth='lg' id="aoiFillterDialog" open={isGridFilterModal} onClose={() => handleClickGridFilterModalBtn(false)}>
      <DialogContent
        className='AoiFilteringModalArea'
        style={{overflowY: 'initial'}}
      >
        <Button 
          className='closeBtn'
          onClick={() => handleClickGridFilterModalBtn(false)}
        >
          <HighlightOffIcon/>
        </Button>
        <div className='titleArea'>
          <Typography className='title'>解析対象範囲の絞り込み検索</Typography>
        </div>
        <TableContainer component={Paper} className='tableArea'>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className='title'>
                  <div>基本情報</div>
                  <Tooltip
                    className='tooltipIcon'
                    title="メッシュの表示設定を行うことができます"
                    placement="top"
                    arrow
                  >
                    <HelpTwoToneIcon/>
                  </Tooltip>
                </TableCell>
                <TableCell className='inputArea'>
                  <div>
                    <RadioGroup
                      aria-label="output type"
                      name="output_type"
                      value={gridAoiShowType}
                      style={{flexDirection: "row"}}
                      onChange={handleChangeGridFilterShowType}
                    >
                      <FormControlLabel
                        value="grid"
                        control={<Radio color="primary" />}
                        label="購入済のみ表示"
                      />
                      <FormControlLabel
                        value="free"
                        control={<Radio color="primary" />}
                        label="すべて表示"
                      />
                    </RadioGroup>
                  </div>
                  {gridAoiShowType === 'free' && 
                   <div style={{color: 'red'}}>解析対象範囲のデータ取得までに時間がかかる場合があります。</div> 
                  }
                </TableCell>
              </TableRow>
              {gridAoiShowType === 'grid' && 
                <TableRow>
                  <TableCell className='title'>
                    <div>隣接範囲</div>
                    <Tooltip
                      className='tooltipIcon'
                      title="購入した解析対象範囲に隣接する範囲を表示することができます"
                      placement="top"
                      arrow
                    >
                      <HelpTwoToneIcon/>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='selectRankArea'>
                    <FormControl className='selectBoxArea grid'>
                      <FormHelperText>拡張するメッシュ数</FormHelperText>
                      <NativeSelect
                        className='selectBox'
                        value={adjoinNum}
                        onChange={handleChangeGridFilterAdjon}
                      >
                        {adJoinColumns.map(value => (
                          <option
                            value={Object.keys(value)}
                            key={Object.keys(value)}
                            >
                            {Object.values(value)}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div className='btnArea'>
          <Button
            style={{marginRight: '20px'}}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleClickGridFilterExeBtn}
          >
            実行
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleClickGridFilterReset}
          >
            リセット
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}