import { useState } from 'react';
import { postRequest } from 'utils/_function';

// メモを更新するロジック
export const useUpdateMemo = (user, myProjectId, memoItems) => {

  /** メモ更新ポップアップを表示 */
  const[memoUpdateFormStatus, setMemoUpdateFormStatus] = useState(false); // 更新用のダイアログ表示の表示制御
  const[memoUpdateId, setMemoUpdateId] = useState(); // 更新するmemoIDを格納
  const[memoUpdateTitle, setMemoUpdateTitle] = useState(""); // 更新するメモタイトルを格納
  const[memoUpdateText, setMemoUpdateText] = useState(""); // 更新するメモ本文を格納
  const [isMemoFormUpdateValid, setIsMemoFormUpdateValid] = useState(true); // メモ本文更新時のバリデーション
  const [isMemoTitleUpdateValid, setIsMemoTitleUpdateValid] = useState(true); // メモタイトル更新時のバリデーション
  const [isUpdateAlertFormOpen, setIsUpdateAlertFormOpen] = useState(false);
  const [alertUpdateMessageMemoForm, setAlertUpdateMessageMemoForm] = useState();
  const [upDateLoadingMemo, setUpDateLoadingMemo] = useState(false); // ローディングアニメーションを表示するか
  const [isUpdateMemoSuccess, setIsUpdateMemoSuccess] = useState(false) // メモの更新が完了したフラグ

  // メモ更新時の本文のバリデーション
  const handleChangeUpdateMemoFormValidate = (e) => {
    setMemoUpdateText(e.target.value)
    if (memoUpdateText.length > 0 && memoUpdateText.length <= 250) {
      setIsMemoFormUpdateValid(true);
    } else {
      setIsMemoFormUpdateValid(false);
    }
  }

  // メモ更新時ののタイトルのバリデーション
  const handleChangeUpdateMemoTitleValidate = (e) => {
    setMemoUpdateTitle(e.target.value)
    if (memoUpdateTitle.length > 0 && memoUpdateTitle.length <= 50) {
      setIsMemoTitleUpdateValid(true);
    } else {
      setIsMemoTitleUpdateValid(false);
    }
  }

  // メモポップアップ内の更新ボタンを押下した時
  const openUpdateMemoPopup = (e) => {
    const memoId = e.target.dataset.id;

    // 更新するべきメモ情報を抽出し、それぞれusestateに格納する
    memoItems.forEach(value => {
      if (value.memoId == memoId) {
        setMemoUpdateId(memoId);
        setMemoUpdateTitle(value.memoTitle);
        setMemoUpdateText(value.memo);
      }
    })
    setMemoUpdateFormStatus(true); // 更新用ダイアログをオープン
  }

  /** メモ更新ポップアップのキャンセルボタンをクリックした時の処理 */
  const handleUpdatePopupCancelButton = () => {
    setMemoUpdateFormStatus(false); // 更新用ダイアログをクローズ
    setIsUpdateAlertFormOpen(false);
  }

  /** メモ更新処理 */
  const updateMemo = async () => {
    const errorMessage = "メモの更新に失敗しました。";
    setUpDateLoadingMemo(true);
    setIsUpdateMemoSuccess(false);
    const params = {
      userId: user.attributes.email,
      projectId: myProjectId,
      memoId: memoUpdateId,
      memo: document.getElementById("memoUpdateTextArea").value, // メモ内容
      memoTitle: document.getElementById("memoUpdateTitleArea").value, // メモタイトル内容
    }
    const response = await postRequest('IM_Lambda', '/im-updatememoinfo', params);
    setUpDateLoadingMemo(false);
    if (response.errorMessage) {
      setIsUpdateAlertFormOpen(true);
      setAlertUpdateMessageMemoForm(errorMessage);
      return;
    }
    if (!response.statusCode) {
      setIsUpdateAlertFormOpen(true);
      setAlertUpdateMessageMemoForm(errorMessage);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        setMemoUpdateFormStatus(false);
        setIsUpdateMemoSuccess(true);
        break;
      case "E0250": // バリデーションチェックによるエラー
        setIsUpdateAlertFormOpen(true);
        setAlertUpdateMessageMemoForm(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0260": // コード内で指定されたデータが、タグごと入力JSONにない
        setIsUpdateAlertFormOpen(true);
        setAlertUpdateMessageMemoForm(errorMessage + '[' + response.statusCode + ']');
        break;
      case "E0270": //コード内で指定されたデータについて、値が入力JSONにない
        setIsUpdateAlertFormOpen(true);
        setAlertUpdateMessageMemoForm(errorMessage + '[' + response.statusCode + ']');
        break;
      default:
        setIsUpdateAlertFormOpen(true);
        setAlertUpdateMessageMemoForm(errorMessage);
        break;
    }
  }
  
  return {
    memoUpdateTitle,
    memoUpdateText,
    memoUpdateId,
    memoUpdateFormStatus,
    isMemoFormUpdateValid,
    isMemoTitleUpdateValid,
    isUpdateAlertFormOpen,
    alertUpdateMessageMemoForm,
    upDateLoadingMemo,
    isUpdateMemoSuccess,
    updateMemo,
    handleUpdatePopupCancelButton,
    openUpdateMemoPopup,
    handleChangeUpdateMemoTitleValidate,
    handleChangeUpdateMemoFormValidate,
  }
}