import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  panel: {
    position: "absolute",
    top: "0px",
    right: "55px",
    background: 'rgba(255,255,255,0.5);',
    padding: '2px 5px 2px 5px',
    textAlign: 'center',
    fontSize: '11px',
    fontWeight: 'normal',
  },
}));

export default function ProjectDetail(props) {
  const classes = useStyles();
  const { pjName } = props;

  return(
    <div className={classes.panel}>
      {pjName}
    </div>
  )
}