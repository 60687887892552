import { useState } from "react";

// 解析点の大きさを変更する処理
export const useConfigAnalysisPointSize = (debugFlag) => {
  const defaultPointSize = debugFlag ? 1.53 : 1.81; // デフォルトの解析点の大きさ
  const [analysisPointSize, setAnalysisPointSize] = useState(defaultPointSize); // 解析点の大きさを定義
  
  // // 解析点の変更(セレクトボックス)が行われた際の処理
  const handleChangeAnalysisPointSize = (e) => {
    setAnalysisPointSize(Number(e.target.value));
  }

  return {
    analysisPointSize,
    handleChangeAnalysisPointSize
  };
};
