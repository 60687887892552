import { useState, useEffect } from 'react';
import { postRequest } from 'utils/_function';

// mapboxに渡す用のzerin地図情報を作成するロジック
export const useFetchZenrinAuthUrl = () => {
  const [zenrinAuthUrlFetchFlag, setZenrinAuthUrlFetchFlag] = useState(false); // 地図表示でZENRINが選択された際のフラグ
  const [zenrinMapStyle, setZenrinMapStyle] = useState({}); // mapboxに渡すzerin地図情報を格納
  const [zenrinMapStyleUrl, setZenrinMapStyleUrl] = useState(null); // zenrin地図情報を作成する時に必要となる認証URLを格納
  const [zenrinMapNewAuthUrl, setZenrinMapNewAuthUrl] = useState(null) // zenrinログイン時に新規発行した認証URLを格納
  const [zenrinMapLoading, setZenrinMapLoading] = useState(false); //zenrin地図選択時にローディング処理を走らせるフラグ
  const [zenrinErrorMessage, setZenrinErrorMessage] = useState(''); // zenrin地図の認証URL取得が失敗した際のメッセージを格納
  const [zenrinFetchErrorFlag, setZenrinFetchErrorFlag] = useState(false); // zenrin地図の認証URL取得が失敗した際のフラグ

  // ZENRIN MAP API利用時のメモ
  /**
   * 1. DBに登録されている認証URLが有効期限以内かチェックする
   * 2. 期限内の場合は認証URLをレスポンスで返して、そのままmapbox側に渡して地図を表示する
   * 3. 期限が過ぎていた場合は、zenrinのログイン処理を行い認証URLを新規発行→DBに登録する
   * 4. DBへの登録が完了したらmapbox側に登録した認証URLを渡して地図を表示する
  */

  // 認証有効期限のURLかどうか調べる処理
  const CheckZerinAuthUrl = async () => {
    const params = {
      authUrl: null,
      checkFlag: true,
    }
    const response = await postRequest('IM_Lambda', '/im-addzenrinmapauthurl', params);
    if (response.errorMessage) {
      setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
      setZenrinFetchErrorFlag(true);
      return;
    }
    if (!response.statusCode) {
      setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
      setZenrinFetchErrorFlag(true);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        //　ZERIN MAP APIの有効期限が切れていなかった場合
        if(response.logData === 'limit') {
          setZenrinMapStyleUrl(response.authUrl);
        }

        //　ZERIN MAP APIの有効期限が切れていた場合
        if(response.logData === 'excess') {
          loginZenrinAuthUrl();
        }
        break;
      case "E0260": // その他のエラー
        setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
        setZenrinFetchErrorFlag(true);
        break;
      default:
        setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
        setZenrinFetchErrorFlag(true);
        break;
    }
  }

  // 新規発行した認証URLをDBに保存する
  const addZenrinAuthUrl = async (zenrinMapNewAuthUrl) => {
    const params = {
      authUrl: zenrinMapNewAuthUrl,
      checkFlag: false,
    }
    const response = await postRequest('IM_Lambda', '/im-addzenrinmapauthurl', params);
    if (response.errorMessage) {
      setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
      setZenrinFetchErrorFlag(true);
      return;
    }
    if (!response.statusCode) {
      setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
      setZenrinFetchErrorFlag(true);
      return;
    }
    switch (response.statusCode) {
      case "N0000": // 正常終了
        setZenrinMapStyleUrl(response.authUrl)
        break;
      case "E0260": // その他のエラー
        setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
        setZenrinFetchErrorFlag(true);
        break;
      default:
        setZenrinErrorMessage('ZENRIN地図の取得に失敗しました。')
        setZenrinFetchErrorFlag(true);
        break;
    }
  }

  // 新規認証URLを発行する処理
  const loginZenrinAuthUrl = () => {
    //ZERIN APIを利用するためのグローバル変数
    const zenrinAuth = ZisAuth; // eslint-disable-line

    // ZENRIN MAP APIのログアウト処理
    // const zenrinLogout = () => {
    //   zenrinAuth.logout();
    // }

    // 認証クラスでログイン可能状態になった場合にloadが発行される
    zenrinAuth.addEventListener("load", function(e) {
      const obj = {
        uid: "PsmIigha",
        pwd: "lPd4x7jh",
        sid: "50000001",
        device_flag: "1"
      }
      zenrinAuth.login(obj);
    });

    // 認証クラスでエラーが発生した場合の処理
    zenrinAuth.addEventListener("failed", function(e) {
      setZenrinFetchErrorFlag(true);
    });

    // readyイベントリスナー設定
    // 認証クラスで地図が利用可能な場合にreadyが発行される
    // ※ログイン認証の場合、ログイン後にreadyイベントが発行される。
    zenrinAuth.addEventListener("ready", function(e) {

      // 住所検索WebAPIのAPI情報を取得
      // ※ready発行後でないと、zenrinAut.hgetApiOption(funcid, funcsubid)はfalseになる。
      const api_opt = zenrinAuth.getApiOption('0007', '0008');

      // WMTSAPIにログイン認証を付与
      const api_url ='https://test-wmts.zip-site.com/api/zips/general/wmts_tile/w2nhkFRc/default/Z3857_3_21/{z}/{y}/{x}.png?zis_authtype=aid';
      const authUrl = api_url + '&zis_zips_authkey=' + api_opt.zis_zips_authkey + '&zis_authtype=' + api_opt.zis_authtype + '&zis_aid=' + api_opt.zis_aid + '&zis_lmtinf=' + api_opt.zis_lmtinf;
      setZenrinMapNewAuthUrl(authUrl);
    });
  }

  // 認証URLが新規発行された場合の処理
  useEffect(() => {
    if(zenrinMapNewAuthUrl === null) return;
    addZenrinAuthUrl(zenrinMapNewAuthUrl);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zenrinMapNewAuthUrl])


  //　zenrin地図表示に使う認証URLが決定した際の処理
  useEffect(() => {
    if(zenrinMapStyleUrl === null) return;
    // ZERIN MAPのstyleを保存
    setZenrinMapStyle(
      {
        'version': 8,
        'sources': {
          'gsi_image': {
            'type': 'raster',
            'tiles': [zenrinMapStyleUrl
            ],
            'tileSize': 256,
            'attribution': "ゼンリン地図"
          }
        },
        'layers': [
          {
            'id': 'ゼンリン画像',
            'type': 'raster',
            'source': 'gsi_image',
              'minzoom': 1,
            'maxzoom': 20,
            'paint': {
              'raster-opacity': 0.8
            }
          },
        ]
      }
    )
    setZenrinMapStyleUrl(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zenrinMapStyleUrl])

  //　地図選択でzenrin地図が選択された際の処理
  useEffect(() => {
    if(!zenrinAuthUrlFetchFlag) return;
    CheckZerinAuthUrl();
    setZenrinAuthUrlFetchFlag(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zenrinAuthUrlFetchFlag])

  return {
    zenrinMapStyle,
    zenrinMapLoading,
    zenrinErrorMessage,
    zenrinFetchErrorFlag,
    setZenrinAuthUrlFetchFlag,
    setZenrinMapLoading,
    setZenrinFetchErrorFlag,
  }
}