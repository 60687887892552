import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import NativeSelect from '@material-ui/core/NativeSelect';
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import 'css/AoiFilterModal.scss';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

//解析対象範囲の絞り込み検索(基準にする期間)
const projectColumns = [
  {selectNone: '選択してください'},
  {rankAllAve: '全期間の斜面変動ランク(平均変位)'},
  {rankAllMax: '全期間の斜面変動ランク(最大変位)'},
  {rankRecAve: '直近2時期の斜面変動ランク(平均変位)'},
  {rankRecMax: '直近2時期の斜面変動ランク(最大変位)'},
];

//解析対象範囲の絞り込み検索(変動ランク)
const projectRankName = [
  {0: '選択してください'},
  {1: '変動A’’(警戒変動)'},
  {2: '変動A’(注意変動)'},
  {3: '変動A(確定変動)'},
  {4: '変動B(準確定変動)'},
  {5: '変動C(潜在変動)以下'},
];

export default function AoiFilterModal(props){
  const {
    aoiIds,
    aoiNameInputValue,
    open,
    filterSearchFlag,
    filteringAois,
    filterInputFlag,
    handleClickAoiFilterModalBtn,
    aoiPeriodValue,
    aoiLevelValue,
    handleChangeInputFilterAoiName,
    handleChangeSearchFlag,
    handleClickFilterBtn,
    handleClickFilterResetBtn,
    handleChangeFilterPeriod,
    handleChangeFilterLevel,
    purchaseFlag,
    handleChangeFilterPurchaseCheck,
    projectAoiType,
  } = props;
  const classes = useStyles();

  return(
    <Dialog maxWidth='lg' id="aoiFillterDialog" open={open} onClose={() => handleClickAoiFilterModalBtn(false)}>
      <DialogContent
        className='AoiFilteringModalArea'
        style={{overflowY: 'initial'}}
      >
        <Button 
          className='closeBtn'
          onClick={() => handleClickAoiFilterModalBtn(false)}
        >
          <HighlightOffIcon/>
        </Button>
        <div className='titleArea'>
          <Typography className='title'>解析対象範囲の絞り込み検索</Typography>
          <div>ヒット件数:{filterInputFlag ? filteringAois.length : aoiIds.length}件</div>
        </div>
        <div>
          <RadioGroup
            aria-label="output type"
            name="output_type"
            value={filterSearchFlag}
            onChange={handleChangeSearchFlag}
          >
            <Box display="flex" id="">
              <FormControlLabel
                value="and"
                control={<Radio color="primary" />}
                label="AND検索"
              />
              <FormControlLabel
                value="or"
                control={<Radio color="primary" />}
                label="OR検索"
              />
            </Box>
          </RadioGroup>
        </div>
        <TableContainer component={Paper} className='tableArea'>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>

              {projectAoiType === 'Grid' &&
                <TableRow>
                  <TableCell className='title'>
                    <div>基本情報</div>
                    <Tooltip
                      className='tooltipIcon'
                      title="hogehoge"
                      placement="top"
                      arrow
                    >
                      <HelpTwoToneIcon/>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='inputArea'>
                  <div>
                    <FormControlLabel
                      label='購入済のみ表示'
                      control={
                        <Checkbox
                          checked={purchaseFlag}
                          onChange={handleChangeFilterPurchaseCheck}
                          name="mapLayer"
                          color="primary"
                        />
                      }
                    />
                  </div>
                  </TableCell>
                </TableRow>
              }

              <TableRow>
                <TableCell className='title'>
                  <div>対象範囲</div>
                  <Tooltip
                    className='tooltipIcon'
                    title="hogehoge"
                    placement="top"
                    arrow
                  >
                    <HelpTwoToneIcon/>
                  </Tooltip>
                </TableCell>
                <TableCell className='inputArea'>
                  <FormControl style={{width: '100%'}}>
                    <TextField
                      id="outlined-basic"
                      className='input'
                      label="以下を名前に含む対象範囲名のみ表示"
                      variant="outlined"
                      value={aoiNameInputValue}
                      onChange={ e => {
                        handleChangeInputFilterAoiName(e.target.value)
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className='title'>
                  <div>斜面変動ランク</div>
                  <Tooltip
                    className='tooltipIcon'
                    title="hogehoge"
                    placement="top"
                    arrow
                  >
                    <HelpTwoToneIcon/>
                  </Tooltip>
                </TableCell>
                <TableCell className='selectRankArea'>

                  <FormControl className='selectBoxArea'>
                    <FormHelperText>基準にする期間</FormHelperText>
                    <NativeSelect
                      className='selectBox'
                      value={aoiPeriodValue}
                      onChange={handleChangeFilterPeriod}
                    >
                      {projectColumns.map(value => (
                        <option
                          value={Object.keys(value)}
                          key={Object.keys(value)}
                          >
                          {Object.values(value)}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <FormControl className='selectBoxArea'>
                    <FormHelperText>変動ランク</FormHelperText>
                    <NativeSelect
                      className='selectBox'
                      value={aoiLevelValue}
                      onChange={handleChangeFilterLevel}
                    >
                      {projectRankName.map(value => (
                        <option
                          value={Object.keys(value)}
                          key={Object.keys(value)}
                          >
                          {Object.values(value)}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
        <div className='btnArea'>
          <Button
            style={{marginRight: '20px'}}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleClickFilterBtn}
          >
            実行
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleClickFilterResetBtn}
          >
            リセット
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}