import { useRef, useEffect, useState } from "react"

// AOIフィルタリング機能のロジック
export const useAoiFiltering = (aoisGeojson, aoiPurchaseData) => {

  // フィルタリングモーダル内の入力値
  const [aoiNameInputValue, setAoiNameInputValue] = useState(''); // 解析対象範囲名の入力値
  const [aoiPeriodValue, setAoiPeriodValue] = useState('selectNone'); // 斜面変動ランク検索内で選択された「基準にする期間」を格納
  const [aoiLevelValue, setAoiLevelValue] = useState(0); // 斜面変動ランク検索内で選択された「変動ランク」を格納
  const [isFilterModal, setIsFilterModal] = useState(false); // フィルタリングモーダルの表示非表示を管理
  const [filterSearchFlag, setFilterSearchFlag] = useState('and'); // フィルタリングの検索軸を定義('and'or'or')
  const [purchaseFlag, setPurchaseFlag] = useState(false); // 購入済みのみ表示のcheckboxの値を定義

  // フィルタリングロジックに必要な値
  const [aoiIds, setAoiIds] = useState([]); // マップ上の全てAOI情報を格納
  const [filterExecuteFlag, setFilterExecuteFlag] = useState(false); // AOIのフィルタリング実行フラグ
  const [filteringAois, setFilteringAois] = useState([]); // フィルタリングに該当したAOIのIDを格納
  const [aoiNameFilterIds, setAoiNameFilterIds] = useState([]); // 解析対象範囲名検索でヒットしたAOIidのを格納
  const [aoiRankFilterIds, setAoiRankFilterIds] = useState([]); // 斜面変動ランク検索でヒットしたAOIidのを格納
  const [purchaseFilterIds, setPurchaseFilterIds] = useState([]); // 購入済みのAOIidを格納
  const [filterInputFlag, setFilterInputFlag] = useState(false); // 何かしらのフィルタリングが行われているかどうかのフラグ

  //最新の状態を参照する
  const filterSearchFlagRef = useRef(); 
  filterSearchFlagRef.current = filterSearchFlag;
  const filteringAoisIdRef = useRef(); 
  filteringAoisIdRef.current = filteringAois;
  const aoiNameFilterIdsRef = useRef(); 
  aoiNameFilterIdsRef.current = aoiNameFilterIds;

  // フィルタリングモーダルを開閉を制御
  const handleClickAoiFilterModalBtn = (event) => setIsFilterModal(event);
  // フィルタリングの検索軸を変更する処理
  const handleChangeSearchFlag = (e) => setFilterSearchFlag(e.target.value);
  // AOIフィルタリングでAOI名が入力された際の処理
  const handleChangeInputFilterAoiName = (inputValue) => setAoiNameInputValue(inputValue);
  //「基準する期間」が変更された際の処理
  const handleChangeFilterPeriod = (e) => setAoiPeriodValue(e.target.value);
  //「変動ランク」が変更された際の処理
  const handleChangeFilterLevel = (e) => setAoiLevelValue(Number(e.target.value));
  // 「購入済みのみ表示」のチェックボックスが変更された際の処理
  const handleChangeFilterPurchaseCheck = (e) => setPurchaseFlag(e.target.checked)
  // 絞り込み検索時の実行処理
  const handleClickFilterBtn = () => setFilterExecuteFlag(true);

  // 絞り込み検索時リセットの処理
  const handleClickFilterResetBtn = () => {
    setFilteringAois([]);
    setAoiNameFilterIds([]);
    setAoiRankFilterIds([]);
    setAoiPeriodValue('selectNone');
    setAoiLevelValue(0);
    setFilterSearchFlag('and');
    setPurchaseFlag(false);
    setAoiNameInputValue('');
  }

  // aoiGeojson取得完了時の処理
  useEffect(() => {
    if(!aoisGeojson) return;
    const aoisGeojsonData = aoisGeojson.features;
    const aoisGeojsonDataList = aoisGeojsonData.map(value => {
      let aoiData = {};
      aoiData.id = value.id ;
      aoiData.aoiName = value.properties.aoiName ;
      aoiData.rankAllAve = value.properties.rankAllAve ;
      aoiData.rankAllMax = value.properties.rankAllMax ;
      aoiData.rankRecAve = value.properties.rankRecAve ;
      aoiData.rankRecMax = value.properties.rankRecMax ;
      aoiData.isNodata = value.properties.no_data;
      return aoiData;
    });
    setAoiIds(aoisGeojsonDataList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoisGeojson])

  // 斜面変動ランク検索でヒットしたAOIのidを取得する処理
  useEffect(() => {
    if(!aoisGeojson) return;
    // 選択した基準にする期間、変動ランクのうちどちらかが未選択状態だった場合はフィルタリングの評価対象外にする
    if(aoiPeriodValue === 'selectNone' || aoiLevelValue === 0) {
      setAoiRankFilterIds([]);
      return;
    }
    // セレクトボックスで選択した期間とランクを元に該当するAOIのidを抽出する
    const PeriodLevelHitAoiIds = [];
    aoiIds.forEach(value => {
      if (value[aoiPeriodValue] === aoiLevelValue && value['isNodata'] === false) {
        PeriodLevelHitAoiIds.push(value.id)
      }
    })
    setAoiRankFilterIds(PeriodLevelHitAoiIds);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[aoiPeriodValue, aoiLevelValue])

  // 検索窓で入力された文字列に該当するAOIのidを取得する処理
  useEffect(() => {
    if(!aoisGeojson) return;

    let filteredAoiIds = [];
    if(aoiNameInputValue) filteredAoiIds = aoiIds.filter(value => value.aoiName.includes(aoiNameInputValue)).map(value => value.id);
    setAoiNameFilterIds(filteredAoiIds);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[aoiNameInputValue])

  // 購入済のみ表示のCheckBoxがONの際に該当するAOIのidを取得する処理
  useEffect(() => {
    if(!aoisGeojson) return;
    if(!purchaseFlag) {
      setPurchaseFilterIds([]);
      return;
    }
    setPurchaseFilterIds(aoiPurchaseData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[purchaseFlag])

  /**
   * 検索軸に基づいてフィルタリングを行うAOIのidを決定する処理
   */
  useEffect(() => {
    if(!aoisGeojson) return;

    // 入力中の項目のvalueが全て空の場合はAOIの総数を表示(フィルタリング項目を増やす場合は必ず追加)
    if(!aoiNameInputValue && (aoiPeriodValue === 'selectNone' || aoiLevelValue === 0) && !purchaseFlag){
      setFilterInputFlag(false);
    }else{
      setFilterInputFlag(true);
    }

    let result = [] // 最終的にここにフィルタリング結果を格納する
    const filteringArray = [
      purchaseFilterIds, // 購入済みのフィルタリング結果
      aoiNameFilterIds, // AOI名でのフィルタリング結果
      aoiRankFilterIds, // 斜面変動ランクでのフィルタリング結果
    ];

    // 各フィルタリング結果の配列を削除する
    const removeFilteringArrayItem = (idName) => {
      const index = filteringArray.indexOf(idName);
      filteringArray.splice(index, 1);
    }

    // 未入力状態の項目はフィルタリングから除外する(項目が増えたら追加する)
    if(!purchaseFlag){
      removeFilteringArrayItem(purchaseFilterIds);
    }
    if(!aoiNameInputValue){
      removeFilteringArrayItem(aoiNameFilterIds);
    }
    if(aoiPeriodValue === 'selectNone' || aoiLevelValue === 0){
      removeFilteringArrayItem(aoiRankFilterIds);
    }

    // and検索処理
    if(filterSearchFlag === 'and') {
      // 各フィルタリング条件でヒットしたidのうち、共通のものを抽出
      result = filteringArray.reduce((acc, current) => {
        current.forEach((element) => {
          if (acc.includes(element)) return;
          if (filteringArray.every((array) => array.includes(element))) acc.push(element);
        });
        return acc;
      }, []);
    }

    // or検索処理
    if(filterSearchFlag === 'or'){

      // 各フィルタリング条件でヒットしたidを重複なしで抽出
      const mergedArray = filteringArray.reduce((acc, cur) => {
        return acc.concat(cur);
      }, []);
      result = [...new Set(mergedArray)];
    }

    setFilteringAois(result); // 結果をstateに格納
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseFilterIds, aoiNameFilterIds, aoiRankFilterIds, filterSearchFlag])

  return{
    aoiIds,
    isFilterModal,
    filterSearchFlag,
    filterExecuteFlag,
    filteringAois,
    aoiPeriodValue,
    aoiLevelValue,
    aoiNameInputValue,
    filterInputFlag,
    purchaseFlag,
    handleClickAoiFilterModalBtn,
    handleClickFilterResetBtn,
    handleChangeSearchFlag,
    handleClickFilterBtn,
    setFilterExecuteFlag,
    handleChangeInputFilterAoiName,
    setIsFilterModal,
    handleChangeFilterPeriod,
    handleChangeFilterLevel,
    handleChangeFilterPurchaseCheck,
  }
}