import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from 'App.js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { I18n } from 'aws-amplify'
import "./lang/config";
import { RecoilRoot } from 'recoil';

// @see https://qiita.com/saki-engineering/items/b327f93fe7f027913bd7
Amplify.configure({
  Auth: {
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_RR8eyHouW',
      userPoolWebClientId: '7t9sbn1u7bk8a81tfmkqckdjmk',
      identityPoolId: 'ap-northeast-1:716848d0-a174-436c-9eb2-bef7a0d99b85',
  },
  API: {
    endpoints: [
      {
        name: "IM_Lambda",
        endpoint: process.env.REACT_APP_API_URL
      },
    ],
}
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const dict = {
  'ja': {
    'Account recovery requires verified contact information': 'アカウントの復旧には、確認済みの連絡先情報が必要です',
    'Back to Sign In': 'サインイン画面に戻る',
    'CHANGE': '変更',
    'Change': '変更',
    'Change Password': 'パスワードを変更する',
    'Confirm': '確認',
    'Confirm Sign Up': 'サインアップの確認',
    'Confirmation Code': '確認コード',
    'Create Account': '新規登録',
    'Create a new account': 'アカウントの新規登録',
    'Create account': '新規登録',
    'Email Address *': 'メールアドレス *',
    'Email': 'メールアドレス *',
    'Enter code': 'コードを入力してください',
    'Enter your code': '確認コードを入力してください',
    'Enter your email address': 'メールアドレスを入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Enter your new password': '新しいパスワードを入力してください',
    'Enter your username': 'メールアドレスを入力してください',
    'Forgot your password?': 'パスワードをお忘れの方 ',
    'Have an account? ': 'アカウント登録済みの方 ',
    'Hello': 'こんにちは ',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが異なります。',
    'Invalid verification code provided, please try again.': '無効な確認コードです。再試行してください。',
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128": '1つの検証エラーが検出されました：「username」の値が制約を満たせませんでした：メンバーの長さが128以下である必要があります',
    "1 validation error detected: Value at 'userName' failed to satisfy constraint: Member must have length less than or equal to 128": '1つの検証エラーが検出されました：「username」の値が制約を満たせませんでした：メンバーの長さが128以下である必要があります',
    'Lost your code? ': 'コードを紛失した方 ',
    'New password': '新しいパスワード',
    'No account? ': 'アカウント未登録の方 ',
    'Password *': 'パスワード *',
    'Password cannot be empty': 'パスワードを空にすることはできません',
    'Password does not conform to policy: Password must have symbol characters': 'パスワードがポリシーに準拠していません：パスワードには記号文字が必要です',
    'Phone Number': '電話番号',
    'Resend Code': '確認コードの再送',
    'Reset password': 'パスワードのリセット',
    'Reset your password': 'パスワードのリセット',
    'Send Code': 'コードの送信',
    'skip': 'スキップ',
    'Skip': 'スキップ',
    'SKIP': 'スキップ',
    'sKIP': 'スキップ',
    'SkIP': 'スキップ',
    'SKiP': 'スキップ',
    'SKIp': 'スキップ',
    'skIP': 'スキップ',
    'skiP': 'スキップ',
    'SKip': 'スキップ',
    'Sign In': 'サインイン',
    'Sign Out': 'サインアウト',
    'Sign in': 'サインイン',
    'Sign in to your account': 'アカウントにサインインします',
    'Submit': '送信',
    'User does not exist': 'ユーザーが存在しません',
    'Username *': 'メールアドレス *',
    'Username cannot be empty': 'メールアドレスは必須入力です',
    'Username/client id combination not found.': 'メールアドレスが見つかりません',
    'Verification code': '検証コード',
    'Verify': '確認',
   }
};

I18n.putVocabularies(dict);
I18n.setLanguage('ja');