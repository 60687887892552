import { useEffect, useState } from "react"

// Gridフィルターモーダル内のロジックを定義
export const useAoiFilterfingForGrid = () => {
    const [isGridFilterModal, setIsGridFilterModal] = useState(false); // Gridフィルターモーダルの表示非表示を管理
    const [adjoinNum, setadjoinNum] = useState(1); // 隣接範囲で選択された値を格納
    const [gridAoiShowType, setGridAoiShowType] = useState('grid'); // 表示するGridタイプを格納(購入のみ or 全て)
    const [gridFilterExeFlag, setGridFilterExeFlag] = useState(false); // Gridフィルターの実行フラグ
    const [reFetchAoiData, setReFetchAoiData] = useState({}); // 再度geojson取得に必要なデータを格納

    // Gridフィルターモーダルを開閉を制御
    const handleClickGridFilterModalBtn = (event) => setIsGridFilterModal(event);
    // 隣接範囲が変更された際の処理
    const handleChangeGridFilterAdjon = (e) => setadjoinNum(Number(e.target.value));
    // 基本情報が変更された際の処理
    const handleChangeGridFilterShowType = (event) => setGridAoiShowType(event.target.value);
    // Gridフィルターが実行された際の処理
    const handleClickGridFilterExeBtn = () => setGridFilterExeFlag(true);
    // Gridフィルターがリセットされた際の処理
    const handleClickGridFilterReset = () => {
      setadjoinNum(0);
      setGridAoiShowType('grid');
    }

    // Gridフィルターが実行された際の処理
    useEffect(() => {
      if(!gridFilterExeFlag) return;
      setIsGridFilterModal(false); // Gridフィルターモーダルを非表示

      // geojson取得に必要なデータを定義
      setReFetchAoiData({
        adjoinNum: adjoinNum,
        gridAoiShowType: gridAoiShowType,
      })

      setGridFilterExeFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[gridFilterExeFlag])

    return{
      isGridFilterModal,
      adjoinNum,
      gridAoiShowType,
      reFetchAoiData,
      handleClickGridFilterModalBtn,
      handleChangeGridFilterAdjon,
      handleChangeGridFilterShowType,
      handleClickGridFilterExeBtn,
      handleClickGridFilterReset,
    }
}