import { useRef, useState } from 'react';
import { postRequestNoneKey } from 'utils/_function';

// 雨量のデータを取得するロジック
export const useFetchWeatherData = (isShowSnakbar) => {
  const [analysisDateFromTo, setAnalysisDateFromTo] = useState({}); // クリックした解析点のグラフデータの初日と最終日のデータを格納
  const [weaterToggleFlag, setWeaterToggleFlag] = useState(false); // 気象データtoggleスイッチの状態を定義
  const [raineyToggleFlag, setRaineyToggleFlag] = useState(false); // 降水量データtoggleスイッチの状態を定義
  const [showWeaterItem, setShowWeaterItem] = useState(false); // 降水量toggleスイッチの表示非表示を管理
  const [weatherPopupStatus, setWeatherPopupStatus] = useState(false) // 観測地点選択ポップアップの表示非表示の管理
  const [weaterObservationData, setWeaterObservationData] = useState([]); // 観測地点所の情報を格納
  const [weaterObservationDataFetchFlag, setWeaterObservationDataFetchFlag] = useState(false); // 観測地点の情報を正しく取得できた際のフラグ
  const [observationPopupContent, setObservationPopupContent] = useState({}); // 観測地点選択ポップアップに表示する内容を格納
  const [observationPointData, setObservationPointData] = useState([]); // 選択した観測地点から取得した降水量データを格納
  const [weatherLoadingFlag, setWeatherLoadingFlag] = useState(false) // ローディングを表示非表示を管理
  const [weatherLoadingName, setWeatherLoadingName] = useState('') // 選択した観測地点の名前を格納
  const analysisDateFromToRef = useRef();
  analysisDateFromToRef.current = analysisDateFromTo; // 最新のクリックした解析点の日時データ
  
  /**
   * 気象toggleスイッチのONOFF時の処理
   * @param {*} e トグルスイッチのイベント 
   */
  const weatherToggleChecked = (e) => {
    let toggleState = e.target.checked;
    setWeaterToggleFlag(toggleState);

    // 観測地点選択モードON
    if(toggleState){
      getWeaterObservationList(toggleState); // 観測地点情報取得
    }

    // 観測地点選択モードOFF
    if(!toggleState){
      setShowWeaterItem(false); // 降水量スイッチをhide
      setWeaterObservationDataFetchFlag(false);
      setRaineyToggleFlag(false); // 降水量スイッチをOFF
      // すべて観測地点アイコンを削除する処理
      const weatherMarkerIcons = document.querySelectorAll(".weatherMarker");
      for (let i = 0; i < weatherMarkerIcons.length; i++) {
        weatherMarkerIcons[i].remove();
      }
    }
  };

  /**
   * 降水量toggleスイッチのをONOFF時の処理
   * @param {*} e toggleスイッチのイベント
   */
  const raineyToggleChecked = (e) => {
    let raineytoggleState = e.target.checked;

    // 観測地点選択モードON
    if(raineytoggleState){
      setRaineyToggleFlag(raineytoggleState);
    }

    // 観測地点選択モードOFF
    if(!raineytoggleState){
      setRaineyToggleFlag(raineytoggleState);
    }
  }

  /**
   * 観測地点選択ポップアップの表示非表示を管理
   * 観測地点選択ポップアップに表示する内容を管理
   * @param {boolean} value 
   */
  const handleClickIsWeatherPopup = (e) => {
    let observationId = e.target.id;

    // クリックした観測地点のidを元に観測地点の情報を取得する
    weaterObservationData.forEach(value => {
      if(value.stid === observationId){
        setObservationPopupContent({
          stid: value.stid,
          observationName: value.name,
          // observationKana: value.kana,
          // latitude: value.latitude,
          // longitude: value.longitude,
          elevation: value.elevation
        })
      }
    })
    setWeatherPopupStatus(true); // 観測地点選択ポップアップを表示
  }

  /**
   * 観測地点選択ポップアップを閉じる処理
   */
  const handleClickCloseWeatherPopup = () => {
    setWeatherPopupStatus(false); // 観測地点選択ポップアップを非表示
  }

  /**
   * 渡された解析データから初日と最終日のdate情報を抜き出しstateに保存する処理
   * @param {string} startDate geojsonの日付データの初日
   * @param {string} endDate geojsonの日付データの最終日
   */
  const getDateFromGeojson = (startDate, endDate) => {
    let fromToDate = {};
    fromToDate.fromDate = Date.parse(startDate.replace(/-/g, '/')) / 1000;
    fromToDate.toDate = Date.parse(endDate.replace(/-/g, '/')) / 1000;
    setAnalysisDateFromTo(fromToDate);
  }

  // 降水量データ取得
  const fetchWeatherData = async (observationName) => {
    setWeatherPopupStatus(false); // 観測地点選択ポップアップを非表示
    setWeatherLoadingFlag(true); // ローディング開始

    const params = {
      from: analysisDateFromToRef.current.fromDate,
      to: analysisDateFromToRef.current.toDate,
      stid: observationPopupContent.stid,
    }
    const errorTxt = '雨量データの取得に失敗しました。もう一度やり直してください。';
    const errorObj = { target:{ checked: false } }

    try{
      const response = await postRequestNoneKey('IM_Lambda', '/im-getweatherdata', params)
      switch (response.statusCode) {
        case 200: // 正常終了
          setShowWeaterItem(true); // 降水量データスイッチをshow
          setObservationPointData(response.result);
          setWeatherLoadingName(observationName);
  
          // 一旦すべて観測地点アイコンを青くする処理
          const weatherMarkerIcons = document.getElementsByClassName("weatherMarker");
          for (let i = 0; i < weatherMarkerIcons.length; i++) {
            const weatherMarkerIcon = weatherMarkerIcons[i];
            weatherMarkerIcon.classList.remove("selectedWeatherMarker");
          }
          // クリックした観測地点アイコンを赤くする処理
          document.getElementById(observationPopupContent.stid).classList.add("selectedWeatherMarker");
  
          break;
        default:
          isShowSnakbar(errorTxt, 'error'); // snackbar表示
          setWeatherLoadingFlag(false); // ローディング終了
          raineyToggleChecked(errorObj); // 降水量toggleスイッチをOFF
          break;
      }
      setWeatherLoadingFlag(false); // ローディング終了
    }catch{
      isShowSnakbar(errorTxt, 'error'); // snackbar表示
      setWeatherLoadingFlag(false); // ローディング終了
      raineyToggleChecked(errorObj); // 降水量toggleスイッチをOFF
      return;
    }
  }

  // 降水量観測所データを取得
  const getWeaterObservationList = async () => {
    setWeatherLoadingFlag(true); // ローディング開始
    const params = {
      // 都道府県ID: 'hogehoge',
    }
    const response = await postRequestNoneKey('IM_Lambda', '/im-getweatherstationlist', params);
    const errorTxt = '気象観測地点情報の取得に失敗しました。もう一度やり直してください。';
    const selectMessage = '気象観測地点を選択してください。'
    const errorObj = { target:{ checked: false } }

    if (!response.statusCode) {
      isShowSnakbar(errorTxt, 'error'); // snackbar表示
      setWeatherLoadingFlag(false); // ローディング終了
      weatherToggleChecked(errorObj); // 気象toggleスイッチをOFF
      return;
    }

    switch (response.statusCode) {
      case 200: // 正常終了
        setWeaterObservationData(response.result);
        setWeaterObservationDataFetchFlag(true);
        setWeatherLoadingFlag(false); // ローディング終了
        isShowSnakbar(selectMessage, 'info'); // snackbar表示
        break;
      default:
        isShowSnakbar(errorTxt, 'error'); // snackbar表示
        setWeatherLoadingFlag(false); // ローディング終了
        weatherToggleChecked(errorObj); // 気象toggleスイッチをOFF
        break;
    }
  }

  return{
    weaterToggleFlag,
    weaterObservationData,
    weatherPopupStatus,
    weaterObservationDataFetchFlag,
    observationPopupContent,
    showWeaterItem,
    observationPointData,
    raineyToggleFlag,
    weatherLoadingFlag,
    weatherLoadingName,
    weatherToggleChecked,
    getDateFromGeojson,
    handleClickIsWeatherPopup,
    handleClickCloseWeatherPopup,
    fetchWeatherData,
    raineyToggleChecked,
  }
}