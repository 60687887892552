import React from 'react';
import Alert from "@material-ui/lab/Alert";

export default function AlertMessage(props) {
  const {
    variant = 'standard',
    alertMessage,
    alertSeverity,
  } = props;

  return (
    <Alert variant={variant} severity={alertSeverity}>
      {alertMessage}
    </Alert>
  );
}
