import React, { useRef } from 'react';
import { Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import 'css/Chart.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  ResponsiveContainer: {
    position: 'relative',
    top: '-15px',
    width: '100%',
    height: '100%',
  },
  customTooltip: {
    background: 'rgba(0, 78, 162, 0.3)',
    borderRadius: '10px',
    fontSize: '12px',
    lineHeight: 1.6,
    padding: '10px',
  },
  customTooltipLabel: {
    margin: 0,
  },
}));

const Chart = (props) => {
  const {
    t,
    pointDatasState,
    graphMaxConfig,
    graphMinConfig,
    observationPointData,
    raineyToggleFlag,
  } = props;

  const raineyToggleFlagRef = useRef();
  raineyToggleFlagRef.current = raineyToggleFlag; // selectedLegendMaxの最新の状態を参照する
  const observationPointDataRef = useRef();
  observationPointDataRef.current = observationPointData; // selectedLegendMaxの最新の状態を参照する

  const classes = useStyles();

  /** グラフのTooltip用のhtml生成 */
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload[0] && label) {
      return (
        <div className={classes.customTooltip}>
          <p className={classes.customTooltipLabel}>{new Date(label).toLocaleDateString()}</p>
          <p className={classes.customTooltipLabel}>{Math.floor(payload[0].value * Math.pow(10, 5)) / Math.pow(10, 5)}</p>
        </div>
      );
    }

    return null;
  }

  /**
  * 解析点のデータと雨量データを合体させてグラフに渡す処理
  * @param {Array} pointData 変位の値
  * @return {Array} 解析データと雨量データを時系列順にした配列 or pointDataをそのまま返す
  */
  const mergePointWeatherData = (pointData) => {

    if(raineyToggleFlagRef.current){
      // グラフ描画用に配列を整形
      const raineyData = observationPointDataRef.current.map((value) => {
        let obj = {
          date: value.date * 1000,
          quantity: null,
          precipitation_total: value.precipitation_total,
          precipitation_max: value.precipitation_max
        }
        return obj;
      })
      // グラフ描画用に配列を整形
      const quantityData = pointData.map((value) => {
        let obj = {
          date: value.date,
          quantity: value.quantity,
          precipitation_total: null,
          precipitation_max: null
        }
        return obj;
      })

      // 雨量データと変異の値をマージかつ時系列順に並び替え
      const mergedArray = raineyData.concat(quantityData).sort((a, b) => a.date - b.date);
      return mergedArray;
    }
    return pointData
  }

  return (
    <ResponsiveContainer className={classes.ResponsiveContainer}>
      <ComposedChart
        data={mergePointWeatherData(pointDatasState)}
        margin={{
          top: 5,
          right: 30,
          left: 5,
          bottom: 5,
        }}
        fontSize={12}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          domain={['dataMin', 'dataMax']}
          tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
          type='number'
          style={{fontSize: '0.9rem'}}
        />
        <YAxis
          yAxisId="left"
          stroke="#333"
          type="number"
          allowDataOverflow={true}
          domain={[graphMinConfig, graphMaxConfig]}
          label={{ value: 'mm', angle: 0, position: 'insideBottomRight', offset: 35, dy: 35, fill: '#6a6a6a' }}
        />
        <YAxis
          yAxisId="right" orientation="right"
          label={{ value: 'mm', angle: 0, position: 'insideBottomRight',dy: 7, display: raineyToggleFlag ? 'block' : 'none', fill: '#6a6a6a' }}
        />
        <Tooltip content={<CustomTooltip />}/>
        <Legend verticalAlign="top" />
        <Line name={t("map.displacement") + "(mm)"} isAnimationActive={false} yAxisId="left" connectNulls type="basis" dataKey="quantity" stroke="#004ea2" activeDot={{ r: 8 }} />
        { raineyToggleFlag && <Area name='降水量累積(mm・月)' isAnimationActive={false} yAxisId="right" connectNulls type="monotone" dataKey="precipitation_total" stroke="#8884d8" fill="#8884d8" activeDot={{ r: 8 }} />}
        { raineyToggleFlag && <Area name='降水量最大(mm/日)' isAnimationActive={false} yAxisId="right" connectNulls type="monotone" dataKey="precipitation_max" stroke="blue" fill="blue" activeDot={{ r: 8 }} /> }
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default Chart;