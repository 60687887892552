import { useState } from 'react';

//現在地取得機能のロジック
export const useCurrentLocationZoom = () => {
  const [nowLocationLatLng, setNowLocationLatLng] = useState({});// 取得した現在地のデータを格納
  const [nowLocationLoadingFlag, setNowLocationLoadingFlag] = useState(false);

  //現在地の緯度経度を取得する
  const handleClickNowLocation = () => {
    setNowLocationLoadingFlag(true);
    let nowLocationData = {};
    navigator.geolocation.getCurrentPosition( (position) => {
        // 現在地取得に成功した場合
        let lat = position.coords.latitude;//緯度を取得
        let lng = position.coords.longitude;//経度を取得
        nowLocationData['lat'] = lat;
        nowLocationData['lng'] = lng;
        setNowLocationLatLng(nowLocationData);
        setNowLocationLoadingFlag(false);
      }, (error) => {
        // 現在地取得に失敗した場合
        setNowLocationLoadingFlag(false);
        switch(error.code) {
          case 1: //PERMISSION_DENIED
            alert("位置情報の利用が許可されていません");
            break;
          case 2: //POSITION_UNAVAILABLE
            alert("現在位置が取得できませんでした");
            break;
          case 3: //TIMEOUT
            alert("タイムアウトになりました");
            break;
          default:
            alert("その他のエラー(エラーコード:"+error.code+")");
            break;
        }
      }
    );
  }
  return{
    nowLocationLatLng,
    nowLocationLoadingFlag,
    handleClickNowLocation,
  }
}