import React from 'react';
import DashboardTable from 'components/dashboard/DashboardTable';

/** Project 一覧ページのテーブル */
export default function ProjectsDashboardTable(props) {
  const {
    t,
    currentLang,
    projectsTableData,
    alertMesseageProjectInfoList,
    addLoadingList,
    accountGroupId,
    setIsShowPurchaseAlertPopup,
  } = props;
  const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: t("dashboard.project") },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
    { id: 'createDateTime', numeric: false, disablePadding: false, label: t("dashboard.register") },
    { id: 'updatedAt', numeric: false, disablePadding: false, label: t("dashboard.update") },
    { id: 'aoiCount', numeric: true, disablePadding: false, label: t("dashboard.rankList") },
    { id: 'target', numeric: true, disablePadding: false, label: t("dashboard.subject") },
    { id: 'status', numeric: true, disablePadding: false, label: t("dashboard.situation") },
    { id: 'remarks', numeric: true, disablePadding: false, label: t("dashboard.remark") },
  ];

  return (
    <DashboardTable
      t={t}
      currentLang={currentLang}
      headCells={headCells}
      rows={projectsTableData}
      alertMesseageProjectInfoList={alertMesseageProjectInfoList}
      addLoadingList={addLoadingList}
      accountGroupId={accountGroupId}
      setIsShowPurchaseAlertPopup={setIsShowPurchaseAlertPopup}
    ></DashboardTable>
  )
}
