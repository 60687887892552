import { useState, useEffect } from 'react';
import { postRequest } from 'utils/_function';

//管理者コメント情報を取得するロジック
export const useFetchAoiAdminComment = (projectId) => {
  const [adminComment, setAdminComment] = useState([]); // fetchした管理者コメント情報を格納
  const [getAdminCommentAlertFlag, setGetAdminCommentAlertFlag] = useState(false); // 管理者コメント取得失敗時のSnackbarのフラグ
  const [summaryTextData, setSummaryTextData] = useState('　'); // summaryページに表示する管理者コメントを格納

  // 管理者コメント情報を取得
  const fetchAoiAdminComment = async () => {
    const params = {
      projectId: String(projectId),
    }
    const response = await postRequest('IM_Lambda', '/im-getaoiadmincomment', params);
    if (response.errorMessage) {
      setGetAdminCommentAlertFlag(true);
      return;
    }
    if (!response.statusCode) {
      setGetAdminCommentAlertFlag(true);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        setAdminComment(response.commentDatas);
        break;
      case "E0270": //　必須項目がない
        setGetAdminCommentAlertFlag(true);
        break;
      case "E0260": // その他のエラー
        setGetAdminCommentAlertFlag(true);
        break;
      default:
        setGetAdminCommentAlertFlag(true);
        break;
    }
  }

  /** 画面初期描画時 */
  useEffect(() => {
    fetchAoiAdminComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return{
    adminComment,
    getAdminCommentAlertFlag,
    summaryTextData,
    setSummaryTextData,
    setGetAdminCommentAlertFlag,
    fetchAoiAdminComment,
  }
}